import { theme } from '@provi/provi-components'
import styled from 'styled-components'
import { fonts } from '~/assets/fonts'
import { colors } from '~/theme'
import { EmptyStatePerson } from '~/assets/svg/EmptyStatePerson'

/** @type {import('styled-components').StyledComponent<"div", any, { isBottomCartShown: boolean, isEmpty: boolean}>} */
export const Wrapper = styled.div`
  * {
    box-sizing: border-box;
  }

  box-sizing: border-box;
  padding-bottom: 50px;
  width: 100vw;
  display: flex;
  min-height: calc(100vh - 190px - 251px);
  flex-direction: column;
  align-items: center;
  background-color: ${({ isEmpty }) => (isEmpty == true ? 'inherit' : colors.bgV2)};
  background-size: cover;
  padding-bottom: 50px;

  ${theme.breakpoints.down('md')} {
    min-height: calc(50vh);
  }
`

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 920px;
  padding: 0 20px;

  p {
    font-family: ${fonts.montserrat.regular};
    font-size: 20px;
    color: ${theme.colors.blue900};

    strong {
      font-family: ${fonts.montserrat.bold};
      font-size: 24px;
      color: ${theme.colors.pink900};
    }
  }

  ${theme.breakpoints.down('md')} {
    align-items: center;
    text-align: center;

    p {
      max-width: 250px;
      font-family: ${fonts.montserrat.regular};
      font-size: 16px;
      color: ${theme.colors.blue900};

      strong {
        font-family: ${fonts.montserrat.bold};
        font-size: 20px;
        color: ${theme.colors.pink900};
      }
    }
  }
`

export const EmptyStateImage = styled(EmptyStatePerson)`
  width: 270px;
  display: none;
  ${theme.breakpoints.down('md')} {
    display: initial;
  }
`

export const PaginationButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 18px;
`

export const LoadMoreContainer = styled.button`
  cursor: pointer;
  padding: 7px 20px;
  background-color: ${theme.colors.pink900};
  color: ${theme.colors.white100};
  font-family: ${fonts.montserrat.bold};
  font-size: 14px;
  border-radius: 5px;
  border: none;

  :hover {
    background-color: #af083a;
  }
`

export const ListContainer = styled.div`
  z-index: 10;
  display: inline-grid;
  grid-template-columns: repeat(3, minmax(280px, auto));
  padding: 0px 20px;
  align-items: flex-start;
  column-gap: 32px;

  ${theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, minmax(280px, auto));
  }
`

export const Container = styled.div`
  margin-top: -28px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ComboContainer = styled.div`
  margin-bottom: 32px;
  display: grid;
  gap: 32px;
`

export const BottomCartAction = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 40px;
  height: 100%;
  max-width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  background-color: ${theme.colors.pink900};
  color: ${theme.colors.white100};
  z-index: 12;
  cursor: pointer;
  transition: background-color 0.2s ease;

  :hover {
    background-color: #af083a;
  }

  ${theme.breakpoints.down('md')} {
    max-height: 56px;
  }
`
export const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
`
