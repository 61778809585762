import React from 'react'
import { Wrapper, Screen, Wrapper404, WrapperErrorText, BoldText, Text, WrapperIcon } from './styles'
import { Text404, ErrorText, ProviIcon } from '~/assets/svg'

export const NotFound = () => {
  return (
    <Wrapper>
      <Screen>
        <Wrapper404>
          <Text404 />
        </Wrapper404>
        <WrapperErrorText>
          <ErrorText />
          <BoldText>Desculpe!</BoldText>
          <Text>Alguma coisa deu errado por nossa culpa.</Text>
          <WrapperIcon>
            <ProviIcon />
          </WrapperIcon>
        </WrapperErrorText>
      </Screen>
    </Wrapper>
  )
}
