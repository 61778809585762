import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { Route, Switch, Router, Redirect } from 'react-router-dom'
import { ToastComponent } from '@provi/provi-components'
import { routes } from './routes'
import { history } from './history'
import { NotFound } from '~/screens'
import { generateToken, getAnalyticsUser } from '~/utils'
import { PageScroll } from './pageScroll'
import { LocalStorageKeys } from '~/enums'
import { RedirectModal } from '~/components/RedirectModal'

export const Navigation = () => {
  useEffect(() => {
    localStorage.setItem(LocalStorageKeys.token, generateToken())
    ReactGA.set({
      userId: getAnalyticsUser(),
    })
  }, [])
  return (
    <Router history={history}>
      <PageScroll />
      <RedirectModal />
      <Switch>
        {routes.map((item) => {
          if (item.component) {
            return <Route key={item.path} exact={item.exact} path={item.path} component={item.component} />
          }
          if (item.redirect) {
            return <Redirect key={item.redirect} from={item.path} to={item.redirect} />
          }

          return <Route key={item.path} exact path={item.path} render={() => (window.location = item.externalRedirect)} />
        })}
        <Route component={NotFound} />
      </Switch>
      <ToastComponent />
    </Router>
  )
}
