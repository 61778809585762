import React from 'react'
import { ButtonV2 as Button } from '@provi/provi-components'
import { BoletoInformation, BoletoItem, BoletoItems, BoletoText, BoletoWrapper, ButtonWrapper, Title } from '../styles'
import { CalendarBoleto } from '~/assets/svg'
import { useCheckoutCart } from '../../hooks'
import { useLoading } from '~/hooks'

/**
 * @typedef {object} IProps
 * @property {() => void} finishAction
 */

/** @type {React.FC<IProps>}*/
export const BoletoOptions = ({ finishAction }) => {
  const { isSmallLoading } = useLoading()
  const { addressSuccessData = {} } = useCheckoutCart()
  const { partnerName = '' } = addressSuccessData
  return (
    <>
      <BoletoWrapper>
        <Title>Para pagar no boleto à vista</Title>
        <BoletoItems>
          <BoletoItem>
            <CalendarBoleto />
            <BoletoText>
              O boleto tem validade de 1 dia. Se ele não for pago até a data de vencimento, o pedido será cancelado.
            </BoletoText>
          </BoletoItem>
        </BoletoItems>
        <BoletoInformation>
          Seu pedido pode levar até 3 dias para ser processado pelo banco. Assim que for compensado, avisaremos a{' '}
          {partnerName || 'nossa parceira'}.
        </BoletoInformation>
      </BoletoWrapper>
      <ButtonWrapper>
        <Button
          text="Gerar boleto"
          id="btnNextBoleto"
          disabled={isSmallLoading}
          onClick={finishAction}
          persistFloatingMode={false}
          marginVertical={0}
          marginHorizontal={0}
        />
      </ButtonWrapper>
    </>
  )
}
