/**
 * file to configurate axios globally
 */

import axios from 'axios'
import { ROOT_URL } from '~/constants'
import { generateToken } from '~/utils'

const api = axios.create({
  baseURL: ROOT_URL?.MS_PROVI_PAY || '',
})

api.interceptors.request.use(
  (config) => {
    config.headers.Authorization = generateToken()

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error?.response ? error?.response?.status : null

    if (status === 401) {
      const newToken = generateToken()
      error.config.headers.Authorization = newToken
      return axios.request(error.config)
    }

    return Promise.reject(error)
  }
)

export { api }
