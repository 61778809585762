import React from 'react'
import { LoadingProvi } from '@provi/provi-components'
import { useLoading, usePlatform } from '~/hooks'
import { View } from './style'
import { Footer } from '../footer'

/** @type {React.FC} */
export const Template = (props) => {
  const { isLoading } = useLoading()
  const { isMobile } = usePlatform()
  return (
    <View>
      {isLoading && <LoadingProvi />}
      {props.children}
      {!isMobile && <Footer />}
    </View>
  )
}
