import { history } from '~/navigation/history'
export const getSlugPartner = () => {
  const pathname = history.location.pathname
  const splitString = pathname.split('/')

  let slug
  if (splitString.includes('courses') && splitString.indexOf('courses') != -1) {
    slug = splitString[splitString.indexOf('courses') + 1]
  } else if (splitString.includes('checkout') && splitString.indexOf('checkout') != -1) {
    slug = splitString[splitString.indexOf('checkout') + 1]
  } else if (splitString.includes('bloqueado') && splitString.indexOf('bloqueado') != -1) {
    slug = splitString[splitString.indexOf('bloqueado') + 1]
  }

  if (slug != null) {
    return slug.split('?')[0]
  }
}
