import React, { memo, useCallback } from 'react'
import {
  Container,
  PartnerLogo,
  Column,
  PartnerName,
  CourseLabel,
  CourseName,
  CoursePrice,
  Removable,
  DiscountTag,
  DiscountLabel,
  Row,
  MobileDiscountTag,
  DiscountIcon,
  InvisibleButton,
} from './styles'
import { logoUrl } from './values'
import { parseCurrency } from '~/utils'
const ListItem = ({ course, isMobile, PartnerNameCamp, removeItem, partnerImage }) => {
  const {
    partnerName = '',
    courseName = '',
    price = '',
    discounts = '',
    removable = false,
    totalPriceToPay = '',
    item = [],
  } = course

  const calcPercents = useCallback(() => {
    const discountNumber = Math.floor(((price - totalPriceToPay) / price) * 100)

    return discountNumber + ' %'
  }, [price, totalPriceToPay])

  const renderRow = (ItemCourseName, ItemTotalPriceToPay, ItemPrice, courseLabel = null, index = null) => {
    const TotalPriceToPay = ItemTotalPriceToPay ? ItemTotalPriceToPay : ItemPrice

    return (
      <Row key={ItemCourseName + ItemPrice} flexDirection="column">
        {courseLabel && <CourseLabel>Curso {index + 1}</CourseLabel>}
        <CourseName marginTop={10}>{ItemCourseName}</CourseName>
        {!isMobile ? (
          <CoursePrice>
            {parseCurrency(TotalPriceToPay)}
            {ItemTotalPriceToPay && <DiscountLabel>{parseCurrency(ItemPrice)}</DiscountLabel>}
          </CoursePrice>
        ) : (
          <Row justifyContent="space-between" alignItems="center">
            <CoursePrice>
              {parseCurrency(TotalPriceToPay)}
              {ItemTotalPriceToPay && <DiscountLabel>{parseCurrency(ItemPrice)}</DiscountLabel>}
            </CoursePrice>
            {ItemTotalPriceToPay && isMobile && (
              <MobileDiscountTag>
                <DiscountIcon />
                {calcPercents()}
              </MobileDiscountTag>
            )}
          </Row>
        )}
      </Row>
    )
  }

  return (
    <Container>
      <Column>
        <PartnerLogo src={partnerImage || logoUrl} />
        {discounts != '' && !isMobile && <DiscountTag>-{parseCurrency(discounts)}</DiscountTag>}
      </Column>
      <Column fullWidth={true}>
        <Row justifyContent="space-between" alignItems="flex-start">
          <PartnerName>{partnerName || PartnerNameCamp}</PartnerName>
          {removable && !item.length && (
            <InvisibleButton onClick={() => removeItem(course)} id="remove-item">
              <Removable discount={true} />
            </InvisibleButton>
          )}
        </Row>

        {item.length
          ? item.map((element, index) => renderRow(element.courseName, element.totalPriceToPay, element.price, true, index))
          : renderRow(courseName, totalPriceToPay, price)}
      </Column>
    </Container>
  )
}

export default memo(ListItem)
