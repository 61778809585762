import * as React from 'react'

/** @type {React.FC<{ size: number }>} */
export const CardIcon = ({ active }) => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 5.42105H17M2.45455 1H15.5455C16.3488 1 17 1.65979 17 2.47368V11.3158C17 12.1297 16.3488 12.7895 15.5455 12.7895H2.45455C1.65122 12.7895 1 12.1297 1 11.3158V2.47368C1 1.65979 1.65122 1 2.45455 1Z"
        stroke={active ? 'white' : '#4E5A99'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
