import { showToast } from '@provi/provi-components'
import { useEffect, useCallback } from 'react'
import { useLoading } from '~/hooks'
import { useHome } from '../home/hooks'
import { useCheckout as useCheckoutStore } from '~/stores'
import { handleAxiosError, hashCard, removeMask, getSlugPartner, setEvent } from '~/utils'
import { trackVirtualPageView } from '~/utils/trackEvent'
import { analyticsPageViews, eventActions, eventCategories } from '~/enums'

import { history } from '~/navigation/history'

import { parseUrl, stringify } from 'query-string'

export const useCheckoutCart = () => {
  const { setIsLoading } = useLoading()

  const {
    createPlaceAddress,
    addressSuccessData,
    processPaymentCreditAndBoleto,
    paymentCreditAndBoletoSuccessData,
    processPaymentCourseFinancing,
    paymentCourseFinancingSuccessData,
    selectedModality,
    setSelectedModality,
    cartInformations,
    step,
    setStep,
    holderInformation,
    creditRequest,
    addressBody,
    setAddressBody,
    creditBody,
    setCreditBody,
  } = useCheckoutStore()

  const proceed = useCallback(
    async (values) => {
      try {
        setIsLoading(true)
        const data = await createPlaceAddress({
          address: {
            creditRequestId: cartInformations.CreditRequestId || creditRequest,
            city: values.city,
            complement: values.complement,
            district: values.district,
            state: values.state || 'AC',
            street: values.street,
            streetNumber: values.streetNumber,
            zipcode: values.zipcode,
          },
        })

        return data
      } catch (error) {
        showToast(handleAxiosError(error))
      } finally {
        setIsLoading(false)
      }
    },
    [cartInformations, step, createPlaceAddress, addressSuccessData, addressBody]
  )

  const finishAction = useCallback(
    async (values) => {
      setEvent(eventCategories.selectPaymentMethod, {
        action: `Set${selectedModality}`,
        label: 'PaymentInfo',
      })

      try {
        setIsLoading(true)
        if (selectedModality == 'CreditCard') {
          const phone = values.phone && removeMask(values.phone)

          const cardHash = await hashCard({
            number: values.cardNumber,
            cvc: values.cardCvv,
            expirationDate: values.cardExpireDate,
          })

          if (values.birthDate && values.holderCPF && phone) {
            const otherCreditCardOwnerBody = {
              CreditRequestId: cartInformations.CreditRequestId || creditRequest,
              PartnerConditionId: values.partnerConditionId,
              paymentMethod: selectedModality,
              creditCardInformation: {
                hash: cardHash,
                holder: {
                  fullName: values.cardName,
                  birthDate: values.birthDate,
                  CPF: values.holderCPF,
                  phone: phone,
                },
              },
            }

            return await processPaymentCreditAndBoleto(otherCreditCardOwnerBody)
          }

          const creditCardBody = {
            CreditRequestId: cartInformations.CreditRequestId || creditRequest,
            PartnerConditionId: values.partnerConditionId,
            paymentMethod: selectedModality,
            creditCardInformation: {
              hash: cardHash,
              holder: {
                fullName: values.cardName,
                birthDate: holderInformation.birthDate,
                CPF: holderInformation.CPF,
                phone: removeMask(holderInformation.phone),
              },
            },
          }

          await processPaymentCreditAndBoleto(creditCardBody)
        }

        if (selectedModality == 'CourseFinancing') {
          await processPaymentCourseFinancing({
            CreditRequestId: cartInformations.CreditRequestId || creditRequest,
            PartnerConditionId: values.partnerConditionId,
          })

          setEvent(eventCategories.selectPaymentMethod, {
            action: `Set${selectedModality}`,
            label: 'PaymentInfo',
          })
        }

        if (selectedModality == 'Boleto') {
          await processPaymentCreditAndBoleto({
            CreditRequestId: cartInformations.CreditRequestId || creditRequest,
            PartnerConditionId: addressSuccessData.Boleto.paymentCondition[0].PartnerConditionId,
            paymentMethod: selectedModality,
          })

          setEvent(eventCategories.selectPaymentMethod, {
            action: `Set${selectedModality}`,
            label: 'PaymentInfo',
          })
        }
      } catch (error) {
        showToast(handleAxiosError(error))
      } finally {
        setIsLoading(false)
      }

      return paymentCreditAndBoletoSuccessData || paymentCourseFinancingSuccessData
    },
    [
      cartInformations,
      step,
      selectedModality,
      paymentCreditAndBoletoSuccessData,
      holderInformation,
      processPaymentCreditAndBoleto,
      paymentCourseFinancingSuccessData,
    ]
  )

  return {
    addressSuccessData,
    proceed,
    setStep,
    step,
    selectedModality,
    setSelectedModality,
    finishAction,
    addressBody,
    setAddressBody,
    creditBody,
    setCreditBody,
  }
}
export const useCheckout = () => {
  const {
    getCheckoutData,
    cartInformations,
    cpf,
    setCPF,
    step,
    setStep,
    triggerModalDiscountAlert,
    showDiscountAlert,
    shouldShowDiscountAlert,
    createNewCheckout,
    setHolderInformation,
    refusedContinueRequest,
    informationBody,
    setInformationBody,
    updateCheckout,
    holderInformation,
    creditRequest,
    addressSuccessData,
    setAddressSuccessData,
  } = useCheckoutStore()

  const { selectProduct } = useHome()

  const { setIsLoading } = useLoading()
  const { query } = parseUrl(window.location.href)

  const checkCPF = useCallback(
    async (userCpf) => {
      setCPF(userCpf)
      refusedContinueRequest === false && (await getCheckoutInfo(userCpf))
    },
    [cartInformations, cpf, setCPF, refusedContinueRequest]
  )

  /** @type {(shouldApplyDiscount: boolean) => void} */
  const hideDiscountAlert = useCallback(
    async (shouldApplyDiscount) => {
      triggerModalDiscountAlert(false)
      if (shouldApplyDiscount) {
        const cpfData = cpf ? cpf : query.cpf ? query.cpf : null

        const newCourses = cartInformations?.discountAlert
          ? [
              ...JSON.parse(query.courses),
              ...new Set(
                cartInformations?.discountAlert
                  .map((discount) =>
                    discount?.ComboId ? discount.item.map((discountCourse) => discountCourse.id) : discount.id
                  )
                  .flat()
              ),
            ]
          : [...JSON.parse(query.courses)]
        await getCheckoutData({
          userCpf: cpfData,
          courses: newCourses || [],
          campaignSlug: query.campaign || null,
          slug: getSlugPartner(),
        })
      }
    },
    [cartInformations]
  )

  const sendCheckout = useCallback(
    async (body) => {
      setIsLoading(true)
      const { PartnerId, courseIds } = cartInformations || {}
      const { cpf: cpfUser, fullName, phone, email, birthDate } = body

      setHolderInformation({
        fullName,
        birthDate,
        CPF: cpfUser,
        phone,
        email,
      })

      const postBody = {
        CPF: cpfUser,
        fullName,
        phone,
        email,
        birthDate,
        courseArray: courseIds,
        PartnerId,
      }

      query.campaign && (postBody.campaignSlug = query.campaign)
      query.p && (postBody.p = query.p)

      await createNewCheckout(postBody)
    },
    [cartInformations]
  )

  const removeItem = useCallback(
    async (item) => {
      setEvent(eventCategories.cart, {
        action: eventActions.removeItem,
        value: item,
      })

      const newCartInformations = {
        ...cartInformations,
        courses: cartInformations.courses.filter((x) => x.id != item.id),
        courseIds: cartInformations.courseIds.filter((x) => x != item.id),
      }

      selectProduct(item.id)

      if (newCartInformations.courseIds.length == 0) goToCourses()

      const cpfData = cpf ? cpf : query.cpf ? query.cpf : null

      if (creditRequest) {
        await updateCart(newCartInformations.courseIds, cpfData)
      }

      const body = {
        userCpf: cpfData,
        courses: newCartInformations.courseIds || [],
        campaignSlug: query.campaign || null,
        slug: getSlugPartner(),
      }

      query.p && (body.p = query.p)

      await getCheckoutData(body)

      let parsedUrl = parseUrl(window.location.href)
      parsedUrl.query.courses = `[${newCartInformations.courseIds.toString()}]`
      window.history.pushState({}, '', parsedUrl.url + '?' + stringify(parsedUrl.query))
    },
    [cartInformations, cpf, selectProduct]
  )

  const updateCart = useCallback(
    async (newCourses, cpf) => {
      setEvent(eventCategories.cart, {
        action: eventActions.updateItem,
        value: newCourses,
      })

      const body = {
        CreditRequestId: cartInformations.CreditRequestId || creditRequest,
        PartnerId: cartInformations.PartnerId,
        CPF: cpf,
        courseArray: newCourses,
        fullName: holderInformation.fullName,
        phone: holderInformation.phone,
        email: holderInformation.email,
        birthDate: holderInformation.birthDate,
      }

      query.p && (body.p = query.p)

      const data = await updateCheckout(body)
      newCourses?.length > 0 && !data.error && setAddressSuccessData(data)
      newCourses?.length > 0 && data.error && showToast(data.error)
    },
    [cartInformations, creditRequest, setAddressSuccessData]
  )

  const getCheckoutInfo = useCallback(
    async (userCpf) => {
      const cpfData = userCpf ? userCpf : query.cpf ? query.cpf : cpf

      const courses = JSON.parse(parseUrl(window.location.href).query.courses || null)

      if (courses) {
        courses.forEach((e) => {
          setEvent(eventCategories.cart, {
            action: eventActions.addFromURL,
            value: e,
          })
        })
      }

      const body = {
        userCpf: cpfData,
        courses: courses || [],
        campaignSlug: query.campaign || null,
        slug: getSlugPartner(),
      }

      query.p && (body.p = query.p)

      const result = await getCheckoutData(body)

      if (creditRequest && step !== 1) {
        await updateCart(courses, cpfData)
      }

      if (result && result.continueRequest && refusedContinueRequest === false) {
        history.push(
          `/courses/${getSlugPartner()}?cpf=${cpfData}${query?.p?.length ? `&p=${query?.p}` : ''}`,
          result.continueRequest
        )
      }
      if (
        (Object.keys(cartInformations).length > 0 && !cartInformations.courseIds && !result && !query.campaign) ||
        ((!query.courses || query.courses === '[]') && !query.campaign && !query.cpf && !query.p) ||
        (result?.courses && result?.courses.length === 0)
      ) {
        setCPF('')
        history.push(`/courses/${getSlugPartner()}`)
      }
    },
    [query, cartInformations]
  )
  const goToCourses = () => history.push(`/courses/${getSlugPartner()}?${stringify(query)}`)

  useEffect(() => {
    trackVirtualPageView(analyticsPageViews.checkout)
    getCheckoutInfo()
  }, [])

  return {
    cartInformations,
    goToCourses,
    removeItem,
    sendCheckout,
    checkCPF,
    cpf,
    setCPF,
    step,
    setStep,
    triggerModalDiscountAlert,
    showDiscountAlert,
    shouldShowDiscountAlert,
    hideDiscountAlert,
    informationBody,
    setInformationBody,
    addressSuccessData,
  }
}
