import * as React from 'react'

/**
 * @typedef {object} IProps
 * @property {number} size
 */

/** @type {React.FC<IProps>} */
export const SearchIcon = ({ size = 25, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" fill="none" {...props}>
      <path
        d="M21.444 11.222c0 5.646-4.576 10.222-10.222 10.222C5.577 21.444 1 16.868 1 11.222 1 5.577 5.577 1 11.222 1c5.646 0 10.222 4.577 10.222 10.222zM24 24l-5.559-5.558"
        stroke="#DE0C4B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
