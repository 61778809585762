import React from 'react'
import { ProviPayLogo } from '~/assets/svg'
import { WHATSAPP_LINK } from '~/constants'
import { Wrapper, ContentWrapper, Copyright, CompanyInfo, PhoneInfo, WhatsAppIcon } from './styles'

/** @typedef {object} IProps */

/** @type {React.FC<IProps>}*/
export const Footer = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <ProviPayLogo />
        <CompanyInfo>
          CNPJ: 32.390.384/0001-92 | Provi Solucoes e Servicos LTDA <br />
          Av. Dr. Cardoso de Melo, 1340 - conjunto 11 Vila Olímpia, São Paulo - SP, 04548-004
        </CompanyInfo>

        <PhoneInfo onClick={() => window.open(WHATSAPP_LINK, '_blank')} aria-label="phone-info">
          <strong>Fale com a gente pelo WhatsApp</strong>
          <div>
            <WhatsAppIcon /> +55 (11) 95771-2414
          </div>
        </PhoneInfo>
        <Copyright>Copyrigths ® 2021 Provi. All rights reserved.</Copyright>
      </ContentWrapper>
    </Wrapper>
  )
}
