import React from 'react'

import { Box, Box2, ArrowWrapper, Arrow } from './styles'

/** @type {React.FC<IProps>}*/
export const LoadingState = () => {
  return (
    <Box>
      <ArrowWrapper>
        <Arrow />
      </ArrowWrapper>
      <Box2 />
    </Box>
  )
}
