import * as React from 'react'

/** @type {React.FC<{ size: number }>} */
export const DiscountMobile = ({ className }) => {
  return (
    <svg width="18" height="18" className={className} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.34315 14.6569C2.63515 13.9489 3.10473 12.4624 2.74437 11.5915C2.3708 10.6886 1 9.96192 1 8.99997C1 8.03804 2.37081 7.31138 2.74437 6.40852C3.10474 5.53756 2.63515 4.05115 3.34315 3.34315C4.05114 2.63515 5.53757 3.10473 6.40853 2.74437C7.31141 2.3708 8.03808 1 9.00003 1C9.96196 1 10.6886 2.37081 11.5915 2.74437C12.4624 3.10474 13.9489 2.63515 14.6569 3.34315C15.3649 4.05114 14.8953 5.53757 15.2556 6.40853C15.6292 7.31141 17 8.03808 17 9.00003C17 9.96196 15.6292 10.6886 15.2556 11.5915C14.8953 12.4624 15.3649 13.9489 14.6569 14.6569C13.9489 15.3649 12.4624 14.8953 11.5915 15.2556C10.6886 15.6292 9.96192 17 8.99997 17C8.03804 17 7.31138 15.6292 6.40852 15.2556C5.53756 14.8953 4.05115 15.3649 3.34315 14.6569Z"
        stroke="#DE0C4B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.1794 5.8208L5.82047 12.1798" stroke="#DE0C4B" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="6.94864" cy="6.94864" r="1.14103" stroke="#DE0C4B" />
      <circle cx="11.0513" cy="11.0512" r="1.14103" stroke="#DE0C4B" />
    </svg>
  )
}
