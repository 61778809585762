import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { fonts } from '~/assets/fonts'
import { Removable as RemovableButton, DiscountMobile } from '~/assets/svg'

export const Removable = styled(RemovableButton)`
  margin-top: ${({ discount }) => (discount ? '0px;' : '5px;')};
  margin-right: 8px;
  transition: all 250ms;
  min-height: 18px;
  min-width: 16px;

  :hover {
    cursor: pointer;
    transform: translateY(-2px);
  }
`
export const Container = styled.div`
  border: 1px solid ${theme.colors.grey500};
  display: flex;
  width: 256px;
  margin-top: 8px;
  padding-bottom: 10px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
    min-height: 83px;
  }
`
export const InvisibleButton = styled.button`
  background-color: ${theme.colors.white100};
  border: 0px solid #000;
  :active {
    outline: none;
    border: none;
  }
  :focus {
    outline: 0;
  }
`

export const PartnerLogo = styled.img`
  height: 48px;
  width: 48px;
  margin: 8px 11px 0px 8px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  border: 0px solid #000;
  width: ${({ fullWidth }) => (fullWidth ? '100%;' : null)};
  // padding-top:7px;
`

export const Row = styled.div`
  flex: 1;
  padding: 0px;
  display: inline-flex;
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : null)}px;
  border: 0px solid #000;
  padding-top: 7px;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : null)};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : null)};
  flex-direction: ${({ flexDirection }) => (flexDirection ? flexDirection : null)};
`

export const PartnerName = styled.label`
  color: ${theme.colors.pink900};
  font-family: ${fonts.montserrat.bold};
  font-size: 14px;
  border: 0px solid #000;
  width: 100%;
`

export const CourseName = styled.label`
  color: ${theme.colors.blue900};
  font-family: ${fonts.montserrat.regular};
  font-size: 16px;
  max-width: 210px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)}px;
`

export const CourseLabel = styled.label`
  color: ${theme.colors.grey500};
  font-family: ${fonts.montserrat.bold};
  font-size: 12px;
  max-width: 210px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)}px;
`

export const CoursePrice = styled.label`
  color: ${theme.colors.blue900};
  font-family: ${fonts.montserrat.bold};
  font-size: 16px;
  margin-top: 5px;
`
export const DiscountTag = styled.span`
  flex: 1;
  margin-left: 8px;
  margin-top: 55%;
  font-family: ${fonts.montserrat.regular};
  background-color: rgba(222, 12, 75, 0.15);
  color: ${theme.colors.pink900};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 25px;
  max-height: 25px;
  align-self: end;
  padding: 2px;
`
export const DiscountLabel = styled.label`
  text-decoration: line-through;
  font-family: ${fonts.montserrat.regular};
  color: ${theme.colors.grey500};
  font-size: 12px;
  margin-left: 7px;
`

export const MobileDiscountTag = styled.div`
  display: flex;
  font-family: ${fonts.montserrat.regular};
  background-color: rgba(222, 12, 75, 0.15);
  color: ${theme.colors.pink900};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  width: 60px;
  font-size: 14px;
  height: 25px;
  max-height: 25px;
  align-self: end;
  margin-top: 10px;
  margin-right: 7px;
`
export const DiscountIcon = styled(DiscountMobile)`
  height: 20px;
  margin-right: 5px;
`
