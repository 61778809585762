import { theme } from '@provi/provi-components'
import styled from 'styled-components'
import { fonts } from '~/assets/fonts'
import {
  SuccessJumpingPerson as SuccessJumpingPersonSVG,
  AnalysisHourglass as AnalysisHourglassSVG,
  EmailNotebookPerson as EmailNotebookPersonSVG,
  SignaturePerson as SignaturePersonSVG,
  FailureRobot as FailureRobotSVG,
  DefaultSuccess,
} from '~/assets/svg'

/** @type {import('styled-components').StyledComponent<"div", any, { isBottomCartShown: boolean, isEmpty: boolean}>} */
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0px solid #000;
  min-height: 100vh;
  text-align: center;

  ${theme.breakpoints.down('md')} {
    label {
      max-width: 90vw;
    }
    margin-bottom: 160px;
  }
`
/** @type {import('styled-components').StyledComponent<"div", any, { isBottomCartShown: boolean, isEmpty: boolean}>} */
export const FirstContainer = styled.div`
  min-width: 100%;
  display: flex;
  border: 0px solid #000;
  min-height: 362px;
  background-color: ${theme.colors.blue900};
`
export const SecondContainer = styled.div`
  min-width: 100%;
  display: flex;
  min-height: 64vh;
  height: 64vh;
  background-color: ${theme.colors.white100};

  @media (max-height: 768px) {
    min-height: 500px;
  }
`
export const FloaterContainer = styled.div`
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0px solid #000;
  min-height: 500px;
  top: 69px;
  padding: 0 20px;
`

export const Row = styled.div`
  display: inline-flex;
  min-width: 420px;
  ${theme.breakpoints.down('md')} {
    min-width: 280px;
  }
`

export const Title = styled.h1`
  word-wrap: break-word;
  margin: 0;
  font-size: 24px;
  font-family: ${fonts.montserrat.bold};
  color: ${theme.colors.blue600};
  max-width: 90%;
  min-width: 100px;
  margin-bottom: 20px;
`
export const Header = styled.h2`
  word-wrap: break-word;
  font-size: 20px;
  font-family: ${fonts.montserrat.bold};
  color: ${theme.colors.white100};
  max-width: 90%;
  margin: 0;
`
export const SubHeader = styled.p`
  word-wrap: break-word;
  font-size: 20px;
  font-family: ${fonts.montserrat.regular};
  color: ${theme.colors.white100};
  max-width: 543px;
  text-align: center;
  margin-top: 20px;
`
export const Question = styled.p`
  font-size: 20px;
  margin: 0;
  font-family: ${({ isRegular }) => (isRegular ? fonts.montserrat.regular : fonts.montserrat.bold)};
  color: ${({ pink }) => (pink ? theme.colors.pink900 : theme.colors.blue900)};
  max-width: 593px;
  text-align: center;
  margin-top: 20px;

  ${theme.breakpoints.down('md')} {
    font-size: 14px;
  }
`
export const Helper = styled.p`
  font-size: 20px;
  margin: 0;
  font-family: ${({ isBold }) => (isBold ? fonts.montserrat.bold : fonts.montserrat.regular)};
  color: ${theme.colors.blue900};
  margin-top: 20px;
  max-width: 788px;
  text-align: center;

  ${theme.breakpoints.down('md')} {
    font-size: 14px;
  }
`

/**
 * images
 */
export const SignaturePerson = styled(SignaturePersonSVG)`
  margin-top: 80px;
`
export const FailureRobot = styled(FailureRobotSVG)`
  margin-top: 27px;
`
export const EmailNotebookPerson = styled(EmailNotebookPersonSVG)`
  margin-top: 59px;
  margin-left: -10vh;
`
export const AnalysisHourglass = styled(AnalysisHourglassSVG)`
  margin-top: 80px;
`
export const SuccessJumpingPerson = styled(SuccessJumpingPersonSVG)`
  margin-top: 27px;
`
export const BoletoSuccess = styled(DefaultSuccess)`
  margin-top: 30px;

  ${theme.breakpoints.down('md')} {
    margin-top: 5px;
    max-height: 250px;
  }
`

/**
 * buttons
 */
export const Button = styled.button`
  min-width: ${({ big }) => (big ? '201px;' : '152px;')};
  min-height: 40px;
  background-color: ${theme.colors.pink900};
  color: ${theme.colors.white100};
  font-size: 16px;
  font-family: ${fonts.montserrat.bold};
  margin-top: 25px;
  border-radius: 3px;
  border: 0px;
  transition: all 0.2s ease;

  :hover {
    cursor: pointer;
    background: #af083a;
  }

  ${theme.breakpoints.down('md')} {
    min-width: 140px;
    margin-top: 5px;
  }
`
/**
 * button outline
 */
export const ButtonOutline = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  min-width: ${({ big }) => (big ? '201px;' : '152px;')};
  min-height: 40px;
  background-color: ${theme.colors.white100};
  color: ${theme.colors.pink900};
  font-size: 16px;
  font-family: ${fonts.montserrat.bold};
  margin-top: 25px;
  margin-left: 22px;
  border-radius: 3px;
  border: 1px solid ${theme.colors.pink900};
  transition: all 0.2s ease;
  text-decoration: none;

  :hover {
    cursor: pointer;
    color: #af083a;
    border: 1px solid #af083a;
  }

  ${theme.breakpoints.down('md')} {
    min-width: 140px;
    margin-top: 5px;
  }
`

/**
 * input
 */
export const InputText = styled.input`
  width: 90%;
  min-height: 40px;
  border-radius: 3px;
  border: 1px solid ${theme.colors.grey500};
  font-family: ${fonts.montserrat.regular};
  font-size: 16px;
  margin-top: 20px;
  text-align: center;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    max-width: 90vw;
  }
`
