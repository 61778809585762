import * as React from 'react'

/** @type {React.FC} */
export const ArrowRight = (props) => {
  return (
    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.70801 6.5H13.3227M9.59611 1.5L14 6.5L9.59611 11.5"
        stroke={props.color ? props.color : '#FFFFFF'}
        strokeWidth={props.strokeWidth ? props.strokeWidth : '2'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
