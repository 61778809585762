import React from 'react'
import PropTypes from 'prop-types'
import { PartnerLogo, PartnerSlug, SellingPlatform, Wrapper } from './styles'

export const PartnerInfo = ({ partnerLogo, partnerName }) => {
  return (
    <>
      <Wrapper>
        {partnerLogo != null && <PartnerLogo src={partnerLogo} alt={partnerName} />}
        <div>
          <SellingPlatform>Plataforma de vendas</SellingPlatform>
          <PartnerSlug>{partnerName}</PartnerSlug>
        </div>
      </Wrapper>
    </>
  )
}

PartnerInfo.propTypes = {
  partnerLogo: PropTypes.string,
  partnerName: PropTypes.string,
}
