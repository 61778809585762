import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import ErrorBoundary from '~/components/error-boundary'
import { Navigation } from '~/navigation'
import { StoresProvider } from '~/stores/provider'
import 'react-toastify/dist/ReactToastify.css'
import './config'
import './style.css'

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <ErrorBoundary>
        <StoresProvider>
          <Navigation />
        </StoresProvider>
      </ErrorBoundary>
    </AppContainer>,
    document.getElementById('provi-root')
  )
}

render()

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./navigation', () => {
    render()
  })
}
