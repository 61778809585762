import * as React from 'react'
import PropTypes from 'prop-types'

/**
 * @typedef {object} IProps
 * @property {number} size
 */

/** @type {React.FC<IProps>} */
export const ProviPayLogoWhite = ({ size = 216, ...props }) => {
  return (
    <svg width={size} viewBox="0 0 216 43" fill="none" {...props}>
      <path
        d="M55.22 12.62v2.4c.98-.98 2.05-1.72 3.19-2.22a9.22 9.22 0 013.67-.74c2.83 0 5.24 1.07 7.23 3.21 1.98 2.14 2.97 4.89 2.97 8.24 0 3.23-1.02 5.94-3.07 8.11-2.05 2.17-4.48 3.26-7.29 3.26-1.25 0-2.42-.22-3.49-.67-1.08-.45-2.15-1.17-3.21-2.19v10.24h-5.41V12.62h5.41zm5.73 4.47c-1.72 0-3.14.59-4.27 1.76-1.13 1.17-1.7 2.7-1.7 4.59 0 1.93.57 3.49 1.7 4.68 1.13 1.19 2.56 1.79 4.27 1.79 1.66 0 3.06-.61 4.2-1.82 1.14-1.21 1.71-2.75 1.71-4.63 0-1.86-.56-3.39-1.68-4.58-1.12-1.19-2.53-1.79-4.23-1.79zM74.41 12.62h4.67v2.73c.51-1.08 1.18-1.9 2.02-2.46.84-.56 1.76-.84 2.75-.84.71 0 1.44.19 2.22.56l-1.7 4.69c-.64-.32-1.16-.48-1.58-.48-.84 0-1.55.52-2.13 1.56-.58 1.04-.87 3.07-.87 6.11l.02 1.06v8.78H74.4V12.62h.01zM97.86 12.06c2.05 0 3.979.51 5.779 1.54a11.12 11.12 0 014.22 4.17c1.01 1.76 1.52 3.65 1.52 5.69 0 2.05-.51 3.97-1.53 5.75-1.02 1.78-2.41 3.18-4.16 4.18-1.76 1-3.69 1.51-5.81 1.51-3.11 0-5.77-1.11-7.97-3.32-2.2-2.22-3.3-4.91-3.3-8.07 0-3.39 1.24-6.22 3.73-8.48 2.18-1.98 4.69-2.97 7.52-2.97zm.09 5.13c-1.69 0-3.1.59-4.23 1.77-1.13 1.18-1.69 2.68-1.69 4.52 0 1.89.56 3.42 1.67 4.59 1.11 1.17 2.52 1.76 4.23 1.76 1.71 0 3.129-.59 4.259-1.78 1.13-1.18 1.7-2.71 1.7-4.57 0-1.86-.56-3.38-1.67-4.54-1.11-1.16-2.53-1.75-4.27-1.75zM110.4 12.62h5.55l5.49 12.82 5.48-12.82h5.52l-9.24 21.72h-3.53l-9.27-21.72zM136.65 3.68c.96 0 1.77.35 2.46 1.04.68.69 1.02 1.53 1.02 2.52 0 .97-.34 1.8-1.02 2.49-.68.69-1.49 1.03-2.43 1.03-.97 0-1.79-.35-2.48-1.05-.68-.7-1.02-1.55-1.02-2.54 0-.96.34-1.78 1.01-2.46.67-.68 1.5-1.03 2.46-1.03zm-2.73 8.94h5.45v21.72h-5.45V12.62z"
        fill="#fff"
      />
      <path
        d="M148.631 12.62v2.4c.98-.98 2.05-1.72 3.19-2.22a9.22 9.22 0 013.67-.74c2.83 0 5.24 1.07 7.23 3.21 1.98 2.14 2.97 4.89 2.97 8.24 0 3.23-1.03 5.94-3.07 8.11-2.05 2.18-4.48 3.26-7.29 3.26-1.25 0-2.42-.22-3.49-.67-1.08-.45-2.15-1.17-3.21-2.19v10.24h-5.41V12.62h5.41zm5.73 4.47c-1.72 0-3.14.59-4.27 1.76-1.13 1.17-1.7 2.7-1.7 4.59 0 1.93.57 3.49 1.7 4.68 1.13 1.19 2.56 1.79 4.27 1.79 1.66 0 3.06-.61 4.2-1.82 1.14-1.21 1.71-2.75 1.71-4.63 0-1.86-.56-3.39-1.68-4.58-1.12-1.19-2.53-1.79-4.23-1.79zM185.13 12.62h5.45v21.72h-5.45v-2.3c-1.06 1.01-2.13 1.74-3.2 2.19-1.07.45-2.23.67-3.48.67-2.8 0-5.23-1.09-7.28-3.26s-3.07-4.88-3.07-8.11c0-3.35.99-6.1 2.97-8.24 1.98-2.14 4.39-3.21 7.22-3.21 1.3 0 2.53.25 3.67.74 1.14.49 2.2 1.23 3.17 2.22v-2.42zm-5.73 4.47c-1.68 0-3.08.6-4.2 1.79-1.11 1.19-1.67 2.72-1.67 4.58 0 1.88.57 3.42 1.7 4.63s2.53 1.82 4.19 1.82c1.71 0 3.13-.6 4.26-1.79s1.69-2.75 1.69-4.68c0-1.89-.56-3.42-1.69-4.59s-2.55-1.76-4.28-1.76zM192.58 12.62h5.57l5.64 13.63 6.22-13.63h5.58L202 42.29h-5.63l4.47-9.57-8.26-20.1z"
        fill="#1DBFFF"
      />
      <path
        d="M35.81 8.03C27.96-.98 14.28-1.33 5.97 6.97c-4.02 4.02-6 9.29-5.97 14.55v20.27h20.27c5.66.04 11.32-2.26 15.43-6.9 6.72-7.6 6.77-19.21.11-26.86zm-9.94 18.84a7.738 7.738 0 01-10.94 0 7.738 7.738 0 010-10.94 7.738 7.738 0 0110.94 0 7.738 7.738 0 010 10.94z"
        fill="#5CB5FE"
      />
      <path
        d="M20.45 33.42c6.672 0 12.08-5.408 12.08-12.08S27.122 9.26 20.45 9.26c-6.671 0-12.08 5.408-12.08 12.08s5.409 12.08 12.08 12.08z"
        fill="#000B3C"
      />
      <path d="M13.676 15.31l-3.025 2.986 9.709 9.836 3.025-2.986-9.709-9.836z" fill="#fff" />
      <path d="M17.377 25.108l2.985 3.025L39.03 9.706l-2.985-3.025-18.668 18.427z" fill="#fff" />
    </svg>
  )
}

ProviPayLogoWhite.propTypes = {
  size: PropTypes.number,
}
