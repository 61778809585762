import React from 'react'
import PropTypes from 'prop-types'
import {
  AvailableProducts,
  Container,
  CPFInputWrapper,
  LogoProviPay,
  Input,
  InputContainer,
  InputCPF,
  InputWrapper,
  Header,
  ProductsContainer,
  ProviPayLogoLight,
  ProviPayLogoDark,
  Wrapper,
  BackToCart,
  CartBadge,
} from './styles'
import { PartnerInfo } from '../partnerInfo'
import { usePlatform } from '~/hooks'
import { masks } from '~/enums'
import { useHome } from '~/screens/home/hooks'
import { SearchIcon } from '~/assets/svg'
import { validateCPF } from '~/utils'

/**
 * @typedef {object} IProps
 * @property {string} activeCourses
 * @property {string} partnerLogo
 * @property {string} partnerName
 * @property {boolean} shouldRenderPartner
 * @property {number} availableProducts
 * @property {string} search
 * @property {React.Dispatch<React.SetStateAction<string>>} setSearch
 */

/** @type {React.FC<IProps>} */
export const HeaderWithSlug = ({
  activeCourses,
  partnerLogo,
  partnerName,
  shouldRenderPartner = true,
  availableProducts,
  search,
  setSearch,
  existingRequests,
  backToCart,
}) => {
  const { showCPFField, setShowCPFField, cpf, setCPF, sendCPF, cpfError, cartInformations, selectedCourses } = useHome()
  const { isMobile } = usePlatform()

  const renderCpf = () => {
    if (showCPFField) {
      return (
        <>
          <CPFInputWrapper isCpfValid={validateCPF(cpf)}>
            <InputCPF
              mask={masks.cpf || ''}
              placeholder="123.456.789-00"
              value={cpf}
              type="tel"
              error={cpfError !== ''}
              onChange={(e) => setCPF(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && validateCPF(cpf)) {
                  sendCPF()
                }
              }}
              aria-label="send-cpf-input"
            />
            <button onClick={() => validateCPF(cpf) && sendCPF()} aria-label="send-cpf-button">
              →
            </button>
          </CPFInputWrapper>
          <h3>Informe o seu CPF abaixo</h3>
        </>
      )
    } else if (!showCPFField && existingRequests == 0) {
      return (
        <button type="button" onClick={() => setShowCPFField(!showCPFField)} aria-label="show-cpf-button">
          Já comecei uma compra
        </button>
      )
    }
  }

  return (
    <Wrapper>
      <Container>
        {shouldRenderPartner && !isMobile ? (
          <Header>
            <LogoProviPay>
              <PartnerInfo partnerLogo={partnerLogo} partnerName={partnerName} />

              {cartInformations.courses && cartInformations.courses.length > 0 && selectedCourses.length > 0 ? (
                <BackToCart onClick={backToCart}>
                  <CartBadge number={selectedCourses.length} />
                  {cartInformations.courses ? 'Voltar para o carrinho' : 'Ir para o carrinho →'}
                </BackToCart>
              ) : (
                <ProviPayLogoDark />
              )}
            </LogoProviPay>
          </Header>
        ) : (
          <>
            <Header>
              <ProviPayLogoLight />
            </Header>

            <LogoProviPay>
              <PartnerInfo partnerLogo={partnerLogo} partnerName={partnerName} />
            </LogoProviPay>
          </>
        )}

        <ProductsContainer>
          <AvailableProducts isColumn={existingRequests > 0}>
            {existingRequests > 0 && <label>Você tem um pedido em andamento</label>}

            {!existingRequests && availableProducts > 0 ? (
              <strong>Produtos disponíveis ({availableProducts})</strong>
            ) : (
              <strong>Deseja continuar?</strong>
            )}
            <InputContainer>{renderCpf()}</InputContainer>
          </AvailableProducts>
        </ProductsContainer>

        {activeCourses > 6 && (
          <InputWrapper>
            <SearchIcon />
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              label="Digite o nome do produto desejado"
              placeholder="Digite o que você procura"
              aria-label="search-input"
            />
          </InputWrapper>
        )}
      </Container>
    </Wrapper>
  )
}

HeaderWithSlug.propTypes = {
  activeCourses: PropTypes.number,
  partnerLogo: PropTypes.string,
  partnerName: PropTypes.string,
  availableProducts: PropTypes.number,
}
