import React, { useEffect } from 'react'
import moment from 'moment'
import { Wrapper, Label, Row, Form } from './styles'
import { showToast, InputField as Input, ButtonV2 as Button } from '@provi/provi-components'
import { validateCPF, validateEmail, validatePhone, validateFullName, getLastName } from '~/utils/validations'
import { removeMask, validateBirthDate, getSlugPartner, stringToMask } from '~/utils'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { errorMessages, masks } from '~/enums'
import { preRegisterValues } from '../forms/preRegister'
import { useCheckout } from '../hooks'
import { useLoading } from '~/hooks'

export const RegisterStep = ({ checkCPF }) => {
  const { isSmallLoading } = useLoading()
  const { sendCheckout, informationBody, setInformationBody, cartInformations } = useCheckout()

  const { submitForm, values, errors, setFieldValue, validateForm, touched, setFieldTouched, validateField } = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      ...preRegisterValues,
    },
    validationSchema: Yup.object({
      cpf: Yup.string().required(errorMessages.requiredField),
      fullName: Yup.string().required(errorMessages.requiredField),
      phone: Yup.string().required(errorMessages.requiredField),
      birthDate: Yup.string().required(errorMessages.requiredField),
      email: Yup.string().required(errorMessages.requiredField),
      confirmEmail: Yup.string()
        .oneOf([Yup.ref('email'), null], 'Os emails devem ser iguais')
        .required(errorMessages.requiredField),
    }),
    onSubmit: (body) => {
      setInformationBody(values)
      sendCheckout(body)
    },

    validate: (fields) => {
      const fieldsArray = Object.keys(fields)
      return fieldsArray.reduce((prev, cur) => {
        if (cur === 'cpf' && fields[cur] && !validateCPF(fields[cur])) {
          return {
            ...prev,
            [cur]: errorMessages.cpf,
          }
        }

        if (cur === 'fullName' && fields[cur] && !validateFullName(fields[cur])) {
          const lastName = getLastName(fields[cur])[0]
          if (!fields[cur].includes(' ') || (lastName && lastName.length < 2) || !lastName) {
            return {
              ...prev,
              [cur]: 'Digite nome e sobrenome',
            }
          }

          return {
            ...prev,
            [cur]: errorMessages.fullName,
          }
        }

        if (cur === 'birthDate' && fields[cur] && !validateBirthDate(fields[cur])) {
          const wrongFormat = fields[cur].length < 10

          if (moment().diff(moment(fields[cur], 'DD/MM/YYYY'), 'years') <= 16 && !wrongFormat) {
            return {
              ...prev,
              [cur]: 'Você deve ter mais de 16 anos de idade',
            }
          }

          return {
            ...prev,
            [cur]: wrongFormat ? 'Digite a data no formato dd/mm/aaaa' : errorMessages.birthDate,
          }
        }
        if (cur === 'phone' && fields[cur] && !validatePhone(fields[cur])) {
          return {
            ...prev,
            [cur]: errorMessages.phone,
          }
        }

        if (cur === 'email' && fields[cur] && !validateEmail(fields[cur])) {
          return {
            ...prev,
            [cur]: errorMessages.emailInvalid,
          }
        }

        if (cur === 'confirmEmail' && fields[cur] && !validateEmail(fields[cur])) {
          return {
            ...prev,
            [cur]: errorMessages.emailInvalid,
          }
        }

        return { ...prev }
      }, {})
    },
  })

  const isFormValid = Object.keys(errors).length === 0 && Object.keys(touched).length > 5 && isSmallLoading === false

  const handleSubmit = (event) => {
    event.preventDefault()
    isFormValid ? submitForm() : showToast('Preencha os campos corretamente')
  }

  const lockCPF = getSlugPartner() === 'rocketseat' && cartInformations.CPF != null

  useEffect(() => {
    if (lockCPF) {
      setFieldValue('cpf', stringToMask(cartInformations.CPF, '999.999.999-99'))
      setFieldTouched('cpf')
      validateField('cpf')
      validateForm()
    }
  }, [])

  useEffect(() => {
    if (
      (values.cpf != null || informationBody.cpf) &&
      (removeMask(values.cpf).length || removeMask(informationBody.cpf).length) === 11
    ) {
      !!informationBody &&
        values.cpf != informationBody.cpf &&
        Object.fromEntries(
          Object.entries(informationBody).map(
            ([key, value]) => key != key.CPF && setFieldTouched(key) && setFieldValue(key, value)
          )
        )

      setFieldTouched('cpf')
      validateField('cpf')
      validateForm()
      if (validateCPF(values.cpf)) {
        checkCPF(values.cpf)
      }
    }
  }, [values.cpf])

  useEffect(() => {
    if (values.email.length > 0 && values.email === values.confirmEmail) {
      setFieldTouched('confirmEmail')
      validateField('confirmEmail')
      validateForm()
    }
  }, [values.confirmEmail])

  return (
    <Wrapper id="formWrapper">
      <Label>Informe o seu CPF para continuar:</Label>
      <Form onSubmit={(event) => handleSubmit(event)}>
        <Row>
          <Input
            isRequired={true}
            disabled={lockCPF}
            label="CPF"
            placeholder="123.456.789-00"
            data-recording-sensitive
            value={values.cpf != null ? values.cpf : informationBody.cpf}
            id="cpf"
            hasError={errors.cpf && touched.cpf}
            isValid={!errors.cpf && touched.cpf}
            errorMessage={errors.cpf}
            mask={masks.cpf}
            onChange={(e) => {
              setFieldValue('cpf', e.target.value)
              informationBody.cpf = e.target.value
            }}
            onKeyDown={(e) => {
              if (e.key == 'Enter') {
                setFieldTouched('cpf')
                validateForm()
                if (validateCPF(e.target.value)) {
                  checkCPF(e.target.value)
                }
              }
            }}
            onBlur={(e) => {
              setFieldTouched('cpf')
              validateForm()
              if (validateCPF(e.target.value)) {
                !touched.cpf && checkCPF(e.target.value)
              }
            }}
          />
          {!errors.cpf && touched.cpf && (
            <Input
              isRequired={true}
              label="Nome completo"
              placeholder="Pedro Silva"
              value={values.fullName != null ? values.fullName : informationBody.fullName}
              id="fullName"
              type="text"
              hasError={errors.fullName && touched.fullName}
              isValid={!errors.fullName && touched.fullName}
              errorMessage={errors.fullName}
              onChange={(e) => setFieldValue('fullName', e.target.value)}
              onBlur={() => {
                setFieldTouched('fullName')
                validateForm()
              }}
            />
          )}
        </Row>
        {!errors.cpf && touched.cpf && (
          <>
            <Row>
              <Input
                isRequired={true}
                label="Celular"
                placeholder="(11) 95771-2414"
                value={values.phone != null ? values.phone : informationBody.phone}
                id="phone"
                hasError={errors.phone && touched.phone}
                isValid={!errors.phone && touched.phone}
                errorMessage={errors.phone}
                mask={masks.phone}
                onChange={(e) => setFieldValue('phone', e.target.value)}
                onBlur={() => {
                  setFieldTouched('phone')
                  validateForm()
                }}
              />

              <Input
                isRequired={true}
                label="Data de nascimento"
                placeholder="31/10/1980"
                value={values.birthDate != null ? values.birthDate : informationBody.birthDate}
                id="birthDate"
                hasError={errors.birthDate && touched.birthDate}
                isValid={!errors.birthDate && touched.birthDate}
                errorMessage={errors.birthDate}
                mask={masks.birthDate}
                onChange={(e) => setFieldValue('birthDate', e.target.value)}
                onBlur={() => {
                  setFieldTouched('birthDate')
                  validateForm()
                }}
              />
            </Row>
            <Row>
              <Input
                isRequired={true}
                label="E-mail"
                placeholder="pedro@email.com.br"
                value={values.email != null ? values.email : informationBody.email}
                id="email"
                type="email"
                hasError={errors.email && touched.email}
                isValid={!errors.email && touched.email}
                errorMessage={errors.email}
                onChange={(e) => setFieldValue('email', e.target.value)}
                onBlur={() => {
                  setFieldTouched('email')
                  validateForm()
                }}
              />
              <Input
                isRequired={true}
                label="Confirme seu e-mail"
                placeholder="pedro@email.com.br"
                value={values.confirmEmail != null ? values.confirmEmail : informationBody.confirmEmail}
                id="confirmEmail"
                type="email"
                hasError={errors.confirmEmail && touched.confirmEmail}
                isValid={!errors.confirmEmail && touched.confirmEmail}
                errorMessage={errors.confirmEmail}
                onPaste={(e) => (values.email.includes('test') ? null : e.preventDefault())}
                onChange={(e) => setFieldValue('confirmEmail', e.target.value)}
                onBlur={() => {
                  setFieldTouched('confirmEmail')
                  validateForm()
                }}
                onKeyDown={(e) => {
                  if (e.key == 'Enter') {
                    setFieldTouched('confirmEmail')
                    validateForm()
                    submitForm()
                  }
                }}
              />
            </Row>
            <Row justifyContent={'flex-end'}>
              <Button
                text="Avançar"
                disabled={!isFormValid}
                onClick={(event) => handleSubmit(event)}
                id="btnNext"
                persistFloatingMode={false}
                marginVertical={0}
                marginHorizontal={0}
              />
            </Row>
          </>
        )}
      </Form>
    </Wrapper>
  )
}
