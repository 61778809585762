import { Home, CheckoutCart, Success, NotFound, Authorization } from '~/screens'

export const routes = [
  {
    path: '/courses/:slug',
    component: Home,
    exact: true,
  },
  {
    path: '/success',
    component: Success,
    exact: true,
  },
  {
    path: '/404',
    component: NotFound,
    exact: false,
  },
  {
    path: '/checkout/:slug',
    component: CheckoutCart,
    exact: false,
  },
  {
    path: '/authorization',
    component: Authorization,
    exact: false,
  },
  { path: '/', externalRedirect: 'https://provi.com.br/transformar-minha-carreira' },
  { path: '/courses', externalRedirect: 'https://provi.com.br/transformar-minha-carreira' },
  { path: '/checkout', externalRedirect: 'https://provi.com.br/transformar-minha-carreira' },
  {
    path: '/:slug',
    redirect: '/courses/:slug',
    exact: true,
  },
]
