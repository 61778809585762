import ReactGA from 'react-ga'
import { LocalStorageKeys } from '~/enums'
import { logToConsole } from './debugging'

/**
 * @description Gets the stored user at Local Storage
 * @type {() => string}
 */
export function getAnalyticsUser() {
  const user = localStorage.getItem(LocalStorageKeys.analyticsUser)
  if (user) {
    return user
  }
  const token = localStorage.getItem(LocalStorageKeys.token)
  return token
}

/**
 * @description Stores the user on the LocalStorage
 * @type {(cpf: string, email: string) => void}
 */
export function storeAnalyticsUser(cpf, email) {
  try {
    const user = `${cpf}-${email}`
    localStorage.setItem(LocalStorageKeys.analyticsUser, user)
    ReactGA.set({
      userId: user,
    })
  } catch (error) {
    logToConsole(error, 'analytics:setUser:error')
  }
}
