import * as React from 'react'

/** @type {React.FC} */
export const RegisterStep = (props) => {
  const { active } = props
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.69193 11.7018C6.41579 11.7018 6.19193 11.9257 6.19193 12.2018C6.19193 12.478 6.41579 12.7018 6.69193 12.7018H11.4488C11.7249 12.7018 11.9488 12.478 11.9488 12.2018C11.9488 11.9257 11.7249 11.7018 11.4488 11.7018H6.69193ZM6.69193 2.97461C6.41579 2.97461 6.19193 3.19847 6.19193 3.47461C6.19193 3.75075 6.41579 3.97461 6.69193 3.97461H11.4488C11.7249 3.97461 11.9488 3.75075 11.9488 3.47461C11.9488 3.19847 11.7249 2.97461 11.4488 2.97461H6.69193ZM3.78935 2.97461C3.51321 2.97461 3.28935 3.19847 3.28935 3.47461C3.28935 3.75075 3.51321 3.97461 3.78935 3.97461H4.19247C4.46862 3.97461 4.69247 3.75075 4.69247 3.47461C4.69247 3.19847 4.46862 2.97461 4.19247 2.97461H3.78935ZM6.69193 5.8839C6.41579 5.8839 6.19193 6.10776 6.19193 6.3839C6.19193 6.66004 6.41579 6.8839 6.69193 6.8839H11.4488C11.7249 6.8839 11.9488 6.66004 11.9488 6.3839C11.9488 6.10776 11.7249 5.8839 11.4488 5.8839H6.69193ZM3.78935 5.8839C3.51321 5.8839 3.28935 6.10776 3.28935 6.3839C3.28935 6.66004 3.51321 6.8839 3.78935 6.8839H4.19247C4.46862 6.8839 4.69247 6.66004 4.69247 6.3839C4.69247 6.10776 4.46862 5.8839 4.19247 5.8839H3.78935ZM6.69193 8.79297C6.41579 8.79297 6.19193 9.01683 6.19193 9.29297C6.19193 9.56911 6.41579 9.79297 6.69193 9.79297H11.4488C11.7249 9.79297 11.9488 9.56911 11.9488 9.29297C11.9488 9.01683 11.7249 8.79297 11.4488 8.79297H6.69193ZM3.78935 8.79297C3.51321 8.79297 3.28935 9.01683 3.28935 9.29297C3.28935 9.56911 3.51321 9.79297 3.78935 9.79297H4.19247C4.46862 9.79297 4.69247 9.56911 4.69247 9.29297C4.69247 9.01683 4.46862 8.79297 4.19247 8.79297H3.78935ZM3.78935 11.7018C3.51321 11.7018 3.28935 11.9257 3.28935 12.2018C3.28935 12.478 3.51321 12.7018 3.78935 12.7018H4.19247C4.46862 12.7018 4.69247 12.478 4.69247 12.2018C4.69247 11.9257 4.46862 11.7018 4.19247 11.7018H3.78935ZM1.77375 0.5H13.4643C14.1668 0.5 14.7381 1.07101 14.7381 1.77778V14.2222C14.7381 14.929 14.1667 15.5 13.4643 15.5H1.77375C1.07135 15.5 0.5 14.929 0.5 14.2222V1.77778C0.5 1.07101 1.07135 0.5 1.77375 0.5Z"
        stroke={active ? 'white' : '#4E5A99'}
        strokeLinecap="round"
      />
    </svg>
  )
}
