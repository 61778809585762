import { api } from './config'
import { handleError } from '~/utils'
import qs from 'query-string'
import '~/typings'

const { REACT_APP_API_ENV } = process.env

/**
 * @name authorizationWirecard
 * @param {string} code
 * @returns {Promise<any>}
 */
export const authorizationWirecard = async (code) => {
  const authURL =
    REACT_APP_API_ENV === 'production' ? 'https://ms-wirecard.provi.com.br/v1' : 'https://ms-wirecard-staging.provi.com.br/v1'
  try {
    await api.post(`${authURL}/register/auth`, {
      code,
    })
  } catch (error) {
    handleError(error)
  }
}

/**
 * @name getUserCourses
 * @param {{ slug: string, cpf: string, pageItems: number, search: string }} params
 * @returns {Promise<IUserInfo>}
 */
export const getUserCourses = async ({ slug, cpf, pageItems = 10, search, p }) => {
  try {
    const params = { limit: pageItems }

    if (cpf) {
      params.cpf = cpf
    }
    if (search) {
      params.courseName = search
    }

    if (p) {
      params.p = p
    }

    const query = qs.stringify(params)
    const { data } = await api.get(`courses/${slug}?${query}`)
    return data
  } catch (error) {
    if (error?.response?.data?.errorName === 'InvalidPartner' && !window.href.includes('authorization')) {
      return history.push(`/bloqueado/${slug}`)
    }
    handleError(error)
  }
}

/**
 * @name placeAddress
 * @param {IProccessAddressPayload} params
 * @returns {Promise<IProccessAddressPayload>}
 */
export const placeAddress = async (params) => {
  try {
    const { data } = await api.post('checkout/place-address', { ...params })
    return data
  } catch (error) {
    handleError(error)
  }
}

/**
 * @name getCheckoutCart
 * @param {{ slug: string, cpf: string, courses: number[], campaignSlug: string, p: string }} params
 * @returns {Promise<ICheckoutCartInfo>}
 */
export const getCheckoutCart = async (params) => {
  const { campaignSlug, cpf, courses = [], slug, p } = params

  try {
    const requestParams = {}

    if (courses.length) requestParams.courses = JSON.stringify(courses)
    if (campaignSlug) requestParams.campaignSlug = campaignSlug
    if (cpf) requestParams.cpf = cpf
    if (p) requestParams.p = p

    const query = qs.stringify(requestParams)
    const { data } = await api.get(`checkout/${slug}?${query}`)
    return data
  } catch (error) {
    handleError(error)
  }
}

export const placeCourseFinancingOrder = async (params) => {
  try {
    const { data } = await api.post('checkout/course-financing', { ...params })
    return data
  } catch (error) {
    handleError(error)
  }
}
export const continueCheckout = async (params) => {
  try {
    return await api.post('checkout/continue-request', params)
  } catch (error) {
    handleError(error)
  }
}

/**
 * @name createCheckout
 * @param {ICreateParams} params
 * @returns {Promise<ICreteCheckoutRes>}
 */
export const createCheckout = async (params) => {
  const {
    CPF,
    fullName,
    phone,
    birthDate,
    courseArray,
    email,
    PartnerId,
    CreditRequestId = null,
    campaignSlug = null,
    p = null,
  } = params
  /** @type {Partial<ICreateParams>} */
  const body = {
    CPF,
    courseArray,
    email,
    PartnerId,
    fullName,
    phone,
    birthDate,
  }

  if (campaignSlug) {
    body.campaignSlug = campaignSlug
  }

  if (CreditRequestId) {
    body.CreditRequestId = CreditRequestId
  }

  if (p) {
    body.p = p
  }

  try {
    const { data } = await api.post('checkout/create-cr', { ...body })
    return data
  } catch (error) {
    handleError(error)
    return error
  }
}

/**
 * @name proccessPayment
 * @param {IProccessPaymentPayload} params
 * @returns {Promise<IPaymentSuccessRes>}
 */
export const proccessPayment = async (params) => {
  try {
    const { data } = await api.post('payment/process-payment', { ...params })
    return data
  } catch (error) {
    handleError(error)
  }
}
