/**
 * @name addDotForDecimal
 * @param {number} integer
 * @return {string}
 **/

function addDotForDecimal(integer) {
  const stringfied = String(integer)
  if (stringfied.length === 1) {
    return integer
  }
  const idx = stringfied.length - 2
  return stringfied.slice(0, idx) + '.' + stringfied.slice(idx + Math.abs(0))
}

/**
 * @name parseCurrency
 * @param {number} monetaryValue
 * @param {boolean} shouldAddDotForDecimal
 * @return {string}
 **/

export function parseCurrency(monetaryValue, shouldAddDotForDecimal = false) {
  if (String(monetaryValue).includes('.') || !shouldAddDotForDecimal) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(monetaryValue)
  }
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(addDotForDecimal(monetaryValue))
}
