// Step Fields

export const personalDataInitialValues = {
  cpf: '',
  fullName: '',
  phone: '',
  birthDate: '',
  email: '',
  confirmationEmail: '',
}

export const addressDataInitialValues = {
  cep: '',
  street: '',
  number: '',
  complement: '',
  state: 'AC',
  city: '',
  district: '',
}

export const paymentInfoInitialValues = {
  partnerConditionId: 0,
  cardName: '',
  cardNumber: '',
  cardCvv: '',
  cardExpireDate: '',
  holderCPF: '',
  phone: '',
  birthDate: '',
}
