import { useContext, useMemo } from 'react'
import { TemplateContext } from '~/components/template/context'

/**
 * @typedef LoadingHook
 * @property {Function} setIsLoading
 * @property {boolean} isLoading
 */

/**
 * @name useLoading
 * @returns {LoadingHook}
 */

export const useLoading = () => {
  const { setIsLoading, isLoading, setIsSmallLoading, isSmallLoading } = useContext(TemplateContext)

  return useMemo(
    () => ({
      setIsLoading,
      isLoading,
      setIsSmallLoading,
      isSmallLoading,
    }),
    [setIsLoading, isLoading, setIsSmallLoading, isSmallLoading]
  )
}
