import * as React from 'react'

export const Calendar = ({ ...props }) => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.75 2.5H1.75C1.33579 2.5 1 2.83579 1 3.25V18.25C1 18.6642 1.33579 19 1.75 19H16.75C17.1642 19 17.5 18.6642 17.5 18.25V3.25C17.5 2.83579 17.1642 2.5 16.75 2.5Z"
        stroke="#000B3C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13.75 1V4" stroke="#000B3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.75 1V4" stroke="#000B3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 7H17.5" stroke="#000B3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.875 10.75H8.5L7 12.625C7.24664 12.625 7.48947 12.6858 7.70699 12.8021C7.92452 12.9183 8.11002 13.0864 8.24708 13.2915C8.38413 13.4965 8.46851 13.7322 8.49274 13.9776C8.51697 14.2231 8.48031 14.4707 8.38599 14.6986C8.29168 14.9265 8.14263 15.1276 7.95203 15.2842C7.76144 15.4407 7.53518 15.5478 7.29331 15.596C7.05143 15.6443 6.80139 15.6321 6.56534 15.5606C6.32928 15.4892 6.11449 15.3606 5.93999 15.1863"
        stroke="#000B3C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.75 11.875L12.25 10.75V15.625"
        stroke="#000B3C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
