import { useState, useCallback } from 'react'
import { logToConsole } from '~/utils'

export const useGeolocation = () => {
  const [geolocation, setGeolocation] = useState({
    latitude: 0,
    longitude: 0,
  })

  const watchPosition = useCallback(() => {
    navigator.geolocation.watchPosition(
      (position) => {
        return setGeolocation({
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        })
      },
      (e) => logToConsole(e, 'geolocation:watch:position')
    )
  }, [])

  /** @type {() => Promise<Coordinates>} */
  const getGeolocation = useCallback(() => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
          })
        },
        (err) => {
          logToConsole(err, 'geolocation:get:current:position')
          reject(err)
        }
      )
    })
  }, [])

  return {
    ...geolocation,
    getGeolocation,
    watchPosition,
  }
}
