import { useCallback, useEffect, useState } from 'react'
import { useCheckout } from '~/stores'
import { useCheckoutCart } from '../checkout-cart/hooks'

export const useSuccess = () => {
  const [possibilities, setPossibilities] = useState({})
  const { selectedModality } = useCheckoutCart()
  const { paymentCreditAndBoletoSuccessData, paymentCourseFinancingSuccessData, cartInformations, creditRequest } =
    useCheckout()
  useEffect(() => {
    getSuccessData()
  }, [])

  const getSuccessData = useCallback(() => {
    const data = [
      {
        selectedModality: 'Boleto',
        screen: 'complete',
        header: 'Seu pedido foi efetuado com sucesso.',
        boletoText: `Agora é só você fazer o pagamento e nós avisaremos a ${cartInformations.partnerName} quando recebermos a confirmação do banco.`,
        question: 'Copie o código de barras abaixo ou clique no botão para visualizar o boleto.',
      },
      {
        selectedModality: 'CreditCard',
        screen: 'complete',
        header: 'Seu pedido foi efetuado com sucesso.',
        subHeader: `Assim que o pagamento for aprovado, avisaremos a ${cartInformations.partnerName} para que libere seu acesso ao curso.`,
        helperText: `Seu número de confirmação é ${paymentCreditAndBoletoSuccessData.WirecardPaymentId}.`,
      },
      {
        selectedModality: 'CourseFinancing',
        screen: 'complete',
        header: 'Para continuar, precisamos verificar o seu e-mail.',
        question: 'O e-mail ainda não chegou?',
        subHeader: `Enviamos um link para ${paymentCourseFinancingSuccessData.email || 'o seu e-email'}.`,
        helperText: `Verifique sua caixa de spam, ele pode ter ido direto pra lá. Se ainda assim não tiver recebido, anote este número: ${
          cartInformations.CreditRequestId || creditRequest
        } e mande uma mensagem pra gente.`,
      },
    ]
    setPossibilities(data.filter((x) => x.selectedModality === selectedModality)[0])
  }, [])

  return {
    selectedModality,
    possibilities,
  }
}
