import { removeMask } from '~/utils'

/** @type {(value: string) => boolean} */
export const validateCPF = (value) => {
  if (!value) {
    return false
  }
  const cpf = removeMask(value)
  if (!new RegExp(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/).test(value)) {
    return false
  }
  if (
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false
  }
  let sum = 0
  let rest
  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (11 - i)
  }
  rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) {
    rest = 0
  }
  if (rest !== parseInt(cpf.substring(9, 10), 10)) {
    return false
  }
  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (12 - i)
  }
  rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) {
    rest = 0
  }
  if (rest !== parseInt(cpf.substring(10, 11), 10)) {
    return false
  }
  return true
}

/** @type {(value: string) => boolean} */
export const validateEmail = (value) => new RegExp(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/).test(value)

/** @type {(value: string) => boolean} */
export const validateCEP = (value) => new RegExp(/^\d{5}-\d{3}$/g).test(value)

/** @type {(value: string) => boolean} */
export const validateExpireDate = (value) => new RegExp(/^(0?[1-9]|1[012])\/\d{2}$/g).test(value)

/** @type {(value: string) => boolean} */
export const validatePhone = (value) => new RegExp(/^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/g).test(value)

export function getFirstName(fullName) {
  return fullName ? fullName.split(' ')[0] : ''
}
export function getLastName(fullName) {
  if (!fullName) {
    return ''
  }
  return fullName
    .trim()
    .split(' ')
    .filter((_name, index) => index > 0)
}
function hasNumbers(string) {
  return string.split('').filter((item) => [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].includes(parseInt(item))).length
}
function isEmptyString(string) {
  return string.trim() === ''
}
function isEmpty(string) {
  return string.trim().length === 0
}
function isEmptyArray(array) {
  return array.length === 0
}

/** @type {(fullName: string) => boolean} */
export const validateFullName = (fullName) => {
  let result = true
  const REGEX = /^[A-Za-zçáàâãéèêíïóôõöúüÁÀÂÃÉÈÊÍÏÓÔÕÖÚÜ\-' ]+$/
  if (!REGEX.test(fullName)) {
    result = false
    return result
  }
  const firstName = getFirstName(fullName)
  const lastName = getLastName(fullName)

  if (
    firstName.length < 2 ||
    (lastName[lastName.length - 1] && lastName[lastName.length - 1].length > 0 && lastName[lastName.length - 1].length < 2)
  ) {
    return false
  }

  if (isEmpty(firstName) || isEmptyString(firstName) || isEmptyArray(lastName)) {
    result = false
    return result
  }

  lastName.forEach((name) => (isEmpty(name) || isEmptyString(name) || hasNumbers(name) ? (result = false) : null))
  return result
}

/** @type {(cardNumber: string) => boolean} */
export const validateCardNumber = (value) => new RegExp(/^.{12,}$/).test(removeMask(value))
