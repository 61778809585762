import React, { useMemo, useState } from 'react'
import {
  BlockWrapper,
  ButtonWrapper,
  Paragraph,
  PaymentConditions,
  PaymentConditionItem,
  PaymentConditionItems,
  RadioButton,
  Row,
  Separator,
  CourseFinancingTitle,
  CourseFinancingBottomText,
} from '../styles'
import { showToast, ButtonV2 as Button } from '@provi/provi-components'
import { defaultCurrencyZeroPlaceholder } from '~/enums'
import { parseNumber, parseCurrency } from '~/utils'
import { useCheckoutCart } from '../../hooks'
import { useLoading } from '~/hooks'

/**
 * @typedef {object} IProps
 
 */

/** @type {React.FC<IProps>}*/
export const CourseFinancing = ({ finishAction }) => {
  const { isSmallLoading } = useLoading()
  const { addressSuccessData = {} } = useCheckoutCart()
  const { CourseFinancing: CourseFinancingData = {} } = addressSuccessData
  const { paymentCondition = [] } = CourseFinancingData
  const [chosenPaymentCondition, setChosenPaymentCondition] = useState('')
  const { upfrontPMT = defaultCurrencyZeroPlaceholder } = CourseFinancingData || {}

  let customInstallment = 0

  const installments = useMemo(() => {
    return paymentCondition.map(
      ({ installments: installmentsData = [], installmentsToApply, PartnerConditionId, monthlyInterest }, index) => {
        let isActive = chosenPaymentCondition == PartnerConditionId
        return (
          <div key={`${PartnerConditionId}-${index}`}>
            {index !== 0 && <Separator />}
            <Row isActive={isActive}>
              <RadioButton
                type="radio"
                name="paymentCondition"
                value={PartnerConditionId}
                onChange={(e) => setChosenPaymentCondition(e.target.value)}
              />
              {installmentsData.length === 1 && (
                <PaymentConditionItem isActive={isActive}>
                  <div>
                    <p>{upfrontPMT}</p>
                    <h2>
                      + <strong>{installmentsToApply}x</strong> de <strong>{installmentsData[0]?.pmt || 'R$ 0.00'}</strong>
                    </h2>
                  </div>
                  <div>
                    <h3>{monthlyInterest > 0 ? `juros de ${monthlyInterest.toLocaleString('pt-BR')}% a.m.` : 'sem juros'}</h3>
                    <span> (total: {installmentsData[0].total})</span>
                  </div>
                </PaymentConditionItem>
              )}
              {installmentsData.length !== 1 && (
                <PaymentConditionItems>
                  <div>
                    {upfrontPMT !== 'R$ 0,00' && <p>{upfrontPMT} +</p>}
                    {installmentsData.map((installment, index) => {
                      customInstallment = customInstallment + installment.numberOfInstallments
                      return (
                        <h2 key={`${PartnerConditionId}-${index}`}>
                          <strong>
                            Parcela {customInstallment + 1 - installment.numberOfInstallments} a {customInstallment}
                          </strong>{' '}
                          de{' '}
                          <strong>
                            {installment?.pmt || 'R$ 0.00'}
                            {index + 1 !== installmentsData.length && ' +'}
                          </strong>
                        </h2>
                      )
                    })}
                  </div>
                  <div>
                    <h3>{monthlyInterest > 0 ? `juros de ${monthlyInterest.toLocaleString('pt-BR')}% a.m.` : 'sem juros'}</h3>
                    <span>
                      {' '}
                      (total:{' '}
                      {parseCurrency(
                        installmentsData
                          .map((installment) => parseNumber(installment.total))
                          .reduce(function (accumVariable, curValue) {
                            return accumVariable + curValue
                          }, 0)
                      )}
                      )
                    </span>
                  </div>
                </PaymentConditionItems>
              )}
            </Row>
          </div>
        )
      }
    )
  }, [paymentCondition, chosenPaymentCondition])

  const handleSubmit = () => {
    finishAction({ partnerConditionId: Number(chosenPaymentCondition) })
  }

  return (
    <>
      <BlockWrapper>
        <CourseFinancingTitle>Transforme sua carreira de um jeito que cabe no seu bolso!</CourseFinancingTitle>
        <Paragraph>
          Pague o valor da entrada e parcele o restante em até{' '}
          {paymentCondition &&
            paymentCondition[paymentCondition.length - 1]?.installments
              .map((installment) => installment.numberOfInstallments)
              .reduce(function (accumVariable, curValue) {
                return accumVariable + curValue
              }, 0)}{' '}
          vezes no boleto.
        </Paragraph>
      </BlockWrapper>
      <PaymentConditions>{installments}</PaymentConditions>
      <CourseFinancingBottomText>
        Cada parcela é composta pela amortização do valor parcelado + juros do período (se houver) + IOF fixo de 0,38% + IOF
        diário de 0,0082%
      </CourseFinancingBottomText>
      <ButtonWrapper>
        <Button
          text="Continuar"
          id="btnNextFinancing"
          disabled={!chosenPaymentCondition || isSmallLoading}
          onClick={() => (chosenPaymentCondition ? handleSubmit() : showToast('Você esqueceu de escolher um parcelamento 😅'))}
          persistFloatingMode={false}
          marginVertical={0}
          marginHorizontal={0}
        />
      </ButtonWrapper>
    </>
  )
}
