import styled, { keyframes } from 'styled-components'

export const rotate = keyframes`
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(315deg);
  }
`

export const Box = styled.div`
  width: 25px;
  height: 25px;
  position: relative;
`

export const ArrowWrapper = styled.div`
  width: 100%;
  height: 100%;
  transform: rotate(-45deg);
  animation: ${rotate} 0.4s linear infinite;
`

export const Arrow = styled.div`
  position: absolute;
  top: 50%;
  left: -4px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 7.5px solid transparent;
  border-bottom: 7.5px solid transparent;
  border-bottom-width: 7.5px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  border-right: 7.5px solid black;
`

export const Box2 = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: ' ';
  border: 7.5px solid #1695f0;
  box-sizing: border-box;
  width: 95%;
  height: 95%;
  border-radius: 50%;
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.1);
`
