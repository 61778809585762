import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'
import { fonts } from '~/assets/fonts'
import { ArrowRight } from '~/assets/svg'
import { colors } from '~/theme'

export const ButtonWrapper = styled.div`
  display: flex;
  align-self: flex-end;

  ${theme.breakpoints.down('md')} {
    align-self: center;
    position: fixed;
    bottom: 0;

    button {
      width: 100vw;
      border-radius: 0;
    }
  }
`

export const ArrowLeftButton = styled(ArrowRight)`
  cursor: pointer;
  align-self: flex-start;
  transform: rotate(180deg);
  margin-left: 5px;
  margin-bottom: 15px;
  width: 22px;
  height: 22px;
`

/** Index styles */

export const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${theme.colors.white100};
  padding: 18px 32px;
`

export const PaymentText = styled.h1`
  font-family: ${fonts.montserrat.bold};
  color: ${theme.colors.pink900};
  font-size: 16px;
  width: 100%;
  max-width: 588px;
`

export const FormContainer = styled.div`
  display: flex;
  max-width: 588px;
  width: 50vw;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  padding-bottom: 20px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
    max-width: 100vw;
  }
`

export const PaymentMethods = styled.div`
  max-width: 576px;
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, minmax(100px, 100%));
  gap: 18px;
  padding: 22px 0 34px 0;

  ${theme.breakpoints.down('lg')} {
    gap: 8px;
  }
`

export const SVGWrapper = styled.div`
  width: 18px;
  margin-right: 18px;
  ${theme.breakpoints.down('lg')} {
    margin-right: 0;
  }
`

/** @type {import('styled-components').StyledComponent<"div", any, { isActive: boolean, disabled: boolean }>} */
export const PaymentMethodWrapper = styled.div`
  cursor: pointer;
  border: 1px solid ${(props) => (props.isActive ? theme.colors.blue600 : theme.colors.grey500)};
  background: ${(props) => (props.isActive ? 'rgba(92, 181, 254, 0.25)' : theme.colors.white100)};
  border-radius: 2px;
  max-width: 180px;
  width: 100%;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.disabled &&
    css`
      background: ${(props) => props.disabled && '#F1F1F1'};
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 0.5;
      cursor: not-allowed;
    `}
  ${theme.breakpoints.down('lg')} {
    border-radius: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 82px;
  }
  ${theme.breakpoints.down('md')} {
    height: 62px;
  }
  h2 {
    font-family: ${(props) => (props.isActive ? fonts.montserrat.bold : fonts.montserrat.regular)};
    color: ${theme.colors.blue900};
    font-size: 14px;
    max-width: 128px;

    ${theme.breakpoints.down('lg')} {
      max-width: 92px;
      text-align: center;
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 12px;
      svg {
        margin-top: -10px;
        margin-bottom: -10px;
      }
    }
  }
`

/** courseFinancing styles */

export const BlockWrapper = styled.div`
  margin-bottom: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
`

export const CourseFinancingTitle = styled.h1`
  margin: 0;
  font-family: ${fonts.montserrat.bold};
  color: ${theme.colors.blue900};
  font-size: 16px;
  text-align: center;
  max-width: 5512px;
  width: 100%;
  ${theme.breakpoints.down('md')} {
    font-size: 14px;
    max-width: 100%;
  }
`

export const Paragraph = styled.p`
  margin: 5px 0;
  font-family: ${fonts.montserrat.regular};
  color: ${theme.colors.blue900};
  font-size: 14px;
  text-align: center;
  max-width: 512px;
  width: 100%;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    max-width: 100%;
  }
`

export const CourseFinancingBottomText = styled.p`
  margin: 0 0 36px 0;
  font-family: ${fonts.montserrat.regular};
  color: ${theme.colors.blue900};
  font-size: 14px;
  text-align: center;
  max-width: 512px;
  width: 100%;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    max-width: 100%;
  }
`

export const PaymentConditions = styled.form`
  width: 100%;
  max-width: 580px;
  margin-bottom: 34px;
  ${theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`

export const Separator = styled.div`
  width: calc(100% - 16px);
  height: 1px;
  margin: 0 auto;
  border-bottom: 1px solid #a9d9ff;
`

/** @type {import('styled-components').StyledComponent<"label", any, { isActive: boolean }>} */
export const Row = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ isActive }) => (isActive ? '#A9D9FF' : 'none')};
  padding: 8px;
  width: 100%;
  cursor: pointer;

  p {
    margin: 0;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      width: 100%;
      border-radius: 8px;
    `}
`

export const RadioButton = styled.input`
  width: 18px;
  height: 18px;
  appearance: none;
  background-clip: content-box, padding-box;

  border: 1px solid ${theme.colors.blue900};
  border-radius: 50%;
  outline: none;
  padding: 3px;
  background-image: #ffffff;

  :checked {
    background-image: linear-gradient(to bottom, ${theme.colors.pink900} 0%, ${theme.colors.pink900} 100%),
      linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
  }
`

export const PaymentConditionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.blue900};
  font-family: ${fonts.montserrat.bold};
  font-size: 14px;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid ${({ isActive }) => (isActive ? theme.colors.blue900 : theme.colors.pink900)};
    margin-left: 12px;
    width: 50%;
    p {
      margin-right: 4px;
    }
    h2 {
      font-size: 12px;
      font-family: ${fonts.montserrat.regular};
      margin: 0;

      strong {
        font-size: 14px;
        font-family: ${fonts.montserrat.bold};
      }
    }
    h3 {
      font-size: 14px;
      margin: 0;
    }
    span {
      font-size: 12px;
      font-family: ${fonts.montserrat.regular};
      margin-left: 2px;
    }

    ${theme.breakpoints.down('md')} {
      flex-direction: column;
      align-items: flex-start;
      font-size: 12px;

      h2,
      h3,
      span {
        padding-left: 0px;
        padding-right: 0px;
        font-size: 12px;
        margin: 0;
      }
    }

    ${theme.breakpoints.down('sm')} {
      margin-left: 4px;
    }

    :last-child {
      border-right: none;
    }
  }
`

export const PaymentConditionItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.blue900};
  font-family: ${fonts.montserrat.bold};
  font-size: 14px;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-right: 1px solid ${({ isActive }) => (isActive ? theme.colors.blue900 : theme.colors.pink900)};
    margin-left: 12px;
    width: 50%;
    p {
      margin-right: 4px;
    }
    h2 {
      font-size: 12px;
      font-family: ${fonts.montserrat.regular};
      font-weight: normal;
      margin: 0;

      strong {
        font-size: 14px;
        font-family: ${fonts.montserrat.bold};
        white-space: nowrap;
      }
    }
    h3 {
      font-size: 14px;
      margin: 0;
    }
    span {
      font-size: 12px;
      font-family: ${fonts.montserrat.regular};
      margin-left: 2px;
    }

    ${theme.breakpoints.down('md')} {
      flex-direction: column;
      align-items: flex-start;
      font-size: 12px;

      h2,
      h3,
      span {
        padding-left: 0px;
        padding-right: 0px;
        font-size: 12px;
        margin: 0;
      }
    }

    ${theme.breakpoints.down('sm')} {
      margin-left: 4px;
    }

    :last-child {
      border-right: none;
    }
  }

  div + div {
    flex-direction: row;
    ${theme.breakpoints.down('md')} {
      flex-direction: column;
    }
  }
`

/** paymentInfo styles */

export const FormWrapper = styled.form`
  width: 100%;
  font-family: ${fonts.montserrat.regular};
  display: flex;
  flex-direction: column;
`

export const InputRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  align-self: center;
  width: 100%;
  margin-bottom: 24px;
  align-items: flex-start;

  label {
    justify-content: space-between;
  }

  ${theme.breakpoints.down('md')} {
    grid-template-columns: repeat(1, 1fr);
    max-width: 580px;
  }
`

export const Wrapper = styled.div`
  * {
    box-sizing: border-box;
  }
  background: ${theme.colors.white100};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 32px;
  margin-bottom: 36px;

  ${theme.breakpoints.down('md')} {
    max-width: 800px;
  }
  ${theme.breakpoints.down('sm')} {
    padding: 8px 10px;
  }
  ${theme.breakpoints.down('xs')} {
    padding: 2px 3px;
  }
`

/** Address Styles */

export const AddressWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`

export const AddressTitle = styled.h1`
  width: 100%;
  font-family: ${fonts.montserrat.bold};
  font-size: 20px;
  color: ${theme.colors.pink900};
  margin-bottom: 24px;
`

export const AddressForm = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  justify-content: center;
  margin-bottom: 32px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const SmallInputRow = styled.div`
  width: 100%;
  max-width: 280px;
  display: flex;
  gap: 24px;
  align-items: flex-start;

  ${theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`

export const AddressFullInput = styled.div`
  display: flex;
  width: 100%;
  max-width: 280px;
  align-items: flex-start;
  ${theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`

export const AddressSmallInputs = styled.div`
  display: flex;
  width: 100%;
  max-width: 280px;
  justify-content: space-between;
  gap: 24px;
  align-items: flex-start;

  ${theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  label {
    font-family: ${fonts.montserrat.bold};
    color: ${theme.colors.blue900};
    font-size: 14px;
    ::after {
      content: ' *';
      color: ${theme.colors.pink900};
    }
  }
`

export const Selector = styled.select`
  width: 100%;
  height: 40px;
  border: 1px solid ${theme.colors.grey600};
  border-radius: 3px;
  background: none;
  font-size: 16px;
  font-family: ${fonts.montserrat.regular};
  padding: 8px;
  margin-top: 8px;
  color: ${theme.colors.blue900};

  :focus {
    outline-color: ${theme.colors.blue600};
  }
`

export const SelectCardOwner = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fonts.montserrat.bold};
  font-size: 14px;
  width: 100%;
  ${theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`

export const SelectCardOwnerButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  font-family: ${fonts.montserrat.regular};
`
export const SelectCardOwnerLabel = styled.label`
  margin-bottom: 8px;
  color: ${theme.colors.blue900};

  ::after {
    content: ' *';
    color: ${theme.colors.pink900};
  }
`
/** @type {import('styled-components').StyledComponent<"button", any, { isActive: boolean }>} */
export const SelectCardOwnerButton = styled.button`
  width: 128px;
  height: 40px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  font-family: ${fonts.montserrat.regular};
  background: ${theme.colors.white100};
  color: ${theme.colors.blue900};
  border: 1px solid ${theme.colors.grey500};

  :focus {
    outline: none;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      font-family: ${fonts.montserrat.bold};
      background: rgba(92, 181, 254, 0.2);
      border: 1px solid ${theme.colors.blue600};
    `}

  ${theme.breakpoints.down('md')} {
    width: 48%;
  }
`

export const Option = styled.option`
  color: ${theme.colors.blue900};
`

export const SmallInputWrapper = styled.div`
  display: flex;
  align-items: center;
  align-items: flex-start;

  ${theme.breakpoints.down('md')} {
    flex-direction: row;
  }
`

/** Boleto Styles */

export const BoletoWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  font-family: ${fonts.montserrat.bold};
  margin-bottom: 40px;
`
export const Title = styled.h1`
  font-size: 16px;
  margin-bottom: 18px;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    margin-bottom: 30px;
  }
`
export const BoletoItems = styled.ul`
  font-family: ${fonts.montserrat.regular};
  font-size: 12px;
  color: ${colors.greyedText};
  margin-bottom: 18px;

  ${theme.breakpoints.down('md')} {
    margin-bottom: 24px;
  }
`
export const BoletoItem = styled.li`
  list-style-type: none;
  display: flex;
  align-items: center;

  svg {
    margin-right: 14px;
    width: 100%;
    max-width: 18px;
  }

  :first-child {
    margin-bottom: 8px;

    ${theme.breakpoints.down('md')} {
      margin-bottom: 28px;
    }
  }
`

export const BoletoText = styled.p``

export const BoletoInformation = styled.p`
  font-size: 12px;
  color: ${colors.greyedText};
`

export const InstabilityModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
