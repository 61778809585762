import { theme } from '@provi/provi-components'
import styled from 'styled-components'
import { fonts } from '~/assets/fonts'

/**
 * @typedef {object} IButtonProps
 * @property {boolean} selected
 */

/** @type {import('styled-components').StyledComponent<"div", any, {isSelected: boolean}>} */
export const Wrapper = styled.div`
  * {
    box-sizing: border-box;
    margin: 0;
  }

  box-sizing: border-box;
  box-shadow: ${(props) => (props.isSelected ? '0px 4px 25px rgba(92, 181, 254, 0.3)' : '0px 4px 25px rgba(0, 0, 0, 0.1)')};
  border: ${(props) => (props.isSelected ? `1px solid ${theme.colors.blue600}` : `1px solid ${theme.colors.white100}`)};
  border-radius: 5px;
  background-color: ${theme.colors.white100};
  display: flex;
  flex-direction: column;
  padding: 12px 10px;
  justify-content: space-between;
  width: 100%;
  max-width: 280px;
  min-width: 280px;
  margin-bottom: 32px;

  ${theme.breakpoints.down('md')} {
    max-width: 328px;
    min-width: 328px;
    :last-child {
      margin-bottom: 0;
    }
  }
`

export const Row = styled.div`
  display: inline-flex;
  align-items: center;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const LabelTitle = styled.label`
  font-family: ${fonts.montserrat.regular};
  font-size: 12px;
  color: ${theme.colors.pink900};
  margin-bottom: 9px;
`

export const CardTitle = styled.h1`
  font-family: ${fonts.montserrat.regular};
  font-size: 16px;
  color: ${theme.colors.blue900};
  margin-bottom: 14px;
`

/** @type {import('styled-components').StyledComponent<"button", any, IButtonProps, never>} */
export const Button = styled.div`
  width: 100%;
  height: 37px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 5px;
  padding: 2px 0px;
  font-family: ${fonts.montserrat.regular};
  font-size: 12px;
  color: ${theme.colors.white100};
  transition: all 0.2s ease;
  background-color: ${theme.colors.pink900};
  :hover {
    cursor: pointer;
  }
`
