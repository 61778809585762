import * as React from 'react'

/** @type {React.FC} */
export const IdentificationCard = (props) => {
  const { active } = props
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.25 10.5H18"
        stroke={active ? 'white' : '#4E5A99'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 13.5H18"
        stroke={active ? 'white' : '#4E5A99'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.63452 13.5C9.87716 13.5 10.8845 12.4926 10.8845 11.25C10.8845 10.0074 9.87716 9 8.63452 9C7.39188 9 6.38452 10.0074 6.38452 11.25C6.38452 12.4926 7.39188 13.5 8.63452 13.5Z"
        stroke={active ? 'white' : '#4E5A99'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.729 15.75C5.89546 15.106 6.27115 14.5355 6.79704 14.1283C7.32294 13.721 7.96923 13.5 8.63438 13.5C9.29952 13.5 9.94583 13.7209 10.4718 14.1282C10.9977 14.5354 11.3734 15.1058 11.5399 15.7498"
        stroke={active ? 'white' : '#4E5A99'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.25 4.50001H3.75C3.33579 4.50001 3 4.83579 3 5.25001V18.75C3 19.1642 3.33579 19.5 3.75 19.5H20.25C20.6642 19.5 21 19.1642 21 18.75V5.25001C21 4.83579 20.6642 4.50001 20.25 4.50001Z"
        stroke={active ? 'white' : '#4E5A99'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
