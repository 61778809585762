export const masks = {
  cpf: '999.999.999-99',
  boleto: '#####.##### #####.###### #####.###### # ##############',
  phone: '(99) 99999-9999',
  creditCard: '9999 9999 9999 9999',
  expireDate: '99/99',
  cardCVV: '9999',
  cep: '99999-999',
  birthDate: '99/99/9999',
}
