import { useDebounce } from 'use-debounce'
import { showToast } from '@provi/provi-components'
import { parseUrl, stringify } from 'query-string'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { analyticsPageViews, errorMessages, eventActions, eventCategories } from '~/enums'
import { history } from '~/navigation/history'
import { useCourses, useCheckout } from '~/stores'
import { getSlugPartner, removeMask, validateCPF, handleAxiosError } from '~/utils'
import { setEvent, trackVirtualPageView } from '~/utils/trackEvent'
import { continueCheckout } from '~/services/api'
import { useLoading } from '~/hooks'
const initialAmmountOfCards = 6

export function useHome() {
  const { getDataForPartner, coursesData } = useCourses()
  const { setIsLoading } = useLoading()

  const {
    getCheckoutCartInfo,
    selectedCourses,
    setSelectedCourses,
    cpf,
    setCPF,
    continueRequestData,
    setContinueRequestData,
    setRefusedContinueRequest,
    cartInformations,
  } = useCheckout()
  const [existingRequests, setExistingRequests] = useState([])
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [showCPFField, setShowCPFField] = useState(false)
  const [cpfError, setCpfError] = useState('')
  const [first, setFirst] = useState(6)
  const { query } = parseUrl(window.location.href)
  const location = useLocation()

  useEffect(() => {
    trackVirtualPageView(analyticsPageViews.catalog)
  }, [])

  const [debouncedSearch] = useDebounce(search, 500)

  const getData = useCallback(
    async (p = null) => getDataForPartner(getSlugPartner(), first, removeMask(cpf), debouncedSearch, p),
    [first, cpf, getDataForPartner, debouncedSearch]
  )

  useEffect(() => {
    if (!location.state) {
      getData(query.p)
    } else {
      const newExistingRequests = {
        existingCourses: location.state.courses,
        cpf: location.state.cpf,
        PartnerId: location.state.PartnerId,
        PartnerName: location.state.PartnerName,
      }
      setExistingRequests(newExistingRequests)
    }
  }, [first, debouncedSearch])

  //set unremovable products selected
  const fetchUnremovable = () => {
    const { coursesWithDiscount = [], courses = [], coursesFromCPF = [] } = coursesData
    coursesFromCPF.map((element) => {
      if (!element.removable) {
        !selectedCourses.includes(element.id) && selectProduct(element.id)
      }
    })
    coursesWithDiscount.map((element) => {
      if (!element.removable) {
        !selectedCourses.includes(element.id) && selectProduct(element.id)
      }
    })
    courses.map((element) => {
      if (!element.removable) {
        !selectedCourses.includes(element.id) && selectProduct(element.id)
      }
    })
  }

  const continueRequest = useCallback(async ({ CreditRequestId, cpf, PartnerId }) => {
    setIsLoading(true)
    const postBody = { CreditRequestId, CPF: cpf, PartnerId }
    try {
      const { data } = await continueCheckout(postBody)
      setIsLoading(false)
      setContinueRequestData(data)
      data?.isOTP === true && data?.OTPLink?.length > 0 && window.location.replace(data.OTPLink)
      !data?.isOTP && history.push('/success')
    } catch (error) {
      setIsLoading(false)
      showToast(handleAxiosError(error))
    }
  }, [])

  const sendCPF = useCallback(() => {
    setCpfError('')
    if (!validateCPF(cpf)) {
      showToast(errorMessages.cpf)
      return setCpfError(errorMessages.cpf)
    }
    history.push(`/checkout/${getSlugPartner()}?cpf=${cpf}${query.p ? `&p=${query.p}` : ''}`)
  }, [getData, cpf])

  /** @type {() => Promise<void>} */
  const getCartData = useCallback(async () => {
    await getCheckoutCartInfo(getSlugPartner(), removeMask(cpf), selectedCourses, null, query.p)
    setIsCartOpen(true)
  }, [cpf, selectedCourses, getCheckoutCartInfo])

  const loadMore = useCallback(() => setFirst((prev) => prev + initialAmmountOfCards), [setFirst])

  /** @type {(products: string[]) => boolean} */
  const isComboSelected = useCallback(
    (products) => {
      const itemsInsideArray = selectedCourses.filter((e) => products.includes(e))
      return itemsInsideArray.length !== 0
    },
    [selectedCourses]
  )

  /** @type {(product: number | number[], isCombo?: boolean) => void} */
  const selectProduct = useCallback(
    (product, isCombo = false) => {
      if (isCombo && typeof product !== 'string') {
        return setSelectedCourses((prev) => {
          const itemsInsideArray = prev.filter((e) => product.includes(e))
          if (itemsInsideArray.length !== 0) {
            setEvent(eventCategories.cart, {
              action: eventActions.removeItem,
              value: product,
            })
            return prev.filter((e) => !product.includes(e))
          }
          setEvent(eventCategories.cart, {
            action: eventActions.addFromCatalog,
            value: product,
          })
          return [...prev, ...product]
        })
      }
      setSelectedCourses((prev) => {
        if (prev.includes(product)) {
          setEvent(eventCategories.cart, {
            action: eventActions.removeItem,
            value: product,
          })
          return prev.filter((e) => e !== product)
        }
        setEvent(eventCategories.cart, {
          action: eventActions.addFromCatalog,
          value: product,
        })
        return [...prev, product]
      })
    },
    [setSelectedCourses]
  )

  const goToCheckout = useCallback(() => {
    const params = {
      courses: `[${selectedCourses.toString() || query?.courses}]`,
    }
    if (cpf) {
      params.cpf = cpf
    }

    if (query.p) {
      params.p = query.p
    }

    if (query.campaign) {
      params.campaign = query.campaign
    }

    const queryURL = stringify(params)
    history.push(`/checkout/${getSlugPartner()}?${queryURL}`)
  }, [cpf, selectedCourses])

  const backToCart = () => {
    const params = {
      courses: `[${selectedCourses.toString() || query?.courses || cartInformations.courseIds}]`,
    }

    if (query.p) {
      params.p = query.p
    }

    if (query.campaign) {
      params.campaign = query.campaign
    }

    const queryURL = stringify(params)

    setRefusedContinueRequest(true)
    if (existingRequests.existingCourses && existingRequests.existingCourses.length > 0) {
      history.push(`/checkout/${getSlugPartner()}?${queryURL}`)
    } else {
      history.push(`/courses/${getSlugPartner()}?${queryURL}`)
    }
  }

  return {
    coursesData,
    cpf,
    setCPF,
    sendCPF,
    showCPFField,
    setShowCPFField,
    selectProduct,
    selectedCourses,
    loadMore,
    search,
    cpfError,
    isComboSelected,
    getCartData,
    setIsCartOpen,
    isCartOpen,
    goToCheckout,
    setSearch,
    slug: getSlugPartner(),
    fetchUnremovable,
    existingRequests,
    continueRequest,
    backToCart,
    continueRequestData,
    cartInformations,
  }
}
