export const errorMessages = {
  emailInvalid: 'Digite um email válido',
  cpf: 'Digite um CPF válido',
  expiryDate: 'Digite uma data de vencimento válida',
  birthDate: 'Digite uma data de nascimento válida',
  cardNumber: 'Bandeira não suportada',
  phone: 'Digite um número correto de celular',
  cep: 'Digite um CEP válido',
  requiredField: 'Campo obrigatório',
  unexpectedError: 'Um erro inesperado aconteceu',
  fullName: 'O nome deve ser composto e ter entre 7 e 90 letras',
}
