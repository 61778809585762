import { theme } from '@provi/provi-components'
import styled from 'styled-components'
import { fonts } from '~/assets/fonts'

export const Wrapper = styled.div`
  width: 328px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 14px;
  font-family: ${fonts.montserrat.regular};
  box-shadow: ${({ selected }) => (selected ? '0px 4px 25px rgba(92, 181, 254, 0.3)' : '0px 4px 25px rgba(0, 0, 0, 0.1)')};
  border: ${({ selected }) => (selected ? `1px solid ${theme.colors.blue600}` : `1px solid ${theme.colors.white100}`)};
  border-radius: 5px;
`

export const ComboWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span + span {
    margin-top: 24px;
  }
`

export const CourseNumber = styled.div`
  font-family: ${fonts.montserrat.bold};
  font-size: 12px;
  color: ${theme.colors.grey600};
`
export const CourseName = styled.div`
  font-family: ${fonts.montserrat.regular};
  font-size: 16px;
  color: ${theme.colors.blue900};
`
export const CoursePrice = styled.div`
  font-family: ${fonts.montserrat.bold};
  font-size: 16px;
  color: ${theme.colors.blue800};
`
export const EachCourse = styled.span`
  p {
    margin: 0;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
  margin-bottom: 14px;

  span {
    display: flex;
    align-items: center;
  }

  p {
    margin: 0;
  }
`

export const OriginalValue = styled.p`
  color: #414141;
  font-size: 12px;
  font-family: ${fonts.montserrat.regular};
  text-decoration: line-through;
  padding-left: 8px;
`

export const Discount = styled.p`
  justify-content: flex-end;
  color: ${theme.colors.pink900};
  font-size: 14px;
  font-family: ${fonts.montserrat.regular};

  span {
    padding: 4px 5px;
    background: rgba(222, 12, 75, 0.15);
    border-radius: 5px;

    svg {
      margin-right: 5px;
    }
  }
`

export const FinalValue = styled.p`
  color: ${theme.colors.blue900};
  font-size: 20px;
  font-family: ${fonts.montserrat.bold};
`

/** @type {import('styled-components').StyledComponent<"button", any, { selected: boolean }, never>} */
export const Button = styled.div`
  cursor: pointer;
  width: 100%;
  height: 38px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid ${({ selected }) => (selected ? theme.colors.blue600 : theme.colors.pink900)};
  border-radius: 5px;
  padding: 2px 10px;
  background-color: ${({ selected }) => (selected ? theme.colors.blue600 : theme.colors.white100)};

  font-family: ${fonts.montserrat.regular};
  font-size: 12px;
  color: ${({ selected }) => (selected ? theme.colors.white100 : theme.colors.pink900)};

  svg {
    margin-right: ${(props) => (props.selected ? 0 : 10)}px;

    path {
      fill: ${theme.colors.pink900};
    }
  }

  transition: all 0.2 ease;
`
