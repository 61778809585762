import styled from 'styled-components'
import { fonts } from '~/assets/fonts'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  min-height: 315px;
  min-width: 279px;
  margin-left: 35px;
  background-color: #fff;
  padding-top: 12px;
  height: fit-content;

  ${theme.breakpoints.down('md')} {
    margin-left: 0px;
    min-width: 100%;
    padding-top: 0px;
  }
`

export const Header = styled.label`
  font-family: ${fonts.montserrat.bold};
  color: ${theme.colors.blue900};
  margin-left: 11px;
  ${theme.breakpoints.down('md')} {
    font-family: ${fonts.montserrat.regular};
    font-size: 16px;
    margin-left: 19px;
    margin-top: 11px;
  }
`

export const List = styled.ul`
  list-style-type: none;
  border: 0px solid #000;
  padding: 0px;
  margin-left: 11px;
  margin-right: 12px;
  ${theme.breakpoints.down('md')} {
    margin-left: 11px;
  }
`
export const Button = styled.button`
  border: 1px solid ${theme.colors.pink900};
  display: flex;
  width: 256px;
  margin-left: 11px;
  min-height: 32px;
  border-radius: 2px;
  background-color: ${theme.colors.white100};
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  :hover {
    color: ${theme.colors.white100};
    background-color: ${theme.colors.pink900};
    cursor: pointer;
  }

  :hover label {
    color: ${theme.colors.white100};
    cursor: pointer;
  }

  :hover svg {
    fill: ${theme.colors.white100};
    cursor: pointer;
  }

  ${theme.breakpoints.down('md')} {
    min-width: 95%;
  }
`

export const ButtonLabel = styled.label`
  color: ${theme.colors.pink900};
  font-family: ${fonts.montserrat.bold};
  font-size: 14px;
  margin-left: 11px;
  ${theme.breakpoints.down('md')} {
    font-family: ${fonts.montserrat.regular};
  }
`

export const TotalPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 0px solid #000;
  margin-top: 16px;
`

export const Label = styled.label`
  color: ${theme.colors.blue900};
  font-family: ${fonts.montserrat.regular};
  ${theme.breakpoints.up('md')} {
    font-size: ${({ minimable }) => (minimable ? '12px' : '16px')};
  }
  margin-left: 11px;
  ${theme.breakpoints.down('md')} {
    font-size: '16px';
  }
`

export const LabelPrice = styled.label`
  color: ${theme.colors.blue900};
  font-family: ${fonts.montserrat.bold};
  ${theme.breakpoints.up('md')} {
    font-size: ${({ minimable }) => (minimable ? '12px' : '16px')};
  }
  ${theme.breakpoints.down('md')} {
    font-size: '16px';
  }
  margin-right: 12px;
`
export const Separator = styled.hr`
  border: 0.5px solid ${theme.colors.grey500};
  width: 279px;
  ${theme.breakpoints.down('md')} {
    min-width: 95%;
  }
  margin-top: 24px;
`
export const SafetyBuyContainer = styled.div`
  height: 45px;
  display: flex;
  padding-bottom: 5px;
  justify-content: center;
  align-items: center;
`

export const SafetyLabel = styled.label`
  color: ${theme.colors.blue900};
  font-family: ${fonts.montserrat.regular};
  font-size: 14px;
  margin-left: 11px;
`

export const LoadingStateWrapper = styled.div`
  margin-right: 40px;
`
