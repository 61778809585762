import React from 'react'
import ListItem from './listItem'
import {
  Container,
  Header,
  List,
  Button,
  TotalPriceContainer,
  Label,
  LabelPrice,
  Separator,
  SafetyBuyContainer,
  SafetyLabel,
  ButtonLabel,
  LoadingStateWrapper,
} from './styles'
import { SafetyBuy, PlusIconButton } from '~/assets/svg'
import { parseCurrency } from '~/utils'
import { LoadingState } from '../loadingState'
import { useLoading } from '~/hooks'

export const CheckoutList = ({ data, isMobile, partnerName, goToCourses, removeItem }) => {
  const { isSmallLoading } = useLoading()
  const { courses, partnerImage = null, totalDiscount, totalPrice, totalPriceToPay } = data
  return (
    <Container>
      {!isMobile && <Header>Resumo da compra</Header>}

      <List>
        {courses.map((course) => (
          <ListItem
            key={course.courseName + course.id}
            isMobile={isMobile}
            partnerImage={partnerImage}
            PartnerNameCamp={partnerName}
            removable={course.removable}
            course={course}
            removeItem={removeItem}
          />
        ))}
      </List>

      <Button onClick={goToCourses} id="add-more-items">
        <PlusIconButton />
        <ButtonLabel>Adicionar mais itens</ButtonLabel>
      </Button>

      {totalPrice && totalDiscount && (
        <TotalPriceContainer>
          <Label minimable={true}>Subtotal</Label>
          <LabelPrice minimable={true}>{parseCurrency(totalPrice)}</LabelPrice>
        </TotalPriceContainer>
      )}

      {totalDiscount && (
        <TotalPriceContainer>
          <Label minimable={true}>Descontos</Label>
          <LabelPrice minimable={true}> -{parseCurrency(totalDiscount)}</LabelPrice>
        </TotalPriceContainer>
      )}

      <TotalPriceContainer id="total-price">
        <Label>Total</Label>
        <LabelPrice>
          {isSmallLoading ? (
            <LoadingStateWrapper>
              <LoadingState />
            </LoadingStateWrapper>
          ) : (
            parseCurrency(totalPriceToPay)
          )}
        </LabelPrice>
      </TotalPriceContainer>
      {!isMobile && <Separator />}
      {!isMobile && (
        <SafetyBuyContainer>
          <SafetyBuy />
          <SafetyLabel>Compra 100% Segura</SafetyLabel>
        </SafetyBuyContainer>
      )}
    </Container>
  )
}
