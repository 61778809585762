import moment from 'moment'
import ReactGA from 'react-ga'
import debugging from 'debug'
import smartlook from 'smartlook-client'
import * as Sentry from '@sentry/browser'
import { setupFirebase } from './services/firebase'
import { LocalStorageKeys } from '~/enums'

/**
 * @typedef {object} DotEnv
 * @property {string} REACT_APP_FIREBASE_ENV
 * @property {string} REACT_APP_DEBUG
 * @property {string} REACT_APP_API_ENV
 * @property {string} REACT_APP_ANALYTICS
 */

/** @type {NodeJS.ProcessEnv & DotEnv} */
const { REACT_APP_FIREBASE_ENV, NODE_ENV, REACT_APP_DEBUG, REACT_APP_API_ENV, REACT_APP_SMART_LOOK, REACT_APP_ANALYTICS } =
  process.env

if (NODE_ENV === 'development') {
  debugging.enable(REACT_APP_DEBUG)
} else {
  debugging.disable('*')
}

if (REACT_APP_API_ENV === 'production' && NODE_ENV !== 'development' && NODE_ENV !== 'test') {
  Sentry.init({
    dsn: 'https://dff25965bf2b450e9fd64d6e98c76f96@o245612.ingest.sentry.io/5552362',
    autoSessionTracking: true,
    tracesSampleRate: 1.0,
  })
  smartlook.init(REACT_APP_SMART_LOOK)
  ReactGA.initialize(REACT_APP_ANALYTICS)
}

// initial setup firebase
setupFirebase(REACT_APP_FIREBASE_ENV)

moment.updateLocale('pt-br', require('moment/locale/pt-br'))

/** @type {string} */
export const UUID = process.env.REACT_APP_UUID

/** @type {string} */
export const WIRECARD_PUB_KEY =
  REACT_APP_API_ENV === 'production' ? process.env.REACT_APP_WIRECARD_KEY_PROD : process.env.REACT_APP_WIRECARD_KEY_DEV

const setVersion = () => {
  localStorage.setItem(LocalStorageKeys.version, 'v2')
}

setVersion()
