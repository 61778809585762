import React from 'react'
import { SelectIcon } from '~/assets/svg'
import { Percent } from '~/assets/svg/percent'
import { parseCurrency } from '~/utils/parseCurrency'
import {
  Button,
  CardOldPrice,
  CardTitle,
  DescriptionWrapper,
  DiscountContainer,
  DiscountPercentage,
  CardDescription,
  CardPrice,
  PriceContainer,
  Wrapper,
} from './styles'

/**
 * @typedef {object} IProps
 * @property {string} title
 * @property {boolean} hasSelectionProcess
 * @property {string} modality
 * @property {string} attendType
 * @property {string} price
 * @property {string} oldPrice
 * @property {string} discount
 * @property {boolean} selected
 * @property {boolean} removable
 * @property {() => void} onSelect
 */

/** @type {React.FC<IProps>}*/
export const CourseCard = ({
  title,
  price,
  oldPrice,
  discount = 0,
  selected = false,
  onSelect,
  removable,
  discountKind,
  extraInfo = [],
}) => {
  const [parsedPrice, parsedOldPrice] = [parseCurrency(Number(price)), parseCurrency(Number(oldPrice))]
  return (
    <Wrapper isSelected={selected}>
      <CardTitle>{title}</CardTitle>
      <DescriptionWrapper>
        {extraInfo.map((info, index) => (
          <CardDescription key={index}>{info}</CardDescription>
        ))}
      </DescriptionWrapper>
      <PriceContainer>
        <CardPrice isCrossed={discountKind == 'combo'}>{parsedPrice}</CardPrice>
        {+oldPrice != null && +oldPrice > +price && (
          <DiscountContainer>
            <CardOldPrice>{parsedOldPrice}</CardOldPrice>
            <DiscountPercentage>
              <Percent />
              <p>{`${Math.floor(discount)}%`}</p>
            </DiscountPercentage>
          </DiscountContainer>
        )}
      </PriceContainer>
      {discountKind != 'combo' && (
        <Button onClick={onSelect} disabled={!removable} removable={removable} selected={selected} aria-label="select-course">
          {selected ? (
            'Produto selecionado'
          ) : (
            <>
              <SelectIcon />
              Selecionar curso
            </>
          )}
        </Button>
      )}
    </Wrapper>
  )
}
