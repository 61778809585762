import { theme } from '@provi/provi-components'
import styled from 'styled-components'
import { fonts } from '~/assets/fonts'

/**
 * @typedef {object} IButtonProps
 * @property {boolean} selected
 */

/** @type {import('styled-components').StyledComponent<"div", any, {isSelected: boolean}>} */
export const Wrapper = styled.div`
  * {
    box-sizing: border-box;
    margin: 0;
  }

  box-sizing: border-box;
  box-shadow: ${(props) => (props.isSelected ? '0px 4px 25px rgba(92, 181, 254, 0.3)' : '0px 4px 25px rgba(0, 0, 0, 0.1)')};
  border: ${(props) => (props.isSelected ? `1px solid ${theme.colors.blue600}` : `1px solid ${theme.colors.white100}`)};
  border-radius: 5px;
  background-color: ${theme.colors.white100};
  display: flex;
  flex-direction: column;
  padding: 12px 10px;
  justify-content: space-between;
  width: 100%;
  max-width: 280px;
  min-width: 280px;
  margin-bottom: 32px;

  ${theme.breakpoints.down('md')} {
    max-width: 328px;
    min-width: 328px;
    :last-child {
      margin-bottom: 0;
    }
  }
`

export const CardTitle = styled.h1`
  font-family: ${fonts.montserrat.regular};
  font-size: 16px;
  color: ${theme.colors.blue900};
  margin-bottom: 14px;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const CardDescription = styled.p`
  font-family: ${fonts.montserrat.regular};
  font-size: 12px;
  color: #656565;

  & + p {
    margin-top: 6px;
  }

  ::before {
    content: '• ';
    font-size: 16px;
    line-height: 0;
  }
`

export const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 14px 0;
`

export const CardPrice = styled.h2`
  font-family: ${fonts.montserrat.bold};
  font-size: 16px;
  color: ${({ isCrossed }) => (isCrossed ? theme.colors.pink900 : theme.colors.blue900)};
  text-decoration: ${({ isCrossed }) => (isCrossed ? 'line-through double' : 'none')};
  display: flex;
  justify-items: flex-start;
  min-width: fit-content;
`

export const CardOldPrice = styled.h3`
  font-family: ${fonts.montserrat.light};
  font-size: 12px;
  color: ${theme.colors.pink900};
  margin-left: 6px;
  text-decoration: line-through;
`

export const DiscountContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

export const DiscountPercentage = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  background: rgba(222, 12, 75, 0.15);
  color: ${theme.colors.pink900};

  svg {
    margin-right: 5px;
  }

  p {
    font-family: ${fonts.montserrat.regular};
  }
`

/** @type {import('styled-components').StyledComponent<"button", any, IButtonProps, never>} */
export const Button = styled.div`
  cursor: ${(props) => (props.removable ? 'pointer' : 'default')};
  width: 100%;
  height: 37px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid ${(props) => (props.selected ? theme.colors.blue600 : theme.colors.pink900)};
  border-radius: 5px;
  padding: 2px 0px;
  background-color: ${(props) => (props.selected ? theme.colors.blue600 : theme.colors.white100)};
  font-family: ${fonts.montserrat.regular};
  font-size: 12px;
  color: ${(props) => (props.selected ? theme.colors.white100 : theme.colors.pink900)};
  transition: all 0.2s ease;

  svg {
    margin-right: ${(props) => (props.selected ? 0 : 10)}px;

    path {
      fill: ${theme.colors.pink900};
    }
  }

  :hover {
    background-color: ${(props) => (props.selected ? theme.colors.blue700 : theme.colors.pink900)};
    color: ${theme.colors.white100};
    border: 1px solid ${(props) => (props.selected ? theme.colors.blue700 : theme.colors.pink900)};

    path {
      fill: ${theme.colors.white100};
    }
  }
`
