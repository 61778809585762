import React, { createContext, useState, useCallback, useContext, useRef } from 'react'
import { showToast } from '@provi/provi-components'
import { useLoading } from '~/hooks/useLoading'
import { getUserCourses } from '~/services/api'
import * as Sentry from '@sentry/browser'
import '~/typings'
import { ROOT_URL } from '~/constants'

/** @typedef {(slug: string, pageItems: string, cpf: string, search: string) => Promise<void>} GetDataFN */

/**
 * @typedef {object} ICoursesState
 * @property {IUserInfo} coursesData
 * @property {GetDataFN} getDataForPartner
 */

/** @type {ICoursesState} */
export const Context = createContext({})

/** @type {React.FC} */
export const CoursesProvider = ({ children }) => {
  const redirectModalRef = useRef()

  const [coursesData, setCoursesData] = useState({})

  const { setIsLoading } = useLoading()

  /** @type {GetDataFN} */
  const getDataForPartner = useCallback(
    async (slug, pageItems, cpf, search, p) => {
      setIsLoading(true)
      try {
        const data = await getUserCourses({ slug, cpf, pageItems, search, p })

        if (data.useV3 === true) {
          redirectModalRef.current.openModalFn()

          const baseUrl = ROOT_URL.BASE_URL_V3
          const splitUrl = window.location.href.split('/')
          const currentUrlParams = splitUrl[3] + '/' + splitUrl[4]

          setTimeout(() => {
            window.location.href = `${baseUrl}/${currentUrlParams}`
          }, 3000)
        }

        setCoursesData(data)
      } catch (error) {
        Sentry.addBreadcrumb({
          category: 'LoadingPartnerDataError',
          message: 'ERROR INFO ' + JSON.stringify(error),
          level: Sentry.Severity.Warning,
        })
        showToast('Um erro inesperado aconteceu')
      } finally {
        setIsLoading(false)
      }
    },
    [setCoursesData, setIsLoading]
  )

  return (
    <Context.Provider
      value={{
        coursesData,
        getDataForPartner,
        redirectModalRef,
      }}
    >
      {children}
    </Context.Provider>
  )
}

/**
 * @name useCourses
 * @returns {ICoursesState}
 */
export function useCourses() {
  return useContext(Context)
}
