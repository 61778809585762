/**
 * @name parseNumber
 * @param {string} value
 * @param {string} locales
 * @return {number}
 **/

export function parseNumber(value) {
  const example = Intl.NumberFormat('pt-br').format('1.1')
  const cleanPattern = new RegExp(`[^-+0-9${example.charAt(1)}]`, 'g')
  const cleaned = value.replace(cleanPattern, '')
  const normalized = cleaned.replace(example.charAt(1), '.')

  return parseFloat(normalized)
}
