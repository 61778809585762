import * as React from 'react'

/** @type {React.FC<{ size: number }>} */
export const WhatsApp = ({ className }) => {
  return (
    <svg width="18" className={className} height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00225 0H8.99775C4.03538 0 0 4.0365 0 9C0 10.9688 0.6345 12.7935 1.71337 14.2751L0.59175 17.6186L4.05113 16.5128C5.47425 17.4555 7.17188 18 9.00225 18C13.9646 18 18 13.9624 18 9C18 4.03763 13.9646 0 9.00225 0Z"
        fill="#4CAF50"
      />
      <path
        d="M14.2391 12.709C14.022 13.3221 13.1602 13.8306 12.4729 13.9791C12.0026 14.0792 11.3884 14.1591 9.32062 13.3018C6.67575 12.2061 4.9725 9.51846 4.83975 9.34409C4.71262 9.16971 3.771 7.92096 3.771 6.62946C3.771 5.33796 4.42687 4.70909 4.69125 4.43909C4.90837 4.21746 5.26725 4.11621 5.6115 4.11621C5.72287 4.11621 5.823 4.12184 5.913 4.12634C6.17737 4.13759 6.31012 4.15334 6.4845 4.57071C6.70162 5.09384 7.23037 6.38534 7.29337 6.51809C7.3575 6.65084 7.42162 6.83084 7.33162 7.00521C7.24725 7.18521 7.173 7.26509 7.04025 7.41809C6.9075 7.57109 6.7815 7.68809 6.64875 7.85234C6.52725 7.99521 6.39 8.14821 6.543 8.41259C6.696 8.67134 7.22475 9.53421 8.00325 10.2272C9.00787 11.1216 9.82237 11.4073 10.1137 11.5288C10.3309 11.6188 10.5896 11.5975 10.7482 11.4287C10.9496 11.2116 11.1982 10.8516 11.4514 10.4972C11.6314 10.243 11.8586 10.2115 12.0971 10.3015C12.3401 10.3858 13.626 11.0215 13.8904 11.1531C14.1547 11.2858 14.3291 11.3488 14.3932 11.4602C14.4562 11.5716 14.4562 12.0947 14.2391 12.709Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
