import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { showToast, InputField as Input, ButtonV2 as Button } from '@provi/provi-components'
import { errorMessages, masks } from '~/enums'
import { useCepValidator, useLoading } from '~/hooks'
import { validateCEP } from '~/utils'
import brazilianStates from './states.json'
import {
  ArrowLeftButton,
  AddressTitle,
  AddressForm,
  AddressFullInput,
  AddressSmallInputs,
  AddressWrapper,
  ButtonWrapper,
  Option,
  SelectWrapper,
  Selector,
  Wrapper,
} from '../styles'
import { addressDataInitialValues } from '../forms'
import { useCheckoutCart } from '../../hooks'

/** @type {React.FC<IProps>}*/
export const AddressOptionsForm = () => {
  const { isSmallLoading } = useLoading()
  const { proceed, addressBody, setAddressBody, setStep } = useCheckoutCart()

  Yup.setLocale({
    string: {
      min: 'Deve ter pelo menos ${min} letras',
    },
  })

  const { submitForm, values, errors, setFieldValue, validateForm, touched, setFieldTouched, validateField } = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      ...addressDataInitialValues,
    },
    validationSchema: Yup.object({
      zipcode: Yup.string().required(errorMessages.requiredField),
      street: Yup.string().required(errorMessages.requiredField).min(4),
      streetNumber: Yup.string().required(errorMessages.requiredField),
      complement: Yup.string().notRequired(),
      state: Yup.string().notRequired(),
      city: Yup.string().required(errorMessages.requiredField).min(3),
      district: Yup.string().required(errorMessages.requiredField).min(3),
    }),
    onSubmit: proceed,

    validate: (fields) => {
      const fieldsArray = Object.keys(fields)
      return fieldsArray.reduce((prev, cur) => {
        if (cur === 'zipcode' && fields[cur] && !validateCEP(fields[cur])) {
          return {
            ...prev,
            [cur]: errorMessages.cep,
          }
        }

        return {
          ...prev,
        }
      }, {})
    },
  })

  const { getCep } = useCepValidator(setFieldValue)

  const isFormValid = Object.keys(errors).length === 0 && Object.keys(touched).length >= 5 && isSmallLoading === false

  const handleSubmit = (event) => {
    event.preventDefault()
    isFormValid ? submitForm(values) && setAddressBody(values) : showToast('Você esqueceu de preencher os seus dados 😅')
  }

  const streetNumberInputRef = React.useRef(null)

  useEffect(() => {
    if (!!addressBody && values.zipcode != addressBody.zipcode && values.streetNumber != addressBody.streetNumber) {
      Object.fromEntries(
        Object.entries(addressBody).map(([key, value]) => key != key.CPF && setFieldTouched(key) && setFieldValue(key, value))
      )
      validateForm()
    }

    if (values.zipcode && values.zipcode.length === 9 && values.street != null) {
      getCep(values.zipcode).then((error) => {
        !error && setFieldTouched('street') && setFieldTouched('city') && setFieldTouched('district')
      })
      streetNumberInputRef.current.focus()
    }

    values.streetNumber && values.streetNumber.length > 0 && setFieldTouched('streetNumber') && validateField('streetNumber')
  }, [values.zipcode])

  useEffect(() => {
    if (values?.streetNumber?.length === 1) {
      setFieldTouched('streetNumber')
      validateField('streetNumber')
      validateForm()
    }
  }, [values.streetNumber])

  return (
    <Wrapper>
      <ArrowLeftButton color={'#000B3C'} strokeWidth={'1.5'} onClick={() => setStep(1)} />
      <AddressWrapper>
        <AddressTitle>Endereço</AddressTitle>
        <AddressForm onSubmit={handleSubmit}>
          <AddressFullInput>
            <Input
              label="CEP"
              placeholder="04548-004"
              value={values.zipcode != null ? values.zipcode : addressBody.zipcode}
              id="zipcode"
              type="tel"
              isRequired={true}
              mask={masks.cep}
              hasError={errors.zipcode && touched.zipcode}
              isValid={!errors.zipcode && touched.zipcode}
              errorMessage={errors.zipcode}
              onChange={(e) => setFieldValue('zipcode', e.target.value)}
              onBlur={() => {
                setFieldTouched('zipcode')

                validateForm()
              }}
            />
          </AddressFullInput>
          <AddressFullInput>
            <Input
              label="Endereço"
              placeholder="Avenida Doutor Cardoso de Melo"
              value={values.street != null ? values.street : addressBody.street}
              id="street"
              type="text"
              isRequired={true}
              hasError={errors.street && touched.street}
              isValid={!errors.street && touched.street}
              errorMessage={errors.street}
              onChange={(e) => setFieldValue('street', e.target.value)}
              onBlur={() => {
                setFieldTouched('street')
                validateForm()
              }}
            />
          </AddressFullInput>
          <AddressSmallInputs>
            <Input
              label="Número"
              placeholder="1340"
              value={values.streetNumber != null ? values.streetNumber : addressBody.streetNumber}
              id="streetNumber"
              type="text"
              isRequired={true}
              isSmall={true}
              hasError={errors.streetNumber && touched.streetNumber}
              isValid={!errors.streetNumber && touched.streetNumber}
              errorMessage={errors.streetNumber}
              onChange={(e) => setFieldValue('streetNumber', e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSubmit(e)}
              onBlur={() => {
                setFieldTouched('streetNumber')
                validateForm()
              }}
              inputRef={streetNumberInputRef}
            />
            <Input
              label="Complemento"
              placeholder="Casa 1"
              value={values.complement != null ? values.complement : addressBody.complement}
              id="complement"
              type="text"
              isSmall={true}
              isRequired={false}
              hasError={errors.complement && touched.complement}
              isValid={!errors.complement && touched.complement}
              errorMessage={errors.complement}
              onChange={(e) => setFieldValue('complement', e.target.value)}
              onBlur={() => {
                setFieldTouched('complement')
                validateForm()
              }}
            />
          </AddressSmallInputs>
          <AddressFullInput>
            <SelectWrapper>
              <label htmlFor="state">Estado</label>
              <Selector
                name="state"
                id="state"
                value={values.state != null ? values.state : addressBody.state}
                onChange={(e) => setFieldValue('state', e.target.value)}
                onBlur={() => {
                  validateForm()
                }}
              >
                {brazilianStates.map(({ id, name, acronym }) => {
                  return (
                    <Option key={id} value={acronym}>
                      {name}
                    </Option>
                  )
                })}
              </Selector>
            </SelectWrapper>
          </AddressFullInput>
          <AddressFullInput>
            <Input
              label="Cidade"
              placeholder="São Paulo"
              value={values.city != null ? values.city : addressBody.city}
              id="city"
              type="text"
              isRequired={true}
              hasError={errors.city && touched.city}
              isValid={!errors.city && touched.city}
              errorMessage={errors.city}
              onChange={(e) => setFieldValue('city', e.target.value)}
              onBlur={() => {
                setFieldTouched('city')
                validateForm()
              }}
            />
          </AddressFullInput>
          <AddressFullInput>
            <Input
              label="Bairro"
              placeholder="Vila Olímpia"
              value={values.district != null ? values.district : addressBody.district}
              id="district"
              type="text"
              isRequired={true}
              hasError={errors.district && touched.district}
              isValid={!errors.district && touched.district}
              errorMessage={errors.district}
              onChange={(e) => {
                setFieldValue('district', e.target.value)
              }}
              onBlur={() => {
                setFieldTouched('district')
                validateForm()
              }}
            />
          </AddressFullInput>
        </AddressForm>
        <ButtonWrapper>
          <Button
            text="Avançar"
            disabled={!isFormValid}
            type="submit"
            id="btnNextAddress"
            onClick={handleSubmit}
            persistFloatingMode={false}
            marginVertical={0}
            marginHorizontal={0}
          />
        </ButtonWrapper>
      </AddressWrapper>
    </Wrapper>
  )
}
