import React from 'react'
import { TemplateProvider } from '~/components/template/provider'
import { CheckoutProvider } from './checkout'
import { CoursesProvider } from './courses'

/** @type {React.FC} */
export const StoresProvider = ({ children }) => (
  <TemplateProvider>
    <CoursesProvider>
      <CheckoutProvider>{children}</CheckoutProvider>
    </CoursesProvider>
  </TemplateProvider>
)
