import { makeStyles } from '@material-ui/core/styles'
import { fonts } from '~/assets/fonts'
import { theme as proviTheme } from '@provi/provi-components'

const defaultWidth = '95%'

export const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: defaultWidth,
  },
  marginDefault: {
    minWidth: defaultWidth,
    marginTop: '0px',
    marginLeft: '10px;',
    marginRight: '10px;',
    padding: 'none',
  },
  accordion: {
    boxShadow: 'none',
    padding: 0,
    border: 1,
    borderColor: '#000',
  },
  expand_icon: {
    color: proviTheme.colors.pink900,
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontFamily: fonts.montserrat.regular,
    textAlign: 'center',
    color: proviTheme.colors.blue900,
  },
  details: {
    padding: 0,
    margin: 0,
  },
}))
