import { showToast } from '@provi/provi-components'
import JSEncrypter from 'jsencrypt'
import { MoipCreditCard } from 'moip-sdk-js'
import { WIRECARD_PUB_KEY } from '~/config'
import { removeMask } from './removeMask'

/** @type {(params: { number: string, cvc: string, expirationDate: string }) => string} */
export const hashCard = async ({ number, cvc, expirationDate }) => {
  try {
    const month = expirationDate.split('/')[0] || ''
    const year = expirationDate.split('/')[1] || ''

    return await MoipCreditCard.setEncrypter(JSEncrypter, 'js')
      .setPubKey(WIRECARD_PUB_KEY)
      .setCreditCard({
        number: removeMask(number),
        cvc,
        expirationMonth: month,
        expirationYear: year,
      })
      .hash()
  } catch (error) {
    console.log('error', error)
    showToast('Um erro inesperado aconteceu')
  }
}
