import ReactGA from 'react-ga'
import { logToConsole } from './debugging'
import * as Sentry from '@sentry/browser'

/**
 * @description Track page events
 * @param {string} category
 * @param {{ label?: string, action?: string, value?: string }} payload
 * @returns {void}
 */
export function setEvent(category, payload) {
  logToConsole(
    {
      category,
      ...payload,
    },
    'analytics:setEvent'
  )
  ReactGA.event({
    category,
    ...payload,
  })
}

/**
 * @description Track page views, and set dimensions for the user and for the session
 * @param {string} title
 * @return {void}
 */
export const trackVirtualPageView = (title) => {
  try {
    ReactGA.pageview(window.location.pathname + window.location.search, null, title)
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'TrackVirtualPageGA',
      message: 'ERROR INFO ' + error,
      level: Sentry.Severity.Warning,
    })
  }
}
