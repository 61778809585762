import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { showToast, ButtonV2 as Button, InputField as Input } from '@provi/provi-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Big } from 'big.js'
import { NewCreditCard } from '~/assets/svg'
import { errorMessages, masks } from '~/enums'
import {
  validateCPF,
  validateExpireDate,
  validateFullName,
  validatePhone,
  validateBirthDate,
  validateCardNumber,
} from '~/utils'
import { useLoading } from '~/hooks'

import {
  ButtonWrapper,
  FormWrapper,
  InputRow,
  Option,
  SmallInputRow,
  SelectCardOwner,
  SelectCardOwnerButton,
  SelectCardOwnerButtonWrapper,
  SelectCardOwnerLabel,
  SelectWrapper,
  Selector,
} from '../styles'
import { paymentInfoInitialValues } from '../forms'
import { useCheckoutCart } from '../../hooks'

/** @type {React.FC<IProps>}*/
export const CreditCardForm = ({ finishAction }) => {
  const { isSmallLoading } = useLoading()

  const [imTheCardOwner, setImTheCardOwner] = useState(true)
  const { addressSuccessData = {}, creditBody, setCreditBody } = useCheckoutCart()
  const { paymentCondition = {} } = addressSuccessData.CreditCard

  const partnerOptions = useMemo(
    () =>
      paymentCondition.map(({ pmt, monthlyInterest, PartnerConditionId, installmentsToApply }) => {
        if (!Number(monthlyInterest)) {
          return {
            value: PartnerConditionId,
            label: `${installmentsToApply}x de ${pmt} (sem juros)`,
          }
        }
        return {
          value: PartnerConditionId,
          label: `${installmentsToApply}x de ${pmt} (juros de ${Big(monthlyInterest)
            .times(100)
            .toNumber()
            .toLocaleString('pt-BR')}% ao mês)`,
        }
      }),
    [addressSuccessData, paymentCondition]
  )

  Yup.setLocale({
    string: {
      min: 'Deve ter mais de ${min} números',
    },
  })

  const { submitForm, values, errors, setFieldValue, validateForm, touched, setFieldTouched, validateField } = useFormik({
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: {
      ...paymentInfoInitialValues,
      partnerConditionId: paymentCondition[0].PartnerConditionId,
    },
    validationSchema: Yup.object({
      cardNumber: Yup.string().required(errorMessages.requiredField),
      cardCvv: Yup.string().required(errorMessages.requiredField).min(3),
      cardExpireDate: Yup.string().required(errorMessages.requiredField),
      cardName: Yup.string().required(errorMessages.requiredField),
      holderCPF: imTheCardOwner === true ? Yup.string().notRequired() : Yup.string().required(errorMessages.requiredField),
      phone: imTheCardOwner === true ? Yup.string().notRequired() : Yup.string().required(errorMessages.requiredField),
      birthDate: imTheCardOwner === true ? Yup.string().notRequired() : Yup.string().required(errorMessages.requiredField),
    }),
    onSubmit: finishAction,

    validate: (fields) => {
      const fieldsArray = Object.keys(fields)
      return fieldsArray.reduce((prev, cur) => {
        if (cur === 'cardNumber' && fields[cur] && !validateCardNumber(fields[cur])) {
          return {
            ...prev,
            [cur]: 'Deve ter mais de 12 números',
          }
        }
        if (cur === 'cardName' && fields[cur] && !validateFullName(fields[cur])) {
          return {
            ...prev,
            [cur]: errorMessages.fullName,
          }
        }

        if (cur === 'cardExpireDate' && fields[cur] && !validateExpireDate(fields[cur])) {
          return {
            ...prev,
            [cur]: errorMessages.expiryDate,
          }
        }

        if (cur === 'birthDate' && fields[cur] && !validateBirthDate(fields[cur])) {
          const wrongFormat = fields[cur].length < 10

          if (moment().diff(moment(fields[cur], 'DD/MM/YYYY'), 'years') <= 16 && !wrongFormat) {
            return {
              ...prev,
              [cur]: 'Você deve ter mais de 16 anos de idade',
            }
          }

          return {
            ...prev,
            [cur]: wrongFormat ? 'Digite a data no formato dd/mm/aaaa' : errorMessages.birthDate,
          }
        }

        if (cur === 'holderCPF' && fields[cur] && !validateCPF(fields[cur])) {
          return {
            ...prev,
            [cur]: errorMessages.cpf,
          }
        }

        if (cur === 'phone' && fields[cur] && !validatePhone(fields[cur])) {
          return {
            ...prev,
            [cur]: errorMessages.phone,
          }
        }

        return { ...prev }
      }, {})
    },
  })

  useEffect(() => {
    if (creditBody) {
      Object.entries(creditBody).map(([key, value]) => {
        if (value != '' && value != undefined && key != 'partnerConditionId') {
          setFieldTouched(key) && setFieldValue(key, value) && validateField(key)
        }
      })
    }
    validateForm()
  }, [creditBody])

  useEffect(() => {
    validateForm()
  }, [imTheCardOwner])

  const isFormValid = useMemo(() => {
    setCreditBody(values)
    return Object.keys(errors).length === 0 && Object.keys(touched).length >= 4 && isSmallLoading === false
  }, [errors, touched, isSmallLoading])

  const handleSubmit = (event) => {
    event.preventDefault()
    validateForm()
    isFormValid ? submitForm(values) : showToast('Você esqueceu de preencher os seus dados 😅')
  }

  return (
    <FormWrapper onSubmit={() => submitForm(values)}>
      <InputRow>
        <Input
          label="Nome (como está escrito no cartão)"
          placeholder="Pedro M Silva"
          data-recording-sensitive
          value={values.cardName != null ? values.cardName : creditBody.cardName}
          id="cardName"
          type="text"
          isRequired={true}
          hasError={errors.cardName && touched.cardName}
          isValid={!errors.cardName && touched.cardName}
          errorMessage={errors.cardName}
          onChange={(e) => setFieldValue('cardName', e.target.value)}
          onBlur={() => {
            values != creditBody && setCreditBody(values)
            setFieldTouched('cardName')
            validateForm()
          }}
        />

        <Input
          label="Número do cartão"
          placeholder="0000 1111 2222 3333"
          data-recording-sensitive
          value={values.cardNumber != null ? values.cardNumber : creditBody.cardNumber}
          id="cardNumber"
          type="tel"
          isRequired={true}
          mask={masks.creditCard}
          hasError={errors.cardNumber && touched.cardNumber}
          isValid={!errors.cardNumber && touched.cardNumber}
          errorMessage={errors.cardNumber}
          onChange={(e) => setFieldValue('cardNumber', e.target.value)}
          onBlur={() => {
            values != creditBody && setCreditBody(values)
            setFieldTouched('cardNumber')
            validateForm()
          }}
        >
          <NewCreditCard />
        </Input>
        <SmallInputRow>
          <Input
            label="Validade"
            placeholder="MM/AA"
            value={values.cardExpireDate != null ? values.cardExpireDate : creditBody.cardExpireDate}
            data-recording-sensitive
            id="cardExpireDate"
            mask={masks.expireDate}
            isRequired={true}
            type="tel"
            hasError={errors.cardExpireDate && touched.cardExpireDate}
            isValid={!errors.cardExpireDate && touched.cardExpireDate}
            errorMessage={errors.cardExpireDate}
            isSmall={true}
            onChange={(e) => setFieldValue('cardExpireDate', e.target.value)}
            onBlur={() => {
              values != creditBody && setCreditBody(values)
              setFieldTouched('cardExpireDate')
              validateForm()
            }}
          />
          <Input
            label="Cód. segurança"
            placeholder="321"
            value={values.cardCvv != null ? values.cardCvv : creditBody.cardCvv}
            data-recording-sensitive
            id="cardCvv"
            isRequired={true}
            type="tel"
            mask={masks.cardCVV}
            hasError={errors.cardCvv && touched.cardCvv}
            isValid={!errors.cardCvv && touched.cardCvv}
            errorMessage={errors.cardCvv}
            isSmall={true}
            onBlur={() => {
              values != creditBody && setCreditBody(values)
              setFieldTouched('cardCvv')
              validateForm()
            }}
            onChange={(e) => setFieldValue('cardCvv', e.target.value)}
          />
        </SmallInputRow>
        <SelectWrapper>
          <label htmlFor="partnerConditions">Número de parcelas</label>
          <Selector
            name="partnerConditions"
            id="partnerConditions"
            onChange={(e) => setFieldValue('partnerConditionId', partnerOptions[e.target.value].value)}
            onBlur={() => {
              validateForm()
            }}
          >
            {partnerOptions.map(({ value, label }, i) => {
              return (
                <Option key={value} value={i}>
                  {label}
                </Option>
              )
            })}
          </Selector>
        </SelectWrapper>
        <SelectCardOwner>
          <SelectCardOwnerLabel>Quem é o titular do cartão?</SelectCardOwnerLabel>
          <SelectCardOwnerButtonWrapper>
            <SelectCardOwnerButton
              type="button"
              id="userOwner"
              onClick={() => {
                setImTheCardOwner(true)
                setFieldValue('holderCPF', '')
                setFieldValue('phone', '')
                setFieldValue('birthDate', '')
                validateForm()
              }}
              isActive={imTheCardOwner}
            >
              Eu sou o titular
            </SelectCardOwnerButton>
            <SelectCardOwnerButton
              type="button"
              id="userNotOwner"
              onClick={() => setImTheCardOwner(false)}
              isActive={!imTheCardOwner}
            >
              Outra pessoa
            </SelectCardOwnerButton>
          </SelectCardOwnerButtonWrapper>
        </SelectCardOwner>
        {!imTheCardOwner && (
          <>
            <Input
              label="CPF do titular"
              placeholder="123.456.789-00"
              data-recording-sensitive
              value={values.holderCPF != null ? values.holderCPF : creditBody.holderCPF}
              id="holderCPF"
              type="tel"
              isRequired={imTheCardOwner ? false : true}
              mask={masks.cpf}
              hasError={errors.holderCPF && touched.holderCPF}
              isValid={!errors.holderCPF && touched.holderCPF}
              errorMessage={errors.holderCPF}
              onChange={(e) => setFieldValue('holderCPF', e.target.value)}
              onBlur={() => {
                values != creditBody && setCreditBody(values)
                setFieldTouched('holderCPF')
                validateForm()
              }}
            />
            <Input
              label="Celular do titular"
              placeholder="(11) 95771-2414"
              value={values.phone != null ? values.phone : creditBody.phone}
              id="phone"
              type="tel"
              isRequired={imTheCardOwner ? false : true}
              mask={masks.phone}
              hasError={errors.phone && touched.phone}
              isValid={!errors.phone && touched.phone}
              errorMessage={errors.phone}
              onChange={(e) => setFieldValue('phone', e.target.value)}
              onBlur={() => {
                values != creditBody && setCreditBody(values)
                setFieldTouched('phone')
                validateForm()
              }}
            />
            <Input
              label="Data de nascimento do titular"
              placeholder="21/12/2001"
              value={values.birthDate != null ? values.birthDate : creditBody.birthDate}
              id="holderCPF"
              type="tel"
              isRequired={imTheCardOwner ? false : true}
              mask={masks.birthDate}
              hasError={errors.birthDate && touched.birthDate}
              isValid={!errors.birthDate && touched.birthDate}
              errorMessage={errors.birthDate}
              onChange={(e) => setFieldValue('birthDate', e.target.value)}
              onBlur={() => {
                values != creditBody && setCreditBody(values)
                setFieldTouched('birthDate')
                validateForm()
              }}
            />
          </>
        )}
      </InputRow>
      <ButtonWrapper>
        <Button
          text="Finalizar compra"
          id="btnFinishCreditCard"
          disabled={!isFormValid}
          onClick={handleSubmit}
          persistFloatingMode={false}
          marginVertical={0}
          marginHorizontal={0}
        />
      </ButtonWrapper>
    </FormWrapper>
  )
}
