import * as React from 'react'

/** @type {React.FC} */
export const FingerPrint = (props) => {
  const { active } = props
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.7522 17.3364C5.576 15.6786 6.0034 13.8521 6.00068 12.0008C5.99983 11.1012 6.20166 10.213 6.59118 9.4021C6.9807 8.5912 7.5479 7.87847 8.25065 7.31684"
        stroke={active ? 'white' : '#4E5A99'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0007 12.0008C12.0054 15.0504 11.2317 18.0507 9.75293 20.7178"
        stroke={active ? 'white' : '#4E5A99'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00049 12.0008C9.00049 11.2052 9.31656 10.4421 9.87917 9.87952C10.4418 9.31691 11.2048 9.00084 12.0005 9.00084C12.7961 9.00084 13.5592 9.31691 14.1218 9.87952C14.6844 10.4421 15.0005 11.2052 15.0005 12.0008C15.0049 15.0931 14.3237 18.1478 13.0059 20.9452"
        stroke={active ? 'white' : '#4E5A99'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4906 17.2508C20.8307 15.5215 21.0015 13.7632 21.0007 12.0008C21.0007 9.61385 20.0524 7.32466 18.3646 5.63683C16.6768 3.949 14.3876 3.00079 12.0007 3.00079C9.6137 3.00079 7.32452 3.949 5.63669 5.63683C3.94886 7.32466 3.00065 9.61385 3.00065 12.0008C3.00177 13.0226 2.82858 14.0372 2.48853 15.0008"
        stroke={active ? 'white' : '#4E5A99'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.70045 15.0008C8.38027 16.5778 7.80676 18.0926 7.0022 19.4862"
        stroke={active ? 'white' : '#4E5A99'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2505 6.04726C12.095 5.94164 12.9523 6.01674 13.7655 6.26758C14.5788 6.51843 15.3295 6.93928 15.9677 7.50225C16.606 8.06521 17.1173 8.75743 17.4678 9.53299C17.8182 10.3086 17.9998 11.1498 18.0005 12.0008C18.0007 13.0037 17.9387 14.0056 17.8148 15.0008"
        stroke={active ? 'white' : '#4E5A99'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2445 18.0008C17.1017 18.5557 16.9398 19.1026 16.7588 19.6414"
        stroke={active ? 'white' : '#4E5A99'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
