import { useCallback } from 'react'
import cep from 'cep-promise'
import { useLoading } from './useLoading'
import { showToast } from '@provi/provi-components'

/**
 * @param {(field: string, value: string) => void} setFieldValue
 */
export const useCepValidator = (setFieldValue) => {
  const { setIsLoading } = useLoading()

  /** @type {(zipCode: string) => void} */
  const getCep = useCallback(
    async (zipcode) => {
      try {
        setIsLoading(true)
        const data = await cep(zipcode)
        if (data) {
          setFieldValue('state', data?.state || '')
          setFieldValue('neighborhood', data?.neighborhood || '')
          setFieldValue('district', data?.neighborhood || '')
          setFieldValue('city', data?.city || '')
          setFieldValue('street', data?.street || '')
        }
      } catch (error) {
        showToast('Não foi possível encontrar o seu CEP 😢')
        return error
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading, setFieldValue, cep]
  )

  return {
    getCep,
  }
}
