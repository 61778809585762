import React from 'react'
import { SuccessCreditCardBillet } from '~/assets/svg'
import { ProviPay, RocketWrapper, TextAndPerson } from './styles'
import { useAuthorization } from './hooks'

/** @typedef {object} IProps */

/** @type {React.FC<IProps>}*/
export const Authorization = () => {
  useAuthorization()

  return (
    <>
      <TextAndPerson>
        <section>
          <div>
            <ProviPay />
            <p>Recebemos sua autorização!</p>
            <h1>Estamos finalizando seu cadastro e te avisaremos quando estiver tudo pronto.</h1>
          </div>
        </section>
        <RocketWrapper>
          <SuccessCreditCardBillet />
        </RocketWrapper>
      </TextAndPerson>
    </>
  )
}
