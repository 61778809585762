import * as React from 'react'

/** @type {React.FC<{ size: number }>} */
export const CartIcon = ({ className, number }) => {
  return (
    <svg width="33" height="32" className={className} viewBox="0 0 33 32" fill="none" xmlns="https://www.w3.org/2000/svg">
      <path
        d="M21.5956 25.9093H7.77135C7.56164 25.9093 7.35859 25.8357 7.19758 25.7013C7.03658 25.5669 6.92784 25.3803 6.89033 25.174L3.90148 8.73528C3.86397 8.52895 3.75523 8.34234 3.59423 8.20797C3.43322 8.0736 3.23017 8 3.02046 8H1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16371 30.3865C9.40008 30.3865 10.4024 29.3842 10.4024 28.1478C10.4024 26.9115 9.40008 25.9092 8.16371 25.9092C6.92733 25.9092 5.92505 26.9115 5.92505 28.1478C5.92505 29.3842 6.92733 30.3865 8.16371 30.3865Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5956 30.3865C22.832 30.3865 23.8342 29.3842 23.8342 28.1478C23.8342 26.9115 22.832 25.9092 21.5956 25.9092C20.3592 25.9092 19.3569 26.9115 19.3569 28.1478C19.3569 29.3842 20.3592 30.3865 21.5956 30.3865Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.58179 12.4775H24.1045C24.2356 12.4775 24.3652 12.5064 24.484 12.5619C24.6028 12.6175 24.7079 12.6985 24.792 12.7992C24.876 12.8999 24.9369 13.0179 24.9703 13.1447C25.0038 13.2715 25.009 13.4041 24.9855 13.5332L23.5202 21.5923C23.4827 21.7987 23.3739 21.9853 23.2129 22.1197C23.0519 22.254 22.8489 22.3276 22.6392 22.3276H6.37271"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="25" cy="12" r="8" fill="white"></circle>
      <text x={number >= 10 ? '20' : '23'} y="16" fill="#DE0C4B" fontSize={'12px'}>
        {number}
      </text>
    </svg>
  )
}
