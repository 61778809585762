import React, { useEffect } from 'react'
import { equals } from 'ramda'
import { useLocation } from 'react-router-dom'
import { usePrevious } from '~/hooks'

export function PageScroll() {
  const location = useLocation()
  const prevLocation = usePrevious(location)

  useEffect(() => {
    if (prevLocation && location && !equals(location, prevLocation)) {
      window.scrollTo(0, 0)
    }
  }, [location, prevLocation])

  return <React.Fragment />
}
