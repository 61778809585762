import * as React from 'react'

export const NewCreditCard = ({ ...props }) => {
  return (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.7505 1H1.75049C1.33627 1 1.00049 1.33579 1.00049 1.75V13.75C1.00049 14.1642 1.33627 14.5 1.75049 14.5H19.7505C20.1647 14.5 20.5005 14.1642 20.5005 13.75V1.75C20.5005 1.33579 20.1647 1 19.7505 1Z"
        stroke="#000B3C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.5 11.5H17.5" stroke="#000B3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 11.5H11.5" stroke="#000B3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 4.83008H20.5" stroke="#000B3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
