import { ButtonV2 as Button } from '@provi/provi-components'
import React, { useEffect } from 'react'
import { parseUrl } from 'query-string'
import { CourseCard, HeaderWithSlug, Template, Combo, CpfCourseCard } from '~/components'
import { useLoading, usePlatform } from '~/hooks'
import { useHome } from './hooks'
import {
  ListContainer,
  Wrapper,
  ComboContainer,
  Container,
  PaginationButtonContainer,
  BottomCartAction,
  EmptyStateContainer,
  EmptyStateImage,
  Controls,
  LoadMoreContainer,
} from './styles'

export const Home = () => {
  const { query } = parseUrl(window.location.href)

  const { isMobile } = usePlatform()
  const { isLoading } = useLoading()

  const {
    coursesData,
    selectedCourses = [],
    selectProduct,
    loadMore,
    goToCheckout,
    fetchUnremovable,
    isComboSelected,
    search,
    setSearch,
    existingRequests = {},
    continueRequest,
    backToCart,
  } = useHome()

  const { existingCourses = [], cpf, PartnerName = '', PartnerId = null } = existingRequests

  const {
    partnerName = '',
    partnerImage = '',
    courses = [],
    coursesFromCPF = [],
    coursesWithDiscount = [],
    hasNextPage,
    activeCourses = 0,
  } = coursesData

  const isBottomCartIndicatorShown = selectedCourses?.length !== 0 && existingCourses.length === 0
  useEffect(() => {
    fetchUnremovable()
  }, [coursesData])

  return (
    <Template>
      {coursesData && (
        <HeaderWithSlug
          partnerName={partnerName || PartnerName}
          partnerLogo={partnerImage || null}
          availableProducts={
            courses.length >= 6 ? activeCourses : courses.length + coursesFromCPF.length + coursesWithDiscount.length
          }
          activeCourses={activeCourses}
          search={search}
          backToCart={existingCourses.length !== 0 ? backToCart : goToCheckout}
          setSearch={setSearch}
          existingRequests={existingCourses.length}
        />
      )}
      <Wrapper
        isBottomCartShown={isBottomCartIndicatorShown}
        isEmpty={
          courses.length == 0 && coursesWithDiscount.length == 0 && coursesFromCPF.length == 0 && existingCourses.length == 0
        }
      >
        {!isLoading &&
          courses.length == 0 &&
          coursesWithDiscount.length == 0 &&
          coursesFromCPF.length == 0 &&
          existingCourses.length == 0 && (
            <EmptyStateContainer>
              <p>
                <strong>Oops!</strong>
                <br />
                Nenhum produto disponível no momento.
              </p>
              <EmptyStateImage />
            </EmptyStateContainer>
          )}
        <Container id="containerCards">
          <ListContainer>
            {coursesFromCPF.length !== 0 &&
              existingCourses.length === 0 &&
              coursesFromCPF.map(({ id, courseName, price, totalPriceToPay, removable, extraInfo, discountPercentage }) => {
                return (
                  <CourseCard
                    key={id}
                    title={courseName}
                    price={totalPriceToPay || price}
                    discount={discountPercentage}
                    oldPrice={price}
                    removable={removable}
                    selected={selectedCourses?.includes(id)}
                    onSelect={() => (!removable ? null : selectProduct(id))}
                    extraInfo={extraInfo}
                  />
                )
              })}

            {coursesWithDiscount.length !== 0 && existingCourses.length === 0 && (
              <>
                {coursesWithDiscount.map((comboData) => {
                  const { id, courseName, type, item, totalPriceToPay, removable, price, extraInfo, discountPercentage } =
                    comboData
                  if (type === 'combo') {
                    const comboIds = item.map((e) => e.id)
                    return (
                      <ComboContainer>
                        <Combo
                          key={id}
                          comboData={comboData}
                          selectAction={() => selectProduct(comboIds, true)}
                          selected={isComboSelected(comboIds)}
                        />
                      </ComboContainer>
                    )
                  }
                  return (
                    <CourseCard
                      key={id}
                      title={courseName}
                      price={totalPriceToPay}
                      oldPrice={price}
                      discount={discountPercentage}
                      removable={removable}
                      selected={selectedCourses?.includes(id)}
                      onSelect={() => (!removable ? null : selectProduct(id))}
                      extraInfo={extraInfo}
                    />
                  )
                })}
              </>
            )}

            {courses.length !== 0 &&
              existingCourses.length === 0 &&
              courses.map(({ id, courseName, price, removable, extraInfo, discountPercentage }) => (
                <CourseCard
                  key={id}
                  title={courseName}
                  price={price}
                  discount={discountPercentage}
                  removable={removable}
                  selected={selectedCourses?.includes(id)}
                  onSelect={() => (!removable ? null : selectProduct(id))}
                  extraInfo={extraInfo}
                />
              ))}

            {existingCourses.length !== 0 &&
              existingCourses.map(({ CreditRequestId, products, paymentMethod, status, finalPrice }) => (
                <CpfCourseCard
                  key={CreditRequestId}
                  CreditRequestId={CreditRequestId}
                  products={products}
                  paymentMethod={paymentMethod}
                  status={status}
                  finalPrice={finalPrice}
                  onClick={continueRequest}
                  PartnerId={PartnerId}
                  cpf={cpf}
                />
              ))}
          </ListContainer>
          <Controls>
            {!!courses && !!courses.length && hasNextPage && !existingCourses.length && (
              <PaginationButtonContainer>
                <LoadMoreContainer onClick={loadMore}>Ver mais opções</LoadMoreContainer>
              </PaginationButtonContainer>
            )}
            {isBottomCartIndicatorShown && !isMobile && <Button onClick={goToCheckout} text="Ir para o carrinho"></Button>}
          </Controls>
        </Container>
        {selectedCourses.length > 0 && isMobile && (
          <BottomCartAction onClick={existingCourses.length !== 0 ? backToCart : goToCheckout}>
            {existingCourses.length !== 0 ? 'Voltar para o carrinho' : 'Ir para o carrinho'}
          </BottomCartAction>
        )}
        {query.cpf && (
          <BottomCartAction onClick={existingCourses.length !== 0 ? backToCart : goToCheckout}>
            Voltar para o carrinho
          </BottomCartAction>
        )}
      </Wrapper>
    </Template>
  )
}
