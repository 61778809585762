import React from 'react'
import { PortalModal } from '@provi/provi-components'
import { CloseButton } from '~/assets/svg/closeButton'
import { useCourses } from '~/stores'
import { ModalContainer, ModalText, ModalTitle } from './styles'

export const RedirectModal = () => {
  const { redirectModalRef } = useCourses()

  return (
    <PortalModal ref={redirectModalRef} width="496px">
      <ModalContainer>
        <ModalTitle>
          Você será redirecionado <CloseButton onClick={() => redirectModalRef.current.closeModalFn()} />
        </ModalTitle>
        <ModalText>Vamos abrir o site da Provi em 5 segundos. É rapidinho!</ModalText>
      </ModalContainer>
    </PortalModal>
  )
}
