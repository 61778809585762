import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { fonts } from '~/assets/fonts'
import { WhatsApp } from '~/assets/svg'

export const Wrapper = styled.div`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  border-top: 1px solid rgba(176, 175, 175, 0.7);
  width: 100%;
  min-height: 230px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
`

export const ContentWrapper = styled.div`
  max-width: 1100px;
  padding: 40px 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  svg {
    max-width: 128px;
    margin-bottom: 26px;
  }
`

export const CompanyInfo = styled.p`
  color: #858585;
  font-size: 12px;
  font-family: ${fonts.montserrat.bold};
  margin-bottom: 4px;
`

export const PhoneInfo = styled.p`
  margin: 4px 0 8px 0;
  font-size: 16px;
  color: ${theme.colors.blue900};
  font-family: ${fonts.montserrat.bold};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  strong {
    color: ${theme.colors.pink900};
  }

  div {
    display: flex;
    max-height: 20px;
    padding: 0px;
  }
`

export const Copyright = styled.p`
  font-size: 12px;
  color: #858585;
  font-family: ${fonts.montserrat.bold};
  margin-bottom: 20px;
  margin-top: 9px;
`
export const WhatsAppIcon = styled(WhatsApp)`
  margin-right: 5px;
`
