import { useEffect, useRef } from 'react'

/** @type {<T>(value: T) => (T | undefined)} */
export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
