import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { fonts } from '~/assets/fonts'
import { colors } from '~/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 8px 0 8px;
  min-height: calc(100vh - 251px);
  background-color: ${colors.greyV2};

  ${theme.breakpoints.down('md')} {
    padding: 0;
    background-color: ${theme.colors.white100};
  }
`
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const Row = styled.div`
  ${theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  ${theme.breakpoints.up('md')} {
    flex-direction: row;
    display: inline-flex;
    border: 0px solid #000;
  }
`
export const Label = styled.div`
  font-family: ${fonts.montserrat.regular};
  font-size: 16px;
`
export const InputLabel = styled.div`
  font-family: ${fonts.montserrat.regular};
  color: ${theme.colors.blue900};
  font-size: 16px;
`

export const Separator = styled.hr`
  border: 0;
  border-top: 0.5px solid ${theme.colors.grey500};
  width: 279px;
  ${theme.breakpoints.down('md')} {
    min-width: calc(95% - 16px);
  }
`
