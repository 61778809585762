import React, { useMemo, useState } from 'react'
import { useScreenMeasure } from './hooks'
import { TemplateContext } from './context'

export const TemplateProvider = (props) => {
  const [isMobile] = useScreenMeasure()
  const [isLoading, setIsLoading] = useState(false)
  const [isSmallLoading, setIsSmallLoading] = useState(false)

  const value = useMemo(
    () => ({
      isLoading,
      setIsLoading,
      isSmallLoading,
      setIsSmallLoading,
      isMobile,
    }),
    [isMobile, isLoading, setIsLoading, isSmallLoading, setIsSmallLoading]
  )

  return <TemplateContext.Provider value={value}>{props.children}</TemplateContext.Provider>
}
