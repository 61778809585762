import * as React from 'react'

/** @type {React.FC<{ size: number }>} */
export const ProviPayLogoV2 = ({ className }) => {
  return (
    <svg width="106" className={className} height="21" viewBox="0 0 106 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.1343 5.71072V6.89003C27.6159 6.40848 28.1416 6.04486 28.7018 5.79917C29.262 5.55839 29.8664 5.43555 30.5052 5.43555C31.8958 5.43555 33.08 5.96132 34.0579 7.01288C35.0308 8.06443 35.5173 9.41573 35.5173 11.0619C35.5173 12.649 35.0161 13.9807 34.0087 15.0469C33.0014 16.1132 31.8073 16.6488 30.4266 16.6488C29.8123 16.6488 29.2374 16.5407 28.7117 16.3196C28.181 16.0985 27.6552 15.7447 27.1343 15.2435V20.2752H24.476V5.71072H27.1343ZM29.9499 7.90719C29.1048 7.90719 28.407 8.19711 27.8517 8.77202C27.2965 9.34694 27.0164 10.0987 27.0164 11.0275C27.0164 11.9758 27.2965 12.7424 27.8517 13.3271C28.407 13.9119 29.1097 14.2067 29.9499 14.2067C30.7656 14.2067 31.4536 13.9069 32.0137 13.3124C32.5739 12.7178 32.854 11.9611 32.854 11.0373C32.854 10.1233 32.5788 9.3715 32.0285 8.78676C31.4781 8.20202 30.7853 7.90719 29.9499 7.90719Z"
        fill="white"
      />
      <path
        d="M36.5639 5.71075H38.8586V7.05222C39.1092 6.52153 39.4384 6.1186 39.8512 5.84342C40.264 5.56825 40.716 5.43066 41.2025 5.43066C41.5514 5.43066 41.9101 5.52403 42.2934 5.70584L41.458 8.01041C41.1435 7.85317 40.888 7.77455 40.6816 7.77455C40.2689 7.77455 39.92 8.03007 39.635 8.5411C39.35 9.05214 39.2075 10.0496 39.2075 11.5434L39.2173 12.0643V16.3786H36.559V5.71075H36.5639Z"
        fill="white"
      />
      <path
        d="M48.0861 5.43555C49.0935 5.43555 50.0418 5.68615 50.9263 6.19227C51.8108 6.69348 52.5036 7.38141 52.9999 8.24133C53.4962 9.10616 53.7468 10.0349 53.7468 11.0373C53.7468 12.0446 53.4962 12.9881 52.995 13.8627C52.4938 14.7374 51.8108 15.4253 50.9509 15.9167C50.0861 16.4081 49.1377 16.6587 48.096 16.6587C46.5678 16.6587 45.2607 16.1132 44.1797 15.0273C43.0986 13.9364 42.5581 12.6146 42.5581 11.0619C42.5581 9.39607 43.1674 8.00547 44.391 6.89495C45.4622 5.92201 46.6955 5.43555 48.0861 5.43555ZM48.1304 7.95633C47.2999 7.95633 46.6071 8.24624 46.0518 8.82607C45.4966 9.4059 45.2214 10.143 45.2214 11.0471C45.2214 11.9758 45.4966 12.7276 46.042 13.3025C46.5874 13.8775 47.2803 14.1674 48.1205 14.1674C48.9608 14.1674 49.6586 13.8775 50.2138 13.2927C50.7691 12.7129 51.0492 11.9611 51.0492 11.0471C51.0492 10.1331 50.774 9.38625 50.2286 8.81624C49.6831 8.24624 48.9854 7.95633 48.1304 7.95633Z"
        fill="white"
      />
      <path
        d="M54.2487 5.71094H56.9758L59.6735 12.0104L62.3663 5.71094H65.0787L60.5383 16.3837H58.8038L54.2487 5.71094Z"
        fill="white"
      />
      <path
        d="M67.147 1.31787C67.6187 1.31787 68.0167 1.48985 68.3558 1.82891C68.6899 2.16796 68.857 2.58072 68.857 3.06719C68.857 3.54382 68.6899 3.95167 68.3558 4.29072C68.0216 4.62978 67.6236 4.79685 67.1617 4.79685C66.6851 4.79685 66.2822 4.62486 65.9431 4.2809C65.609 3.93693 65.4419 3.51926 65.4419 3.03279C65.4419 2.56106 65.609 2.15813 65.9382 1.82399C66.2674 1.48985 66.6753 1.31787 67.147 1.31787ZM65.8055 5.71081H68.4835V16.3836H65.8055V5.71081Z"
        fill="white"
      />
      <path
        d="M73.0342 5.71072V6.89003C73.5158 6.40848 74.0416 6.04486 74.6017 5.79917C75.1619 5.55839 75.7663 5.43555 76.4051 5.43555C77.7957 5.43555 78.9799 5.96132 79.9578 7.01288C80.9307 8.06443 81.4172 9.41573 81.4172 11.0619C81.4172 12.649 80.911 13.9807 79.9086 15.0469C78.9013 16.1182 77.7073 16.6488 76.3265 16.6488C75.7122 16.6488 75.1373 16.5407 74.6116 16.3196C74.0809 16.0985 73.5551 15.7447 73.0342 15.2435V20.2752H70.3759V5.71072H73.0342ZM75.8498 7.90719C75.0047 7.90719 74.3069 8.19711 73.7516 8.77202C73.1964 9.34694 72.9163 10.0987 72.9163 11.0275C72.9163 11.9758 73.1964 12.7424 73.7516 13.3271C74.3069 13.9119 75.0096 14.2067 75.8498 14.2067C76.6655 14.2067 77.3535 13.9069 77.9136 13.3124C78.4738 12.7178 78.7539 11.9611 78.7539 11.0373C78.7539 10.1233 78.4787 9.3715 77.9284 8.78676C77.378 8.20202 76.6852 7.90719 75.8498 7.90719Z"
        fill="#1DBFFF"
      />
      <path
        d="M90.969 5.71066H93.6471V16.3834H90.969V15.2533C90.4482 15.7496 89.9224 16.1083 89.3966 16.3294C88.8708 16.5505 88.3008 16.6586 87.6866 16.6586C86.3107 16.6586 85.1167 16.123 84.1094 15.0567C83.102 13.9904 82.6008 12.6588 82.6008 11.0716C82.6008 9.42549 83.0873 8.0742 84.0602 7.02264C85.0332 5.97109 86.2174 5.44531 87.608 5.44531C88.2468 5.44531 88.8512 5.56816 89.4114 5.80893C89.9715 6.04971 90.4924 6.41333 90.969 6.8998V5.71066ZM88.1534 7.90713C87.3279 7.90713 86.64 8.20196 86.0896 8.7867C85.5442 9.37144 85.269 10.1233 85.269 11.0372C85.269 11.961 85.5491 12.7177 86.1044 13.3123C86.6596 13.9069 87.3476 14.2066 88.1633 14.2066C89.0035 14.2066 89.7013 13.9118 90.2565 13.3271C90.8118 12.7423 91.087 11.9758 91.087 11.0274C91.087 10.0987 90.8118 9.34687 90.2565 8.77196C89.7013 8.19704 89.0035 7.90713 88.1534 7.90713Z"
        fill="#1DBFFF"
      />
      <path
        d="M94.6301 5.71094H97.3671L100.138 12.4085L103.195 5.71094H105.937L99.2589 20.2902H96.4925L98.6889 15.5877L94.6301 5.71094Z"
        fill="#1DBFFF"
      />
      <path
        d="M17.5965 3.4554C13.7392 -0.971942 7.01708 -1.14393 2.93371 2.93453C0.958357 4.90988 -0.0145764 7.49946 0.00016501 10.0841V20.0444H9.96045C12.7417 20.0641 15.5229 18.9339 17.5424 16.6539C20.8445 12.9194 20.8691 7.21446 17.5965 3.4554ZM12.7122 12.713C11.2282 14.197 8.82045 14.197 7.33648 12.713C5.85251 11.229 5.85251 8.82127 7.33648 7.3373C8.82045 5.85333 11.2282 5.85333 12.7122 7.3373C14.1961 8.82127 14.1961 11.229 12.7122 12.713Z"
        fill="#5CB5FE"
      />
      <path
        d="M10.0489 15.9313C13.3272 15.9313 15.9848 13.2737 15.9848 9.99545C15.9848 6.71715 13.3272 4.05957 10.0489 4.05957C6.77062 4.05957 4.11304 6.71715 4.11304 9.99545C4.11304 13.2737 6.77062 15.9313 10.0489 15.9313Z"
        fill="#000B3C"
      />
      <path d="M6.72018 7.03292L5.23389 8.5L10.0045 13.3331L11.4908 11.866L6.72018 7.03292Z" fill="white" />
      <path d="M8.53878 11.8467L10.0059 13.333L19.1789 4.27855L17.7118 2.79226L8.53878 11.8467Z" fill="white" />
    </svg>
  )
}
