import smartlook from 'smartlook-client'
import { logger } from '~/utils'
/**
 * @name sendCartInformations
 * @param {object} smartLookInfos
 * @return {string}
 **/

export const smartlookSender = ({ crid, email, partnerSlug }) => {
  try {
    smartlook &&
      smartlook.identify(crid, {
        email,
        partnerSlug,
        crid,
      })
  } catch (error) {
    logger({ error })
    console.log('Smartlook Error')
  }
}
