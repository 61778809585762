import * as React from 'react'

/**
 * @typedef {object} IProps
 * @property {number} size
 */

/** @type {React.FC<IProps>} */
export const SelectIcon = ({ size = 25, ...props }) => {
  return (
    <svg width={size} height={20} viewBox="0 0 25 20" fill="none" {...props}>
      <path
        d="M23.375 7.085L19.102 2.39a2.175 2.175 0 00-3.626.947 2.176 2.176 0 00-3.41 1.135 2.178 2.178 0 00-3.404 1.115L6.65 3.573a2.175 2.175 0 00-3.077 3.076l7.737 7.737-4.589.72a2.546 2.546 0 00-2.175 2.508c0 .94.765 1.705 1.705 1.705h10.715c1.669 0 3.238-.65 4.42-1.831l1.787-1.788A6.199 6.199 0 0025 11.287a6.233 6.233 0 00-1.625-4.202zm-1.007 7.812l-1.787 1.787a5.08 5.08 0 01-3.616 1.498H6.25a.568.568 0 01-.569-.568c0-.691.516-1.285 1.208-1.384l5.7-.896a.569.569 0 00.313-.962L4.376 5.846a1.04 1.04 0 011.47-1.47l5.684 5.686a.569.569 0 10.803-.805L10.01 6.935a1.037 1.037 0 01.007-1.469 1.037 1.037 0 011.463 0l2.322 2.323a.569.569 0 10.803-.805L13.42 5.8a1.037 1.037 0 01.007-1.47 1.037 1.037 0 011.463 0l1.19 1.192h.002v.002h.002l.001.002.002.001a.567.567 0 00.792-.815l-.049-.048a1.031 1.031 0 01.006-1.47c.405-.402 1.06-.401 1.445-.02l4.254 4.677a5.102 5.102 0 011.329 3.437c0 1.363-.531 2.645-1.496 3.61z"
        fill="#fff"
      />
      <path
        d="M9.768 3.015A5.123 5.123 0 005.114 0 5.12 5.12 0 000 5.114c0 2 1.184 3.827 3.015 4.656a.571.571 0 00.752-.285.57.57 0 00-.284-.752 3.985 3.985 0 01-2.347-3.62 3.982 3.982 0 013.978-3.977c1.554 0 2.975.922 3.62 2.347a.568.568 0 101.035-.468z"
        fill="#fff"
      />
    </svg>
  )
}
