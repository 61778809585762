import React from 'react'
import { StepsWrapper } from './styles'
import { stepsRegistration, stepsVerification } from './values'
import { HeaderV2 as Header } from '@provi/provi-components'

export const StepperTrack = ({ currentStep }) => {
  return (
    <StepsWrapper>
      {currentStep <= 3 ? (
        <Header content={stepsRegistration} current={currentStep} />
      ) : (
        <Header content={stepsVerification} current={currentStep} />
      )}
    </StepsWrapper>
  )
}
