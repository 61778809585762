import React, { useCallback } from 'react'
import { Container, Wrapper, Row, Separator } from './styles'
import { CheckoutList, StepperTrack, Template, Collapse, DiscountAlertModal } from '~/components'
import { RegisterStep } from './RegisterStep'
import { usePlatform } from '~/hooks'
import { AddressOptionsForm } from './forms/addressOptions'
import { PaymentForm } from './forms'
import { useCheckout } from './hooks'

export const CheckoutCart = () => {
  const { isMobile } = usePlatform()
  const {
    cartInformations,
    goToCourses,
    removeItem,
    checkCPF,
    showDiscountAlert,
    shouldShowDiscountAlert,
    hideDiscountAlert,
    step,
    setStep,
  } = useCheckout()
  const { courses = [], PartnerId = null, partnerName = '' } = cartInformations || {}

  const renderStep = useCallback(() => {
    switch (step) {
      case 1:
        return <RegisterStep setStep={setStep} checkCPF={checkCPF} />
      case 2:
        return <AddressOptionsForm />
      case 3:
        return <PaymentForm />
    }
  }, [step])

  return (
    <Template>
      <Container>
        <Row>
          <Wrapper>
            {courses && courses.length > 0 && <StepperTrack currentStep={step} />}
            {(isMobile && courses.length) > 0 && (
              <Collapse title="Resumo da compra" marginLeft={true}>
                <CheckoutList
                  data={cartInformations}
                  removeItem={removeItem}
                  PartnerId={PartnerId}
                  partnerName={partnerName}
                  isMobile={isMobile}
                  goToCourses={goToCourses}
                />
              </Collapse>
            )}
            {isMobile && <Separator />}
            {courses && courses.length > 0 && renderStep()}
          </Wrapper>
          {courses && courses.length > 0 && !isMobile && (
            <CheckoutList
              data={cartInformations}
              removeItem={removeItem}
              PartnerId={PartnerId}
              partnerName={partnerName}
              goToCourses={goToCourses}
            />
          )}
        </Row>
        <DiscountAlertModal
          hideDiscountAlert={hideDiscountAlert}
          shouldShowDiscountAlert={shouldShowDiscountAlert}
          showDiscountAlert={showDiscountAlert}
        />
      </Container>
    </Template>
  )
}
