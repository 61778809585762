import { theme } from '@provi/provi-components'
import styled from 'styled-components'
import { fonts } from '~/assets/fonts'

export const ModalTitle = styled.h1`
  font-family: ${fonts.montserrat.bold};
  font-size: 20px;
  color: ${theme.colors.blue900};
  line-height: 36px;
  text-align: left;
`

export const Description = styled.p`
  font-family: ${fonts.montserrat.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  margin-top: 10px;
  text-align: left;
  color: ${theme.colors.blue900};
  ${theme.breakpoints.down('md')} {
    font-size: 14px;
  }
  h1 {
    font-family: ${fonts.montserrat.bold};
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }
`

export const UnderlineButton = styled.a`
  font-family: ${fonts.montserrat.bold};
  font-size: 16px;
  line-height: 30px;
  text-decoration-line: underline;
  color: ${theme.colors.pink900};
  cursor: pointer;
  text-align: center;
`

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`
export const Button = styled.button`
  min-width: 270px;
  height: 48px;
  border: 0px;
  border-radius: 5px;
  background-color: ${theme.colors.pink900};
  color: ${theme.colors.white100};
  font-family: ${fonts.montserrat.regular};
  margin-left: 40px;
  :hover {
    cursor: pointer;
  }

  ${theme.breakpoints.down('md')} {
    margin-left: 0px;
    margin-top: 10px;
  }
`
export const ButtonOutline = styled.button`
  min-width: 270px;
  height: 48px;
  border: 1px solid ${theme.colors.pink900};
  border-radius: 5px;
  color: ${theme.colors.pink900};
  background-color: ${theme.colors.white100};
  font-family: ${fonts.montserrat.regular};

  :hover {
    cursor: pointer;
  }

  ${theme.breakpoints.down('md')} {
  }
`
