import React from 'react'
import { Modal } from '@provi/provi-components'
import { ButtonsWrapper, Description, ModalTitle, Button, ButtonOutline } from './styles'
import { useCheckout } from '~/stores'

/**
 * @typedef {object} IProps
 * @property {(shouldApplyDiscount: false) => void} hideDiscountAlert
 * @property {boolean} showDiscountAlert
 * @property {boolean} shouldShowDiscountAlert
 */

/** @type {React.FC<IProps>}*/
export const DiscountAlertModal = ({ showDiscountAlert, hideDiscountAlert, shouldShowDiscountAlert }) => {
  const { cartInformations } = useCheckout()
  return showDiscountAlert && shouldShowDiscountAlert ? (
    <Modal isWithoutHeight onDismiss={() => hideDiscountAlert(false)} title="">
      <ModalTitle>
        Você tem um desconto especial para a compra de{' '}
        {cartInformations.discountAlert[0]?.courseName || cartInformations.discountAlert[0]?.item[0]?.courseName}.
      </ModalTitle>
      <Description>Quer alterar a sua escolha ou prefere continuar sem o desconto?</Description>
      <ButtonsWrapper>
        <ButtonOutline onClick={() => hideDiscountAlert(false)}>Continuar sem desconto</ButtonOutline>
        <Button onClick={() => hideDiscountAlert(true)}>Aproveitar a oferta</Button>
      </ButtonsWrapper>
    </Modal>
  ) : null
}
