import { theme } from '@provi/provi-components'
import InputMask from 'react-input-mask'
import styled from 'styled-components'
import { ProviPayLogoWhite, ProviPayLogo, CartIcon } from '~/assets/svg'
import { fonts } from '~/assets/fonts'

export const Wrapper = styled.div`
  * {
    box-sizing: border-box;
  }

  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  z-index: -10;
  background-color: ${theme.colors.white100};
  padding-bottom: 50px;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 920px;
  width: 100%;
`

export const Header = styled.div`
  padding: 0px 20px;
  width: 100%;
  margin: 25px auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  ${theme.breakpoints.down('md')} {
    padding: 0px;
    margin: 0px;
    background-color: ${theme.colors.blue900};
    flex-direction: column;
    align-self: flex-start;
    justify-content: center;
    min-height: 40px;
  }

  ${theme.breakpoints.up('lg')} {
    max-width: 1280px;
  }
`

export const LogoProviPay = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  ${theme.breakpoints.down('md')} {
    justify-content: flex-start;
    padding: 13px;
  }
`

export const ProviPayLogoLight = styled(ProviPayLogoWhite)`
  height: 20px;
`

export const ProviPayLogoDark = styled(ProviPayLogo)`
  width: 137px;
`

export const ProductsContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const AvailableProducts = styled.div`
  width: 100%;
  max-width: 920px;
  padding: 0px 20px 18px 20px;
  display: flex;

  ${({ isColumn }) => (isColumn ? 'flex-direction: column;' : '')}
  justify-content: space-between;
  color: ${theme.colors.blue900};

  label {
    font-family: ${fonts.montserrat.regular};
  }

  strong {
    font-family: ${fonts.montserrat.bold};
    font-weight: 700;
    font-size: 20px;

    margin-top: ${({ isColumn }) => (isColumn ? '20px;' : null)};
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    color: inherit;
    font-family: ${fonts.montserrat.regular};
    font-weight: 400;
    font-size: 14px;

    :focus {
      outline-width: 0;
    }
  }

  ${theme.breakpoints.down('md')} {
    flex-direction: column-reverse;
    padding: 0px 12px 8px 12px;

    button {
      margin: 5px 0 32px 0;
    }
  }

  ${theme.breakpoints.up('lg')} {
    max-width: 1280px;
  }
`
export const InputWrapper = styled.div`
  * {
    box-sizing: border-box;
  }

  width: 312px;
  border: 1px solid #b0afaf;
  padding: 0px 10px;
  margin-top: 8px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  flex-direction: row;
  margin: 0 20px;

  path {
    stroke: ${theme.colors.grey600};
  }

  ${theme.breakpoints.down('md')} {
    align-self: center;
    max-width: 90vw;
  }
`

export const Input = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  height: 40px;
  outline: none;
  margin-left: 14px;
  font-size: 14px;
  font-family: ${fonts.montserrat.regular};
`

export const CPFInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 280px;
  height: 40px;
  border: 1px solid #b0afaf;
  border-radius: 3px;
  padding: 4px 4px 4px 8px;

  button {
    margin: 0;
    padding: 0;
    background-color: ${({ isCpfValid }) => (isCpfValid ? theme.colors.pink900 : theme.colors.grey600)};
    cursor: ${({ isCpfValid }) => (isCpfValid ? 'pointer' : 'not-allowed')};
    color: ${theme.colors.white100};
    font-size: 24px;
    line-height: 24px;
    border-radius: 3px;
    min-width: 32px;
    min-height: 32px;
    text-decoration: none;
  }
`

export const InputCPF = styled(InputMask)`
  font-family: ${fonts.montserrat.regular};
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.blue900};
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;

  ::placeholder {
    color: ${theme.colors.grey600};
  }

  :focus {
    border: none;
    outline: none;
  }
`

export const InputAdornment = styled.div``

export const CPFSendButton = styled.div``

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  margin-bottom: 8px;

  h3 {
    align-self: flex-start;
    font-family: ${fonts.montserrat.bold};
    font-size: 16px;
    line-height: 30px;
    margin: 0 0 4px 0;
  }

  ${theme.breakpoints.down('md')} {
    align-items: flex-start;
    margin-bottom: 16px;
  }
`

export const BackToCart = styled.button`
  display: inline-flex;
  background-color: ${theme.colors.pink900};
  min-height: 40px;
  color: ${theme.colors.white100};
  border-radius: 5px;
  border: 0;
  padding: 8px 12px 8px 12px;
  font-family: ${fonts.montserrat.bold};
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`
export const CartBadge = styled(CartIcon)`
  margin-right: 11px;
`
