import React from 'react'
import { showToast } from '@provi/provi-components'
import { useSuccess } from './hooks'
import { Template } from '~/components'
import {
  AnalysisHourglass,
  BoletoSuccess,
  Button,
  ButtonOutline,
  Container,
  EmailNotebookPerson,
  FailureRobot,
  FirstContainer,
  FloaterContainer,
  Header,
  Helper,
  InputText,
  Question,
  Row,
  SuccessJumpingPerson,
  SecondContainer,
  SignaturePerson,
  SubHeader,
  Title,
} from './styles'
import { copyToClipboard, getCRIDFromMessage } from '~/utils'
import { useCheckout } from '~/stores'

export const Success = () => {
  const { selectedModality, possibilities } = useSuccess()
  const { paymentCreditAndBoletoSuccessData, continueRequestData } = useCheckout()

  const requestDataSuccess = !selectedModality ? continueRequestData : possibilities

  const { screen, header, subHeader, question, helperText } = requestDataSuccess || {}

  const getImage = (successScreen) => {
    switch (successScreen) {
      case 'complete':
        return <SuccessJumpingPerson />
      case 'analysis':
        return <AnalysisHourglass />
      case 'email':
        return <EmailNotebookPerson />
      case 'signature':
        return <SignaturePerson />
      case 'failure':
        return <FailureRobot />
      default:
        return <FailureRobot />
    }
  }

  return (
    <Template>
      <Container>
        <FirstContainer />
        <FloaterContainer>
          {selectedModality != 'Boleto' && (
            <Title>
              {screen === 'failure' || Object.entries(requestDataSuccess).length === 0
                ? 'Ops! '
                : selectedModality === 'CourseFinancing'
                ? 'Tudo certo ;)'
                : 'Uhuul!'}
            </Title>
          )}
          {header ? <Header>{header}</Header> : <Header>Algum erro aconteceu por nossa culpa.</Header>}
          {subHeader && <SubHeader>{subHeader}</SubHeader>}
          {selectedModality === 'Boleto' || selectedModality === 'CreditCard' ? (
            <BoletoSuccess />
          ) : (
            selectedModality === 'CourseFinancing' && <EmailNotebookPerson />
          )}
          {!selectedModality && getImage(screen)}
          {requestDataSuccess.boletoText && <Helper>{requestDataSuccess.boletoText}</Helper>}
          {question && (
            <Question pink={screen === 'analysis' || selectedModality === 'CourseFinancing'} isRegular={screen === 'signature'}>
              {question}
            </Question>
          )}
          {helperText && <Helper isBold={screen === 'signature'}>{helperText}</Helper>}
          {screen === ('analysis' || 'failure') && (
            <Button
              onClick={() =>
                window.open(
                  `https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar?%20Meu%20n%C3%BAmero%20identificador%20%C3%A9%20o%20${getCRIDFromMessage(
                    helperText
                  )}%21`,
                  '_blank'
                )
              }
            >
              Abrir chat
            </Button>
          )}
          {selectedModality === 'Boleto' && (
            <>
              <InputText
                defaultValue={paymentCreditAndBoletoSuccessData.invoiceCode}
                onFocus={(e) => e.target.select()}
                readonly
              />
              <Row>
                <Button
                  big={true}
                  onClick={() => {
                    copyToClipboard(paymentCreditAndBoletoSuccessData?.invoiceCode)
                    showToast('Código copiado')
                  }}
                >
                  Copiar código
                </Button>
                <ButtonOutline
                  big={true}
                  href={paymentCreditAndBoletoSuccessData?.invoiceUrl}
                  target="blank"
                  rel="noopener noreferrer"
                >
                  Ver boleto
                </ButtonOutline>
              </Row>
            </>
          )}
        </FloaterContainer>
        <SecondContainer />
      </Container>
    </Template>
  )
}
