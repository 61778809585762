import * as React from 'react'

/** @type {React.FC<{ size: number }>} */
export const Removable = ({ className }) => {
  return (
    <svg width="18" className={className} height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9091 6.09091L6.09091 11.9091M11.9091 11.9091L6.09091 6.09091M1.72727 1H16.2727C16.6744 1 17 1.32561 17 1.72727V16.2727C17 16.6744 16.6744 17 16.2727 17H1.72727C1.32561 17 1 16.6744 1 16.2727V1.72727C1 1.32561 1.32561 1 1.72727 1Z"
        stroke="#DE0C4B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
