import * as React from 'react'

/** @type {React.FC} */
export const SuccessCreditCardBillet = (props) => {
  return (
    <svg width={385} height={409} viewBox="0 0 385 409" fill="none" {...props}>
      <path
        d="M0 188.721C0 233.271 36.27 269 80.5 269c44.673 0 80.5-36.17 80.5-80.279C161 144.611 125.173 108 80.942 108 36.27 108 0 144.17 0 188.721z"
        fill="url(#paint0_linear)"
      />
      <path transform="rotate(-45 37 192.53)" fill="#fff" d="M37 192.53H54.1473V242.5431H37z" />
      <path transform="rotate(-135 72.363 227.895)" fill="#fff" d="M72.3633 227.895H89.5106V308.88120000000004H72.3633z" />
      <path
        d="M347.767 379.516c-16.285-36.075-50.46-23.102-60.17-40.237-9.71-17.136 7.645-36.075-25.001-58.761-32.57-22.685-37.692-77.214-37.692-77.214-.535-.485-5.964-.277-9.404-.139-3.44-.138-8.945-.346-9.404.139 0 0-5.122 54.598-37.692 77.214-32.646 22.686-15.291 41.625-25.001 58.761-9.709 17.135-43.808 4.162-60.17 40.237C74.67 398.386 59.762 406.086 46 409h339c-13.762-2.914-28.671-10.614-37.233-29.484z"
        fill="#F9FBFC"
      />
      <path
        d="M237.726 187.17l-21.93.903-21.929-.903s-9.039 7.222-8.891 21.967h61.64c.149-14.745-8.89-21.967-8.89-21.967zM249.284 118.561s.296 14.745 13.335 20.312c13.039 5.416 31.265 14.745 31.561 50.704h-51.268l6.372-71.016zM181.717 118.561s-.297 14.745-13.336 20.312c-13.039 5.416-31.264 14.745-31.561 50.704h51.268l-6.371-71.016z"
        fill="#DE0C4B"
      />
      <path d="M250.913 191.082S262.915 48.297 215.5 0c-47.564 48.297-35.413 191.082-35.413 191.082h70.826z" fill="#F9FBFC" />
      <path
        d="M215.648 92.683c10.884 0 19.708-8.96 19.708-20.011 0-11.052-8.824-20.011-19.708-20.011-10.883 0-19.707 8.959-19.707 20.01 0 11.052 8.824 20.011 19.707 20.011z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M215.65 128.19c5.646 0 10.224-4.648 10.224-10.381 0-5.734-4.578-10.382-10.224-10.382-5.647 0-10.224 4.648-10.224 10.382 0 5.733 4.577 10.381 10.224 10.381z"
        fill="url(#paint2_linear)"
      />
      <defs>
        <linearGradient id="paint0_linear" x1={80.5} y1={108} x2={80.5} y2={269} gradientUnits="userSpaceOnUse">
          <stop stopColor="#8CCBFF" />
          <stop offset={1} stopColor="#137ED5" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1={215.648} y1={52.6606} x2={215.648} y2={92.6825} gradientUnits="userSpaceOnUse">
          <stop stopColor="#5CB5FE" />
          <stop offset={1} stopColor="#1989F1" />
        </linearGradient>
        <linearGradient id="paint2_linear" x1={215.65} y1={107.427} x2={215.65} y2={128.19} gradientUnits="userSpaceOnUse">
          <stop stopColor="#5CB5FE" />
          <stop offset={1} stopColor="#1989F1" />
        </linearGradient>
      </defs>
    </svg>
  )
}
