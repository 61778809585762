import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const ModalContainer = styled.div``

export const ModalTitle = styled.h2`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: ${theme.colors.bluePrimary};

  ${theme.breakpoints.down('md')} {
    font-size: 15.84px;
    line-height: 24px;
  }
`

export const ModalText = styled.p`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;
  color: #000000;

  ${theme.breakpoints.down('md')} {
    font-size: 13.2px;
    line-height: 20px;
    letter-spacing: 0.005em;
  }
`
