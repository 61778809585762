import { useEffect, useCallback } from 'react'
import { parseUrl } from 'query-string'
import { showToast } from '@provi/provi-components'
import { handleAxiosError } from '~/utils'
import { authorizationWirecard } from '~/services/api'
export function useAuthorization() {
  /** @type {(code: string) => Promise<void>} */
  const authorizate = useCallback(async (code) => {
    try {
      await authorizationWirecard(code)
    } catch (error) {
      showToast(handleAxiosError(error).message)
    }
  }, [])

  useEffect(() => {
    /** @type {{ code: string }} */
    const { code } = parseUrl(window.location.href).query
    if (code) {
      authorizate(code)
    }
  }, [])
}
