import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { fonts } from '~/assets/fonts'

export const Wrapper = styled.div`
  display: flex;
  width: 656px;
  flex-direction: column;
  margin-top: 16px;
  border-radius: 2px;
  background-color: ${theme.colors.white100};
  padding-top: 17px;
  padding-left: 32px;
  padding-bottom: 51px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 40px;
  }
`
export const Label = styled.div`
  font-family: ${fonts.montserrat.regular};
  font-size: 16px;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    font-size: 14px;
    align-self: flex-start;
    margin-left: 22px;
  }
`
export const InputLabel = styled.div`
  font-family: ${fonts.montserrat.regular};
  color: ${theme.colors.blue900};
  font-size: 16px;
`

export const Row = styled.div`
  * {
    box-sizing: border-box;
  }
  box-sizing: border-box;

  input:disabled {
    background: unset;
  }

  ${theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: row;
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'space-between')};
    padding-right: 45px;
    margin-top: 25px;
  }

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    padding-right: 30px;
    padding-left: 20px;
    label {
      margin-top: 10px;
    }
  }
`

export const Form = styled.form`
  border: 0px solid #000;
`
