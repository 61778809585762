import * as React from 'react'

/** @type {React.FC<{ size: number }>} */
export const LocationIcon = ({ active }) => {
  return (
    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8 7.54545C13.8 12.6364 7.4 17 7.4 17C7.4 17 1 12.6364 1 7.54545C1 5.80949 1.67428 4.14463 2.87452 2.91712C4.07475 1.68961 5.70261 1 7.4 1C9.09739 1 10.7252 1.68961 11.9255 2.91712C13.1257 4.14463 13.8 5.80949 13.8 7.54545Z"
        stroke={active ? 'white' : '#4E5A99'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.4 9.72727C8.57821 9.72727 9.53333 8.75044 9.53333 7.54545C9.53333 6.34047 8.57821 5.36364 7.4 5.36364C6.22179 5.36364 5.26667 6.34047 5.26667 7.54545C5.26667 8.75044 6.22179 9.72727 7.4 9.72727Z"
        stroke={active ? 'white' : '#4E5A99'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
