import * as React from 'react'

export const Boleto = ({ ...props }) => {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15.25 1H19V4.75" stroke="#000B3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.75 16H1V12.25" stroke="#000B3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 12.25V16H15.25" stroke="#000B3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 4.75V1H4.75" stroke="#000B3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.5 4.75V12.25" stroke="#000B3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.5 4.75V12.25" stroke="#000B3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.5 4.75V12.25" stroke="#000B3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.5 4.75V12.25" stroke="#000B3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
