import * as React from 'react'

export const CalendarBoleto = ({ ...props }) => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.5556 1V4.55556M5.44444 1V4.55556M1 8.11111H17M2.77778 2.77778H15.2222C16.2041 2.77778 17 3.57372 17 4.55556V17C17 17.9818 16.2041 18.7778 15.2222 18.7778H2.77778C1.79594 18.7778 1 17.9818 1 17V4.55556C1 3.57372 1.79594 2.77778 2.77778 2.77778Z"
        stroke="#949494"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
