import { theme } from '@provi/provi-components'
import styled from 'styled-components'
import { fonts } from '~/assets/fonts'
import { ProviPayLogoV2 } from '~/assets/svg'

export const ProviPay = styled(ProviPayLogoV2)`
  width: 200px;
  height: auto;
  margin-bottom: 32px;
`

export const RocketWrapper = styled.div`
  svg {
    z-index: 11;
    ${theme.breakpoints.down('md')} {
      z-index: 0;
      height: 300px;
      margin-right: -20px;
    }
  }
`

export const TextAndPerson = styled.div`
  box-sizing: border-box;
  background: ${theme.colors.blue900};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  section {
    margin-top: 50px;
    max-width: 1280px;
    padding: 0px 50px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    ${theme.breakpoints.down('md')} {
      flex-direction: column;
    }
    flex-direction: row;
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  p {
    font-size: 28px;
    color: ${theme.colors.white100};
    font-family: ${fonts.montserrat.bold};
    margin: 0px;
    ${theme.breakpoints.down('md')} {
      margin-left: 10px;
      font-size: 24px;
    }
  }
  h1 {
    color: ${theme.colors.blue600};
    font-family: ${fonts.montserrat.bold};
    font-size: 38px;
    margin: 0px;
    width: 500px;
    ${theme.breakpoints.down('md')} {
      width: 80vw;
      margin-right: -20px;
      padding: 10px;
      font-size: 28px;
    }
  }

  @media only screen and (max-width: 900px) {
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    section,
    div {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
`
