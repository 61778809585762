import * as React from 'react'

/** @type {React.FC} */
export const SafetyBuy = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 8L10.1666 15L6.5 11.5M1 9.33333V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H22C22.2652 1 22.5196 1.10536 22.7071 1.29289C22.8946 1.48043 23 1.73478 23 2V9.33333C23 19.8352 14.0868 23.3146 12.307 23.9047C12.1081 23.9731 11.8919 23.9731 11.693 23.9047C9.91325 23.3146 1 19.8352 1 9.33333Z"
        stroke="#5CB5FE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
