import React from 'react'
import { parseCurrency } from '~/utils'
import { Button, CardTitle, Wrapper, LabelTitle, Row, Column } from './styles'

/**
 * @typedef {object} IProps
 * @property {string} title
 * @property {boolean} hasSelectionProcess
 * @property {string} modality
 * @property {string} attendType
 * @property {string} price
 * @property {string} oldPrice
 * @property {string} discount
 * @property {boolean} selected
 * @property {boolean} removable
 * @property {() => void} onSelect
 */

/** @type {React.FC<IProps>}*/
export const CpfCourseCard = ({ onClick, CreditRequestId, products, paymentMethod, status, finalPrice, cpf, PartnerId }) => {
  return (
    <Wrapper>
      <LabelTitle>Produto</LabelTitle>
      <CardTitle>{products[0]}</CardTitle>

      <LabelTitle>Forma de pagamento:</LabelTitle>
      <CardTitle>{paymentMethod}</CardTitle>

      <LabelTitle>Etapa da solicitação:</LabelTitle>
      <CardTitle>{status}</CardTitle>

      <Row>
        {finalPrice && (
          <Column>
            <LabelTitle>Valor da compra:</LabelTitle>
            <CardTitle>{parseCurrency(finalPrice)}</CardTitle>
          </Column>
        )}

        <Column>
          <Button onClick={() => onClick({ CreditRequestId, cpf, PartnerId })} type="button">
            Continuar
          </Button>
        </Column>
      </Row>
    </Wrapper>
  )
}
