import React from 'react'
import { SelectIcon } from '~/assets/svg'
import { Percent } from '~/assets/svg/percent'
import { parseCurrency } from '~/utils'
import {
  Button,
  ComboWrapper,
  Wrapper,
  OriginalValue,
  FinalValue,
  Discount,
  TextWrapper,
  CourseNumber,
  CourseName,
  CoursePrice,
  EachCourse,
} from './styles'
import '~/typings'

/** @type {React.FC<{ comboData: ICombo, selectAction: () => void, selected: boolean }>}*/
export const Combo = ({ comboData, selectAction, selected }) => {
  return (
    <Wrapper selected={selected}>
      <ComboWrapper>
        {comboData.item.map(({ courseName, price }, index) => {
          return (
            <EachCourse key={index}>
              <CourseNumber>
                <p>Curso {index + 1}</p>
              </CourseNumber>
              <CourseName>{courseName}</CourseName>
              <CoursePrice>{parseCurrency(price)}</CoursePrice>
            </EachCourse>
          )
        })}
      </ComboWrapper>
      <TextWrapper>
        <span>
          <FinalValue>{parseCurrency(comboData.totalPriceToPay)}</FinalValue>
          <OriginalValue>{parseCurrency(comboData.totalPrice)}</OriginalValue>
        </span>
        <Discount>
          <span>
            <Percent />
            {Math.floor(comboData.discountPercentage)}%
          </span>
        </Discount>
      </TextWrapper>
      <Button selected={selected} onClick={selectAction}>
        {selected ? (
          'Combo selecionado'
        ) : (
          <>
            <SelectIcon />
            {'Selecionar combo'}
          </>
        )}
      </Button>
    </Wrapper>
  )
}
