import { addBreadcrumb, Severity } from '@sentry/browser'

/** @type {(error: import("axios").AxiosError) => void} */
export const handleError = (error) => {
  addBreadcrumb({
    category: 'errorRequest',
    message: `Error Info: ${error}`,
    level: Severity.Warning,
  })
  throw error
}
