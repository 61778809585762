import React from 'react'
import { RegisterStep, LocationIcon, CardIcon, FingerPrint, IdentificationCard, MagnifyngGlass } from '~/assets/svg'

export const stepsRegistration = [
  {
    id: 1,
    name: 'Cadastro',
    icon: <RegisterStep />,
  },
  {
    id: 2,
    name: 'Endereço',
    icon: <LocationIcon />,
  },
  {
    id: 3,
    name: 'Pagamento',
    icon: <CardIcon />,
  },
]

export const stepsVerification = [
  {
    id: 1,
    name: 'Verificação',
    icon: <FingerPrint />,
  },
  {
    id: 2,
    name: 'Documentos',
    icon: <IdentificationCard />,
  },
  {
    id: 3,
    name: 'Análise',
    icon: <MagnifyngGlass />,
  },
]
