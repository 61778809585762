import React, { useEffect, useMemo, useRef } from 'react'
import { showToast, PortalModal, ButtonV2 } from '@provi/provi-components'
import { NewCreditCard, Boleto, Calendar } from '~/assets/svg'
import { eventCategories } from '~/enums'
import { setEvent } from '~/utils'
import { CreditCardForm } from './creditCard'
import { BoletoOptions } from './boletoOptions'
import { CourseFinancing } from './courseFinancing'
import {
  ArrowLeftButton,
  PaymentMethods,
  PaymentMethodWrapper,
  SVGWrapper,
  FormContainer,
  PaymentText,
  Wrapper,
  InstabilityModalContent,
} from './styles'
import { useCheckoutCart } from '../hooks'

const InstabilityModal = ({ hasInstability }) => {
  const modalRef = useRef()

  const openModal = () => {
    modalRef.current.openModalFn()
  }

  const closeModal = () => {
    modalRef.current.closeModalFn()
  }
  useEffect(() => {
    hasInstability && openModal()
  }, [])

  return (
    <>
      <PortalModal ref={modalRef}>
        <InstabilityModalContent>
          <p>
            Por conta de um problema técnico, os pagamentos com cartão de crédito e boleto à vista não estão disponíveis no
            momento. Estamos trabalhando para restabelecer esses serviços o quanto antes. Obrigada pela compreensão.
          </p>
          <br />
          <br />
          <ButtonV2 icon={' '} text="Entendi" onClick={closeModal}>
            Entendi
          </ButtonV2>
        </InstabilityModalContent>
      </PortalModal>
    </>
  )
}

export const PaymentForm = () => {
  const { addressSuccessData = {}, selectedModality = '', setSelectedModality, finishAction, setStep } = useCheckoutCart()
  const hasInstability = false

  const { paymentMethods = [] } = addressSuccessData
  const renderForm = useMemo(() => {
    switch (selectedModality) {
      case 'CreditCard':
        return <CreditCardForm finishAction={finishAction} />
      case 'Boleto':
        return <BoletoOptions finishAction={finishAction} />
      case 'CourseFinancing':
        return <CourseFinancing finishAction={finishAction} />
    }
  }, [selectedModality])

  useEffect(() => {
    addressSuccessData?.CourseFinancing?.paymentCondition?.length === 0 &&
      selectedModality === 'CourseFinancing' &&
      setSelectedModality('')
  }, [addressSuccessData?.CourseFinancing?.paymentCondition?.length])

  /**
   * @param {string} paymentMethod
   */
  const invalidPaymentMethod = (paymentMethod) => {
    showToast('Método de pagamento não permitido para esse curso ou venda')
    setEvent(eventCategories.selectPaymentMethod, {
      action: `Set${paymentMethod}`,
      label: 'NotAvailable',
    })
  }

  return (
    <Wrapper>
      <ArrowLeftButton color={'#000B3C'} strokeWidth={'1.5'} onClick={() => setStep(2)} />
      <PaymentText>Escolha a forma de pagamento</PaymentText>
      <PaymentMethods>
        <PaymentMethodWrapper
          id="CreditCard"
          disabled={hasInstability ? true : !paymentMethods.includes('CreditCard')}
          isActive={selectedModality === 'CreditCard'}
          onClick={() =>
            !paymentMethods.includes('CreditCard') || hasInstability
              ? invalidPaymentMethod('CreditCard')
              : setSelectedModality('CreditCard')
          }
        >
          <SVGWrapper>
            <NewCreditCard />
          </SVGWrapper>
          <h2>Cartão de crédito</h2>
        </PaymentMethodWrapper>
        <PaymentMethodWrapper
          id="Boleto"
          disabled={hasInstability ? true : !paymentMethods.includes('Boleto')}
          isActive={selectedModality === 'Boleto'}
          onClick={() =>
            !paymentMethods.includes('Boleto') || hasInstability
              ? invalidPaymentMethod('Boleto')
              : setSelectedModality('Boleto')
          }
        >
          <SVGWrapper>
            <Boleto />
          </SVGWrapper>
          <h2>Boleto à vista</h2>
        </PaymentMethodWrapper>
        <PaymentMethodWrapper
          id="CourseFinancing"
          disabled={
            !paymentMethods.includes('CourseFinancing') || addressSuccessData?.CourseFinancing?.paymentCondition?.length === 0
          }
          isActive={selectedModality === 'CourseFinancing'}
          onClick={() =>
            !paymentMethods.includes('CourseFinancing') || addressSuccessData?.CourseFinancing?.paymentCondition?.length === 0
              ? invalidPaymentMethod('CourseFinancing')
              : setSelectedModality('CourseFinancing')
          }
        >
          <SVGWrapper>
            <Calendar />
          </SVGWrapper>
          <h2>Financiamento no boleto</h2>
        </PaymentMethodWrapper>
      </PaymentMethods>
      <FormContainer>{renderForm}</FormContainer>
      {hasInstability && !selectedModality && <InstabilityModal hasInstability={hasInstability} />}
    </Wrapper>
  )
}
