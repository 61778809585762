import { theme } from '@provi/provi-components'
import styled from 'styled-components'
import { fonts } from '~/assets/fonts'

export const Wrapper = styled.div`
  * {
    box-sizing: border-box;
    margin: 0;
  }

  display: flex;
  max-width: 500px;

  div {
    display: flex;
    flex-direction: column;
  }

  ${theme.breakpoints.down('md')} {
    padding-left: 5px;
    padding-top: 5px;
  }
`

export const PartnerLogo = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 2px;
  margin-right: 8px;
`

export const SellingPlatform = styled.h2`
  font-family: ${fonts.montserrat.regular};
  font-weight: 400;
  font-size: 14px;
  color: ${theme.colors.blue900};
  line-height: 24px;
`

export const PartnerSlug = styled.h1`
  font-family: ${fonts.montserrat.bold};
  font-weight: 700;
  font-size: 20px;
  color: ${theme.colors.pink900};
  line-height: 24px;
`
